import graphql from "babel-plugin-relay/macro";
import Icon from "components/Icon";
import React, { useState } from "react";
import { useMutation } from "react-relay/hooks";
import styled from "styled-components/macro";
import DaColors from "../../app/common/DaColors";
import Spinner from "./Spinner";
import { TestConnectionButtonTypeCameraMutation } from "./__generated__/TestConnectionButtonTypeCameraMutation.graphql";
import { TestConnectionButtonTypeX1Mutation } from "./__generated__/TestConnectionButtonTypeX1Mutation.graphql";

const testCameraConnectionMutation = graphql`
  mutation TestConnectionButtonTypeCameraMutation($id: ID!) {
    testCameraConnection(id: $id) {
      __typename
      ... on UnauthorizedError {
        errorType: type
      }
      ... on NotFoundError {
        errorType: type
      }
      ... on CameraOfflineError {
        errorType: type
      }
      ... on SecureComCamera {
        __typename
        id
      }
    }
  }
`;
const testX1ConnectionMutation = graphql`
  mutation TestConnectionButtonTypeX1Mutation($id: ID!) {
    testSiteConnection(siteControlSystemId: $id) {
      ... on TestSiteConnectionSuccessResponse {
        canConnectRightNow
      }
      ... on Error {
        type
      }
    }
  }
`;

export enum DeviceType {
  Camera,
  X1,
}

const TestConnectionButton = ({
  deviceType,
  deviceId,
  style,
  isPreProgram,
}: {
  deviceType: DeviceType;
  deviceId: string;
  style?: any;
  isPreProgram?: boolean;
}) => {
  const [testCameraConnection, isTestingCameraConnection] =
    useMutation<TestConnectionButtonTypeCameraMutation>(
      testCameraConnectionMutation
    );
  const [testX1Connection, isTestingX1Connection] =
    useMutation<TestConnectionButtonTypeX1Mutation>(testX1ConnectionMutation);
  const isTestingConnection =
    isTestingCameraConnection || isTestingX1Connection;
  const [buttonText, setButtonText] = useState("Test Connection");
  const handleTestConnectionClick = () => {
    switch (deviceType) {
      case DeviceType.Camera:
        testCameraConnection({
          variables: {
            id: deviceId,
          },
          onCompleted: (response) => {
            if (
              response.testCameraConnection.__typename === "SecureComCamera"
            ) {
              setButtonText("Connected");
            } else {
              setButtonText("Offline");
            }
          },
        });
        break;
      case DeviceType.X1:
        testX1Connection({
          variables: {
            id: deviceId,
          },
          onCompleted: ({ testSiteConnection }) => {
            if (testSiteConnection?.canConnectRightNow) {
              setButtonText("Connected");
            } else {
              setButtonText("Offline");
            }
          },
        });
        break;
    }
  };
  return (
    <TestButton
      type="button"
      style={style}
      onClick={handleTestConnectionClick}
      disabled={isPreProgram}
      isPreProgram={isPreProgram}
    >
      {!isTestingConnection && buttonText === "Connected" ? (
        <Icon
          name="checkmark"
          style={{ marginRight: ".3rem" }}
          size={"1.4rem"}
          color={DaColors.SuccessBase}
        />
      ) : !isTestingConnection && buttonText === "Offline" ? (
        <Icon
          name="radial_alert"
          size={"1.4rem"}
          style={{ marginRight: ".8rem" }}
          color={DaColors.FailureBase}
        />
      ) : null}
      {isTestingConnection ? (
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner />
          Testing
        </span>
      ) : (
        <span
          style={
            buttonText === "Connected"
              ? { color: DaColors.SuccessBase }
              : buttonText === "Offline"
              ? { color: DaColors.FailureBase }
              : { color: DaColors.PrimaryBase }
          }
        >
          {buttonText}
        </span>
      )}
    </TestButton>
  );
};

//styled components
//Take a prop to change the color of the button
export const TestButton = styled.button<{ isPreProgram?: boolean }>`
  align-items: center;
  appearance: none;
  background-color: #fff;
  border-radius: 0.4rem;
  border-style: none;
  box-shadow: rgba(0, 0, 0, 0.2) 0 3px 5px -1px,
    rgba(0, 0, 0, 0.14) 0 6px 10px 0, rgba(0, 0, 0, 0.12) 0 1px 18px 0;
  box-sizing: border-box;
  color: #3c4043;
  cursor: pointer;
  display: inline-flex;
  fill: currentcolor;
  font-size: 1.4rem;
  font-weight: 500;
  justify-content: center;
  letter-spacing: 0.25px;
  line-height: normal;
  width: 12.4rem !important;
  overflow: visible;
  padding: 0.8rem 0.5rem;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 15ms linear 30ms, transform 270ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  vertical-align: middle;
  width: auto;
  will-change: transform, opacity;
  z-index: 0;

  &:hover {
    background: ${(props) => (props.isPreProgram ? "#fff" : "#f6f9fe")};
    color: ${(props) => (props.isPreProgram ? "#3c4043" : "#174ea6")};
  }

  &:active {
    box-shadow: 0 4px 4px 0 rgb(60 64 67 / 30%),
      0 8px 12px 6px rgb(60 64 67 / 15%);
    outline: none;
  }
  &:focus {
    outline: none;
  }

  &:not(:disabled) {
    box-shadow: rgba(60, 64, 67, 0.3) 0 1px 3px 0,
      rgba(60, 64, 67, 0.15) 0 4px 8px 3px;
  }

  &:not(:disabled):hover {
    box-shadow: rgba(60, 64, 67, 0.3) 0 2px 3px 0,
      rgba(60, 64, 67, 0.15) 0 6px 10px 4px;
  }

  &:not(:disabled):focus {
    box-shadow: rgba(60, 64, 67, 0.3) 0 1px 3px 0,
      rgba(60, 64, 67, 0.15) 0 4px 8px 3px;
  }

  &:not(:disabled):active {
    box-shadow: rgba(60, 64, 67, 0.3) 0 4px 4px 0,
      rgba(60, 64, 67, 0.15) 0 8px 12px 6px;
  }

  &:disabled {
    box-shadow: rgba(60, 64, 67, 0.3) 0 1px 3px 0,
      rgba(60, 64, 67, 0.15) 0 4px 8px 3px;
  }

  :disabled {
    opacity: 0.4;
  }
`;

export default TestConnectionButton;
