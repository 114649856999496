import graphql from "babel-plugin-relay/macro";
import { IncludedTemplateFieldsType } from "components/FullProgramming/common/IncludedTemplateFieldsContext";
import {
  EditingFrom,
  useTemplateContext,
} from "components/FullProgramming/common/TemplateContext";
import { useStateDotGo } from "components/SystemDiagnostics/AngularStateProvider";
import { useShowAlert } from "contexts/AlertsContext";
import { useFragment, useMutation } from "react-relay";
import {
  asID,
  CommunicationComType,
  fromDealerId,
} from "securecom-graphql/client";
import { getState as getCommunicationState } from "../../../XTFullProgramming/XTCommunicationProgrammingConceptForm";
import { getInput as getAreaInput } from "../Data/XTAreaInformationTemplateData";
import { getInput as getBellOptionsInput } from "../Data/XTBellOptionsTemplateData";
import { getInput as getCommunicationInput } from "../Data/XTCommunicationTemplateData";
import { getInput as getDeviceInformationsInput } from "../Data/XTDeviceInformationTemplateData";
import { getInput as getKeyfobsInput } from "../Data/XTKeyfobsTemplateData";
import { getInput as getLockoutCodeInput } from "../Data/XTLockoutCodeTemplateData";
import { getInput as getNetworkOptionsInput } from "../Data/XTNetworkOptionsTemplateData";
import { getInput as getOutputInformationsInput } from "../Data/XTOutputInformationTemplateData";
import { getInput as getOutputOptionsInput } from "../Data/XTOutputOptionsTemplateData";
import { getInput as getRemoteOptionsInput } from "../Data/XTRemoteOptionsTemplateData";
import { getInput as getSystemOptionsInput } from "../Data/XTSystemOptionsTemplateData";
import { getInput as getSystemReportsInput } from "../Data/XTSystemReportsTemplateData";
import { getInput as getZoneInformationInput } from "../Data/XTZoneInformationTemplateData";
import { xtTemplateSaveMutation } from "./XTTemplateSaveMutation";
import { XTTemplateSaveHook_controlSystem$key } from "./__generated__/XTTemplateSaveHook_controlSystem.graphql";
import { XTTemplateSaveMutation } from "./__generated__/XTTemplateSaveMutation.graphql";

export const useXTTemplateSaveMutation = (
  controlSystem: XTTemplateSaveHook_controlSystem$key
): [
  (
    includedFields: IncludedTemplateFieldsType,
    templateName: string,
    customerId: string,
    dealerId: string
  ) => void,
  boolean
] => {
  const [saveXtTemplate, isSavingXTTemplate] =
    useMutation<XTTemplateSaveMutation>(xtTemplateSaveMutation);

  const data = useFragment(
    graphql`
      fragment XTTemplateSaveHook_controlSystem on ControlSystem {
        id
        panel {
          id
          hardwareModel
        }
        ...XTAreaInformationProgrammingConceptFormInline_controlSystem
        ...XTBellOptionsProgrammingConceptFormInline_controlSystem
        ...XTCommunicationProgrammingConceptFormInline_controlSystem
        ...XTDeviceSetupProgrammingConceptFormInline_controlSystem
        ...XTKeyfobProgrammingConceptFormInline_controlSystem
        ...XTLockoutCodeProgrammingConceptFormInline_controlSystem
        ...XTMessagingSetupProgrammingConceptFormInline_controlSystem
        ...XTNetworkOptionsProgrammingConceptFormInline_controlSystem
        ...XTOutputInformationProgrammingConceptFormInline_controlSystem
        ...XTOutputOptionsProgrammingConceptFormInline_controlSystem
        ...XTRemoteOptionsProgrammingConceptFormInline_controlSystem
        ...XTSecurityGradeProgrammingConceptFormInline_controlSystem
        ...XTSystemReportsProgrammingConceptFormInline_controlSystem
        ...XTSystemOptionsProgrammingConceptFormInline_controlSystem
        ...XTZoneInformationsProgrammingConceptFormInline_controlSystem
      }
    `,
    controlSystem
  );

  const templateData = useTemplateContext();
  const showAlert = useShowAlert();
  const nav = useStateDotGo();

  return [
    (
      includedFields: IncludedTemplateFieldsType,
      templateName: string,
      customerId: string,
      dealerId: string
    ) => {
      const {
        panel: { communication },
      } = getCommunicationState(data);
      saveXtTemplate({
        variables: {
          dealerId: dealerId,
          templateData: {
            id: templateData.templateId ? templateData.templateId : null,
            name: templateName,
            hardwareModel: templateData.hardwareModel,
            dealerId: dealerId,
            receiverCommType:
              communication?.comType ?? CommunicationComType.NONE,
            customerId: customerId,
            concepts: {
              areaInformations: getAreaInput(data, includedFields),
              bellOptions: getBellOptionsInput(data, includedFields),
              communication: getCommunicationInput(data, includedFields),
              deviceInformations: getDeviceInformationsInput(
                data,
                includedFields
              ),
              keyfobs: getKeyfobsInput(data, includedFields),
              lockoutCode: getLockoutCodeInput(data, includedFields),
              networkOptions: getNetworkOptionsInput(data, includedFields),
              outputInformations: getOutputInformationsInput(
                data,
                includedFields
              ),
              outputOptions: getOutputOptionsInput(data, includedFields),
              remoteOptions: getRemoteOptionsInput(data, includedFields),
              systemOptions: getSystemOptionsInput(data, includedFields),
              systemReports: getSystemReportsInput(data, includedFields),
              zoneInformations: getZoneInformationInput(data, includedFields),
            },
          },
          isInternational: templateData.isInternational,
        },
        onCompleted: (response) => {
          if (
            response.saveXtProgrammingTemplate?.__typename ===
            "SaveProgrammingTemplateSuccessResult"
          ) {
            showAlert({
              type: "success",
              text: "Successfully saved template.",
            });
            if (templateData.editingFrom === EditingFrom.NEW_TEMPLATE_PAGE) {
              nav(
                "app.dealer.default_programming",
                {
                  dealer_id: fromDealerId(asID(dealerId)).dealerId,
                },
                { reload: true }
              );
            } else if (
              templateData.editingFrom === EditingFrom.PROGRAMMING_PAGE
            ) {
              templateData.toggleIsEditing();
              nav("app.panel_programming.programming", {}, { reload: true });
            }
          } else if (
            response.saveXtProgrammingTemplate?.__typename ===
            "SaveProgrammingTemplateErrorResult"
          ) {
            showAlert({
              type: "error",
              text: `Error saving template`,
            });
          }
        },
        onError: () => {
          showAlert({
            type: "error",
            text: `Error saving template`,
          });
        },
      });
    },
    isSavingXTTemplate,
  ];
};
