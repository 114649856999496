import { LegaleseModal } from "components/LegaleseModal";
import React from "react";

import { DaSolidButton } from "components/DaStyledElements";
import { react2angular } from "react2angular";

const PrivacyPolicy = () => {
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = React.useState(false);

  const handleCloseModal = () => setIsPrivacyModalOpen(false);
  // this return utilizes a local asset and will be changed when the new html link is available
  return (
    <>
      <DaSolidButton onClick={() => setIsPrivacyModalOpen(true)}>
        Privacy Policy
      </DaSolidButton>
      {isPrivacyModalOpen ? (
        <LegaleseModal
          modalTitle="Privacy Policy"
          hasCloserIcon
          srcFile="assets/img/legal-docs/LT-2914_SecureCom_PrivacyPolicy.html"
          handleCloseModal={handleCloseModal}
          modalOpen={isPrivacyModalOpen}
          handleSubmit={handleCloseModal}
        />
      ) : null}
    </>
  );
};

export const dangerouslyAddToApp = () => {
  App.component(
    "privacyPolicy",
    react2angular(PrivacyPolicy, [], ["$state", "$rootScope", "$scope"])
  );
};
