import graphql from "babel-plugin-relay/macro";
import NumericInput from "components/FullProgramming/common/NumericInput";
import { emptyStringOrNumber } from "components/FullProgramming/utils/format";
import { resolvePanelType } from "components/FullProgramming/utils/panel";
import { clamp } from "ramda";
import * as React from "react";
import { useHardwareModel } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useSystemOptionsFragment } from "./SystemOptionsContext";
import { wirelessEncryption1100Updater } from "./SystemOptionsWirelessEncryption1100Field";
import { SystemOptionsWirelessHouseCodeField_systemOptions$key } from "./__generated__/SystemOptionsWirelessHouseCodeField_systemOptions.graphql";

export const systemOptionsWirelessHouseCodeFieldId = () =>
  "system-options-wireless-house-code";

function WirelessHouseCodeField() {
  const [{ houseCode, houseCodeMax, houseCodeMin }, updateSystemOptions] =
    useSystemOptionsFragment<SystemOptionsWirelessHouseCodeField_systemOptions$key>(
      graphql`
        fragment SystemOptionsWirelessHouseCodeField_systemOptions on SystemOptions {
          ... on XrSystemOptions {
            houseCode
            houseCodeMin
            houseCodeMax
            wirelessEncryption1100 #needed for encryption updater
            wirelessEncryption1100Passphrase #needed for encryption updater
          }
          ... on XtSystemOptions {
            houseCode
            houseCodeMin
            houseCodeMax
            wirelessEncryption1100 #needed for encryption updater
            wirelessEncryption1100Passphrase #needed for encryption updater
          }
          ... on Xt75SystemOptions {
            houseCode
            houseCodeMin
            houseCodeMax
            wirelessEncryption1100 #needed for encryption updater
            wirelessEncryption1100Passphrase #needed for encryption updater
          }
          ... on XfSystemOptions {
            houseCode
            houseCodeMin
            houseCodeMax
            wirelessEncryption1100 #needed for encryption updater
            wirelessEncryption1100Passphrase #needed for encryption updater
          }
        }
      `
    );

  const hardwareModel = useHardwareModel();
  const { isXf } = resolvePanelType(hardwareModel);
  const fieldId = systemOptionsWirelessHouseCodeFieldId();
  const inlineHelp = isXf
    ? `${houseCodeMin}–${houseCodeMax}, 99`
    : `${houseCodeMin}–${houseCodeMax}`;
  const originalHouseCode = React.useRef(houseCode).current;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Wireless House Code"
      tooltip="House Code used for DMP 1100 series wireless."
    >
      <NumericInput
        id={fieldId}
        name={fieldId}
        value={houseCode}
        required
        inlineHelp={inlineHelp}
        min={houseCodeMin}
        max={isXf ? undefined : houseCodeMax}
        onChange={({ target }) => {
          updateSystemOptions((recordProxy) => {
            recordProxy.setValue(
              emptyStringOrNumber(target.value.toString()),
              "houseCode"
            );

            if (/^(0|00|000)$/.test(target.value)) {
              wirelessEncryption1100Updater("NONE")(recordProxy);
            }
          });
        }}
        onBlur={({ target }) => {
          updateSystemOptions((recordProxy) => {
            const houseCodeAsNumber = Number(target.value);
            const houseCode =
              !target.value || isNaN(houseCodeAsNumber)
                ? originalHouseCode
                : houseCodeAsNumber === 99 && isXf
                ? houseCodeAsNumber
                : clamp(houseCodeMin, houseCodeMax, houseCodeAsNumber);
            recordProxy.setValue(
              target.value === ""
                ? "000"
                : isXf
                ? String(houseCode)
                : target.value,
              "houseCode"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default WirelessHouseCodeField;
