/**
 * @generated SignedSource<<bb39173901d9c9612c7610d9c731ae77>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XT75CardFormatsProgrammingConceptFormInline_cardFormat$data = {
  readonly id: string;
  readonly isNew: boolean;
  readonly name: string;
  readonly number: string;
  readonly requireSiteCode: boolean;
  readonly siteCodeLength: number;
  readonly siteCodePosition: number;
  readonly siteCodes: ReadonlyArray<number | null>;
  readonly userCodeDigits: number;
  readonly userCodeLength: number;
  readonly userCodePosition: number;
  readonly wiegandLength: number;
  readonly " $fragmentType": "XT75CardFormatsProgrammingConceptFormInline_cardFormat";
};
export type XT75CardFormatsProgrammingConceptFormInline_cardFormat$key = {
  readonly " $data"?: XT75CardFormatsProgrammingConceptFormInline_cardFormat$data;
  readonly " $fragmentSpreads": FragmentRefs<"XT75CardFormatsProgrammingConceptFormInline_cardFormat">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XT75CardFormatsProgrammingConceptFormInline_cardFormat"
};

(node as any).hash = "a85a2447119af274249e585b9222cd59";

export default node;
