/**
 * @name App.controller:PanelProgArchiveCtrl
 *
 * @description Controller for the control system programming archive
 * */
App.controller("PanelProgArchiveCtrl", [
  "$scope",
  "PanelProgArchiveService",
  "ControlSystemsService",
  "$rootScope",
  "DataTablesUtilsService",
  "$state",
  "UserService",
  "$q",
  "$filter",
  "InitialConnectionService",
  function (
    $scope,
    PanelProgArchiveService,
    ControlSystemsService,
    $rootScope,
    DataTablesUtilsService,
    $state,
    UserService,
    $q,
    $filter,
    InitialConnectionService
  ) {
    $scope.dt = {
      options: {},
      data: [],
      show: false,
    };
    $scope.schedule = null;
    $scope.scheduleMessage = null;
    $scope.maxProtected = null;

    $scope.loadToView = function (backup) {
      PanelProgArchiveService.cache.backup
        .load(backup.Id)
        .then(
          function () {
            $state.go("app.panel_programming.programming_print", {
              customer_id: UserService.customer_id,
              control_system_id: UserService.control_system_id,
              panel_id: UserService.panel_id,
            });
          },
          function (error) {
            console.error("Error loading backup: " + angular.toJson(error));
            $rootScope.alerts.push({
              type: "error",
              text: "error loading backup",
            });
          }
        )
        .catch(function (error) {
          console.error(
            "Caught error loading backup: " + angular.toJson(error)
          );
          $rootScope.alerts.push({
            type: "error",
            text: "caught error loading backup",
          });
        });
    };

    $scope.sendToPanel = function (backup) {
      PanelProgArchiveService.cache.backup
        .load(backup.Id)
        .then(
          function () {
            PanelProgArchiveService.restorePanel();
          },
          function (error) {
            console.error("Error loading backup: " + angular.toJson(error));
            $rootScope.alerts.push({
              type: "error",
              text: "error loading backup",
            });
          }
        )
        .catch(function (error) {
          console.error(
            "Caught error loading backup: " + angular.toJson(error)
          );
          $rootScope.alerts.push({
            type: "error",
            text: "caught error loading backup",
          });
        });
    };

    $scope.triggerBackup = function () {
      InitialConnectionService.ensureConnectionReady().then(
        function () {
          PanelProgArchiveService.createBackup(UserService.panel_id)
            .then(
              function () {
                $rootScope.alerts.push({
                  type: "success",
                  text: "creating backup - please check back later",
                });
              },
              function (error) {
                console.error(
                  "Error triggering backup: " + angular.toJson(error)
                );
                $rootScope.alerts.push({
                  type: "error",
                  text: "error backing up",
                });
              }
            )
            .catch(function (error) {
              console.error(
                "Caught error triggering backup: " + angular.toJson(error)
              );
              $rootScope.alerts.push({
                type: "error",
                text: "caught error backing up",
              });
            });
        },
        function (error) {
          console.error(
            `PanelProgArchiveCtrl->triggerBackup() - ensure initial connection established error: ${angular.toJson(
              error
            )}`
          );
        }
      );
    };

    $scope.protectionChange = function (backup) {
      PanelProgArchiveService.setProtection(backup)
        .then(
          function () {
            updateMaxProtected();
          },
          function (error) {
            console.error("Error setting protection: " + angular.toJson(error));
            $rootScope.alerts.push({
              type: "error",
              text: "error setting protection",
            });
          }
        )
        .catch(function (error) {
          console.error(
            "Caught error setting protection: " + angular.toJson(error)
          );
          $rootScope.alerts.push({
            type: "error",
            text: "caught error setting protection",
          });
        });
    };

    function init() {
      retrieveData().then(function () {
        initDtOptions();
        $scope.dt.show = true;
        updateMaxProtected();
        formatScheduleMessage();
      });
    }

    function updateMaxProtected() {
      if ($scope.dt.show && $scope.schedule) {
        $scope.maxProtected =
          $scope.dt.data.filter(function (b) {
            return b.Protected;
          }).length >= $scope.schedule.MaxBackups;
        formatScheduleMessage();
      }
    }

    function retrieveData() {
      var deferred = $q.defer();
      var promises = [];
      promises.push(
        PanelProgArchiveService.getBackupsForPanel(
          ControlSystemsService.currentControlSystem.id
        )
          .then(
            function (backups) {
              if (!$scope.dt.show) {
                $scope.dt.data = backups;
              }
            },
            function (error) {
              console.error("Error getting backups: " + angular.toJson(error));
              $rootScope.alerts.push({
                type: "error",
                text: "error getting programming backups",
              });
            }
          )
          .catch(function (error) {
            console.error(
              "Caught error getting programming backups" + angular.toJson(error)
            );
            $rootScope.alerts.push({
              type: "error",
              text: "caught error getting programming backups",
            });
          })
      );
      promises.push(
        PanelProgArchiveService.getSchedule(
          ControlSystemsService.currentControlSystem.id
        )
          .then(
            function (data) {
              $scope.schedule = data;
            },
            function (error) {
              console.error(
                "Error getting panel programming archive schedule: " +
                  angular.toJson(error)
              );
              if (error.status !== 404) {
                $rootScope.alerts.push({
                  type: "error",
                  text: "error getting panel programming archive schedule",
                });
              }
            }
          )
          .catch(function (error) {
            console.error(
              "Caught error getting panel programming archive schedule: " +
                angular.toJson(error)
            );
            $rootScope.alerts.push({
              type: "error",
              text: "caught error getting panel programming archive schedule",
            });
          })
      );
      $q.all(promises)
        .then(
          function () {
            deferred.resolve();
          },
          function () {
            deferred.reject();
          }
        )
        .finally(function () {
          formatScheduleMessage();
        });
      return deferred.promise;
    }

    function initDtOptions() {
      $scope.dt.options = DataTablesUtilsService.getEmptyDTOptions("Reports");
      $scope.dt.options.withButtons([]);
      $scope.dt.options.withOption("order", [0, "desc"]);
      if ($scope.dt.data.length <= 10) {
        $scope.dt.options.withDOM("<t>");
      }
    }

    function formatScheduleMessage() {
      if (!$scope.dt.show) {
        $scope.scheduleMessage = null;
      } else {
        $scope.scheduleMessage = !$scope.dt.data.length ? "No backups. " : "";
        if (!$scope.schedule) {
          $scope.scheduleMessage = "Scheduling first backup";
        } else if (!$scope.schedule.Enabled) {
          $scope.scheduleMessage = "Backups have been disabled for this system";
        } else if ($scope.schedule.InProgress) {
          $scope.scheduleMessage = "Backup in progress";
        } else if ($scope.maxProtected) {
          $scope.scheduleMessage += "Max number of backups protected";
        } else if (!$scope.schedule.NextRunUtc) {
          $scope.scheduleMessage += "Scheduling next backup";
        } else {
          $scope.scheduleMessage += `Next backup scheduled for ${$filter(
            "date"
          )($scope.schedule.NextRunUtc, "medium")}`;
        }
      }
    }

    init();
  },
]);
