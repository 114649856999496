/**
 * @name App.service:UserSettingsService
 *
 * @description Service for saving user settings across all devices
 */
App.service("UserSettingsService", [
  "UserService",
  "UserSettingsAPI",
  function (UserService, UserSettingsAPI) {
    let _this = this;

    const defaultSettings = {
      mapFilters: {},
      tableFilters: {},
      searchFilter: "",
      allOthers: false,
      lightStyle: true,
      numOfRecords: true,
      pagination: {
        itemsPerPage: "10",
        currentPage: 1,
      },
      tableColumns: {
        no_addrs_data: false,
        cust_name: true,
        created_at: true,
        acct_num: true,
        sys_name: true,
        sys_type: true,
        sys_ver: true,
        sof_date: false,
        con_type: true,
        carrier: false,
        app: false,
        net_add: false,
        last_con: false,
        arm_type: false,
        ser_num: false,
        cell_num: false,
        cust_acc: false,
        billing_information: false,
      },
      hasUserSettings: true,
    };

    _this.userSettings = defaultSettings;

    const subscriptions = new Set();
    _this.subscribe = (fn) => {
      subscriptions.add(fn);
      return () => {
        subscriptions.delete(fn);
      };
    };

    const setUserSettings = (userSettings) => {
      _this.userSettings = userSettings;
      subscriptions.forEach((fn) => {
        fn(_this.userSettings);
      });
    };

    const initialize = () =>
      Promise.all([UserService.waitForUser(), UserService.waitForDealerInfo()])
        .then(
          ([user, dealer]) =>
            new Promise((resolve) => {
              UserSettingsAPI.getUserSettings(
                {
                  dealer_id: dealer.id,
                  person_id: user.person_id,
                  app_type: "DealerAdmin",
                },
                ({ $promise, $resolved, ...data }) => {
                  resolve(data);
                },
                () => {
                  resolve(defaultSettings);
                }
              );
            })
        )
        .catch(() => defaultSettings)
        .then((data) => {
          setUserSettings(data);
          return data;
        })
        .finally(() => {
          initializePromise = null;
        });

    let initializePromise = initialize();

    _this.tryNewProgramming = true;

    _this.getUserSettings = () => {
      if (initializePromise) {
        return initializePromise;
      }

      initializePromise = initialize();
      return initializePromise;
    };

    _this.getUserSettingsDefaults = () => defaultSettings;

    _this.setDefaults = function (appType) {
      UserSettingsAPI.updateUserSettings(
        {
          dealer_id: UserService.dealer_id,
          person_id: UserService.user.person_id,
          app_type: appType,
        },
        defaultSettings
      );
    };

    _this.updateUserSettings = function (appType, patchBody) {
      setUserSettings(patchBody);
      UserSettingsAPI.updateUserSettings(
        {
          dealer_id: UserService.dealer_id,
          person_id: UserService.user.person_id,
          app_type: appType,
        },
        patchBody
      );
    };

    _this.useUserSettings = ($scope) => {
      $scope.userSettings = _this.userSettings;
      $scope.$on(
        "$destroy",
        _this.subscribe((userSettings) => {
          $scope.userSettings = userSettings;
        })
      );
    };

    _this.init = () => {
      setUserSettings(defaultSettings);
      initializePromise = initialize();
    };
  },
]);
