/**
 * DEPRECATED - MAY USE AS SIMPLE FILE UPLOADER LATER? -ZC
 * fileModel is a directive used to help with uploading files
 */
App.directive("daSimpleFileUploader", [
  "$parse",
  function ($parse) {
    return {
      restrict: "A",
      scope: { fileChanged: "&fileChanged" },
      link: function (scope, element, attrs) {
        element.bind("change", function () {
          scope.fileChanged({
            file: element[0].files[0],
            properties: attrs.fileProperties,
          });
          element.val(null);
        });
      },
    };
  },
]);

App.directive("daCompetitorFileUploader", [
  "$parse",
  function ($parse) {
    return {
      restrict: "A",
      require: "?ngModel",
      scope: { fileChanged: "&fileChanged" },
      link: function (scope, element, attrs, ngModel) {
        element.bind("change", function () {
          scope.$apply(function () {
            ngModel.$setViewValue(element.val());
          });
          scope.fileChanged({
            file: element[0].files[0],
            properties: attrs.fileProperties,
          });
        });
      },
    };
  },
]);

App.directive("daReferralFileUploader", [
  "$compile",
  function ($compile) {
    return {
      restrict: "E",
      require: "?ngModel",
      template:
        '<input accept="image/*" class="form-control form-control-file-input" id="referral_photo" name="referral_photo" type="file"/>',
      scope: {
        fileChanged: "&fileChanged",
      },
      link: function (scope, element, attrs, ngModel) {
        element.bind("change", function () {
          scope.fileChanged({
            file: element[0].firstChild.files[0],
            properties: attrs.fileProperties,
          });
        });
      },
    };
  },
]);

/*
 * fileUploader is element directive to be used to upload an image to the logoserver.
 * @param is-disabled = Dynamic Value from the View used in, enables/disables functionality in directive. REQUIRED
 * @param file-name - Dynamic value of the name of the file you are trying to upload/view. REQUIRED
 * @param view-width - The width of the image you are trying to get. REQUIRED
 * @param view-height - The height of the image you are trying to get. REQUIRED
 * EXAMPLE: <file-uploader file-name='test' is-disable='inputDisabled' view-width='240' view-height='100'><file-uploader>
 */
App.directive("daFileUploader", [
  "$rootScope",
  "$window",
  "$q",
  "LogoService",
  "UserService",
  function ($rootScope, $window, $q, LogoService, UserService) {
    return {
      restrict: "E",
      templateUrl: "/app/common/templates/file-uploader-tpl.html",
      scope: {
        isDisabled: "=",
        fileName: "=",
      },
      controller: function ($scope, $attrs) {
        init();
        function init() {
          $scope.loading = true;
          $scope.uploading = false;
          $scope.disabled = $scope.isDisabled;
          $q.all([
            LogoService.getLogo(
              UserService.dealer_id,
              $scope.fileName,
              $attrs.viewWidth,
              $attrs.viewHeight
            ),
          ])
            .then(
              function (data) {
                $scope.viewFile = data[0];
                $scope.loading = false;
              },
              function (error) {
                $scope.loading = false;
              }
            )
            .catch(function (error) {
              console.error(error);
            });
        }
        $scope.openView = function () {
          $window.open($scope.viewFile);
        };
        $scope.uploadLogo = function (fileToUpload) {
          $scope.uploading = true;
          LogoService.createLogos(
            UserService.dealer_id,
            $scope.fileName,
            fileToUpload
          )
            .then(
              function (data) {
                $rootScope.alerts.push({
                  type: "success",
                  text: "Logo uploaded",
                });
                $scope.uploading = false;
              },
              function (error) {
                $rootScope.alerts.push({ type: "error", json: error });
              }
            )
            .catch(function (error) {
              console.error(error);
            });
        };
      },
    };
  },
]);
