import React from "react";
import { joinSpaced } from "utils/string";
import styles from "./Alert.module.css";

export default function Alert({
  type = "info",
  icon = (() => {
    switch (type) {
      case "warning":
      case "error":
        return <i className="icon-radial_alert"></i>;
      case "success":
        return <i className="icon-radial_check"></i>;
      case "info":
      default:
        return "";
    }
  })(),
  onClose,
  children,
}: {
  type: "info" | "warning" | "error" | "success";
  icon?: React.ReactNode;
  onClose?: () => void;
  children: React.ReactNode;
}) {
  const getAlertClass = (type: string) => {
    switch (type) {
      case "error":
        return "danger";
      default:
        return type;
    }
  };

  return (
    <div
      className={joinSpaced(
        "alert",
        `alert-${getAlertClass(type)}`,
        !!onClose ? "alert-dismissible" : ""
      )}
      role="alert"
    >
      {!!onClose && (
        <button type="button" className="close" onClick={onClose}>
          <span aria-hidden="true">×</span>
          <span className="sr-only">Close</span>
        </button>
      )}
      <div className={styles["content"]}>
        <div className={styles["icon"]} aria-hidden="true">
          {icon}
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
}
