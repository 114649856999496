/**
 * Controller for the nest UI-VIEW for control system. It's only concern is the presentation of the view.
 */
App.controller("ControlSystemViewCtrl", [
  "$scope",
  "$rootScope",
  "$stateParams",
  "$timeout",
  "UserService",
  "ControlSystemsService",
  "PROPS",
  "$state",
  "VideoDeviceService",
  "OnlinePanelService",
  "ClientEventsService",
  "ModemFirmwareAPI",
  "PanelMacAddressService",
  "BayAlarmBannerApi",
  "$q",
  "PanelProgrammingService",
  "TagsService",
  function (
    $scope,
    $rootScope,
    $stateParams,
    $timeout,
    UserService,
    ControlSystemsService,
    PROPS,
    $state,
    VideoDeviceService,
    OnlinePanelService,
    ClientEventsService,
    ModemFirmwareAPI,
    PanelMacAddressService,
    BayAlarmBannerApi,
    $q,
    PanelProgrammingService,
    TagsService
  ) {
    $scope.panelMacAddress = "";
    $scope.PROPS = PROPS;
    $scope.sendFirmwareUpdate = sendFirmwareUpdate;
    $scope.showModemFirmwareButton = showModemFirmwareButton;
    $scope.CSC = ControlSystemsService; //Used to access ControlSystemsService properties on $scope. Used interchangeably throughout the ctrl.
    $scope.UserService = UserService;
    if ($stateParams.customer_id)
      UserService.customer_id = $stateParams.customer_id;
    UserService.control_system_id = $stateParams.control_system_id;
    $scope.customerId = UserService.customer_id;
    $scope.controlSystemId = $stateParams.control_system_id;
    $scope.loader = {};
    $scope.OnlinePanelService = OnlinePanelService;
    $scope.ClientEventsService = ClientEventsService;
    $scope.getExpandedVideoCount = getExpandedVideoCount;
    $scope.VideoDeviceService = VideoDeviceService;
    $scope.expandedCameraCount = 0;
    $scope.tags = [];
    $scope.isXf = false;
    $scope.isTMSentry = false;

    const macAddressErrorText = `
    Error getting panel mac address.
    Is the panel online?
    Run a connection test to determine if the panel is online.`;

    // busy flag for the mac address refresh button
    $scope.isAttmptingMacAddressRefresh = false;

    // regex for a valid mac address with no colons or dashes
    const validGenericMacAddressRegex = /^[0-9a-fA-F]{12}$/;

    // list of invalid mac addresses for scapi
    const invalidScapiMacAdresses = [
      "000000000000",
      "00ffffffffff",
      "3fffffffffff",
      "ffffffffffff",
    ];
    $scope.macAddressIsValid = (address) => {
      // format the mac address by removing colons and dashes
      // and converting to lowercase
      address = address.replaceAll(/([:-])/g, "").toLowerCase();
      // check if the mac address is structurally valid AND
      return (
        validGenericMacAddressRegex.test(address) &&
        // check if the mac address is not in the list of invalid scapi mac addresses
        !invalidScapiMacAdresses.includes(address)
      );
    };

    /**
     *Send and Clear keypad banner for Bay Alarm keypads
     */
    $scope.bayAlarmSendBanner = function () {
      var deferred = $q.defer();
      var _this = this;
      BayAlarmBannerApi.bannerSend(
        { panel_id: UserService.panel_id, banner: "banner_1" },
        function (data) {
          deferred.resolve(data);
          $rootScope.alerts.push({
            type: "success",
            text: "Banner Sent",
          });
        },
        function (error) {
          deferred.reject(error);
          $rootScope.alerts.push({
            type: "error",
            text: "Banner Send Unsuccessful",
            json: error,
          });
        }
      );
    };

    $scope.bayAlarmClearBanner = function () {
      var deferred = $q.defer();
      var _this = this;
      BayAlarmBannerApi.bannerClear(
        { panel_id: UserService.panel_id, banner: "banner_1", clear: "clear" },
        function (data) {
          deferred.resolve(data);
          $rootScope.alerts.push({
            type: "success",
            text: "Banner Cleared",
          });
        },
        function (error) {
          deferred.reject(error);
          $rootScope.alerts.push({
            type: "error",
            text: "Banner Clear Unsuccessful",
            json: error,
          });
        }
      );
    };

    $scope.disconnect = function () {
      $rootScope.alerts.push({ type: "success", text: "Disconnect Sent" });
      PanelProgrammingService.panel_id = UserService.panel_id;
      PanelProgrammingService.disconnect();
    };

    /*Used in controlSystem View to display number of expanded cameras*/
    getExpandedVideoCount();
    function getExpandedVideoCount() {
      switch (
        ControlSystemsService.currentControlSystem.services_manager
          .video_service_level
      ) {
        case "no_video":
          $scope.expandedCameraCount = 0;
          break;
        case "standard":
          $scope.expandedCameraCount = 8;
          break;
        case "standard_plus_four":
          $scope.expandedCameraCount = 12;
          break;
        case "standard_plus_eight":
          $scope.expandedCameraCount = 16;
          break;
        default:
          $scope.expandedCameraCount = 0;
      }
    }

    function showModemFirmwareButton() {
      return (
        ["XTLP", "XT30", "XT50"].includes(
          $scope.controlSystem.panels[0].hardware_model
        ) &&
        ((599 >= parseInt($scope.controlSystem.panels[0].software_version) &&
          parseInt($scope.controlSystem.panels[0].software_version) >= 172) ||
          parseInt($scope.controlSystem.panels[0].software_version) >= 672) &&
        $scope.controlSystem.panels[0].software_date >= "05-25-18"
      );
    }

    $scope.showDoorControl = function () {
      let hardwareModel =
        $scope.controlSystem.panels[$scope.controlSystem.panel_index]
          .programming_type;
      return (
        hardwareModel == "XR550" ||
        ((hardwareModel == "XT50" ||
          hardwareModel == "XT30" ||
          hardwareModel == "XTLP") &&
          (between(
            $scope.controlSystem.panels[$scope.controlSystem.panel_index]
              .software_version,
            202,
            599
          ) ||
            between(
              $scope.controlSystem.panels[$scope.controlSystem.panel_index]
                .software_version,
              702,
              Infinity
            )))
      );
    };

    $scope.showDoor = (door) =>
      door.tracked === true && (door.tipe === "1" || door.door === "Y");

    function setControlSystem() {
      $scope.controlSystem = ControlSystemsService.currentControlSystem;
      $scope.isTMSentry =
        $scope.controlSystem.panels[0].hardware_model === "TMS6";
      if ($scope.controlSystem.panels[0].hardware_model === "X001") {
        $state.go("app.sites.edit", {
          customer_id: $scope.controlSystem.customer_id,
          site_id: $scope.controlSystem.site_id,
        });
      } else {
        if ($state.is("app.control_system")) {
          $scope.loader.isBusy = true;
          UserService.isBusy = true;
          $scope.loader.isBusy = false;
          UserService.isBusy = false;
        } else {
          $scope.loader.isBusy = false;
          UserService.isBusy = false;
        }
        $scope.isXf = $scope.controlSystem.panels[0].programming_type === "XF6";
      }
      TagsService.getPanelTags(UserService.panel_id).then(
        (tags) => ($scope.tags = tags)
      );
    }
    // get the control system and then get the mac address
    const setControlSystemAndMacAddress = async () => {
      await setControlSystem();
      $scope.getPanelMacAddress(UserService.panel_id);
    };

    setControlSystemAndMacAddress();

    $scope.navBack = function () {
      $state.go("app.customers.customersummary", {
        customer_id: UserService.customer_id,
        dealer_id: UserService.dealerInfo.id,
      });
    };

    $scope.getPanelMacAddress = function (panel_id) {
      var deferred = $q.defer();
      PanelMacAddressService.get(UserService.panel_id).then(
        (data) => {
          $scope.panelMacAddress = data.panel_mac_and_serial.mac_address;
          deferred.resolve(data);
        },
        (error) => {
          deferred.reject(error);
        }
      );
      return deferred.promise;
    };

    /**
     * function: refreshPanelMacAddress
     * desc: to refresh the panel mac address
     * @param {string} panel_id
     *
     * @returns {object} promise
     */
    $scope.refreshPanelMacAddress = function (panel_id) {
      $scope.isAttmptingMacAddressRefresh = true;
      var deferred = $q.defer();
      PanelMacAddressService.refresh(panel_id).then(
        (success) => {
          $scope.isAttmptingMacAddressRefresh = false;
          deferred.resolve(success);
        },
        (error) => {
          deferred.reject(error);
          $scope.isAttmptingMacAddressRefresh = false;
          //In this case the job is not successful because the panel may be offline
          $rootScope.alerts.push({
            type: "error",
            text: macAddressErrorText,
            json: error,
          });
        }
      );
      // gets the panel mac address after the refresh
      $scope.getPanelMacAddress(panel_id);
      return deferred.promise;
    };

    function sendFirmwareUpdate() {
      ModemFirmwareAPI.updateFirmware(
        { panel_id: $scope.controlSystemId },
        function (data) {
          $timeout(function () {
            PanelV2API.disconnect(
              { panel_id: $scope.controlSystemId },
              function () {
                $rootScope.alerts.push({
                  type: "success",
                  text: "Panel Disconnect Sent",
                });
              },
              function () {
                $rootScope.alerts.push({
                  type: "error",
                  text: "Error disconnecting panel",
                  json: error,
                });
              }
            );
            $rootScope.alerts.push({
              type: "success",
              text: "Firmware Update Sent",
            });
          }, 1000);
        },
        function (error) {
          $rootScope.alerts.push({
            type: "error",
            text: "Error updating modem firmware",
            json: error,
          });
        }
      );
    }
  },
]);
