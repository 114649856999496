import graphql from "babel-plugin-relay/macro";
import { emptyStringOrNumber } from "components/FullProgramming/utils/format";
import { clamp } from "ramda";
import * as React from "react";
import { usePanelFragment } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import TextInput from "../TextInput";
import { useCardFormatFragment } from "./CardFormatContext";
import { CardFormatWiegandCodeLengthField_cardFormat$key } from "./__generated__/CardFormatWiegandCodeLengthField_cardFormat.graphql";
import { CardFormatWiegandCodeLengthField_CardFormatList_panel$key } from "./__generated__/CardFormatWiegandCodeLengthField_CardFormatList_panel.graphql";

export const cardFormatWiegandCodeLengthFieldId = (number: string) =>
  `card-format-wiegand-code-length-${number}`;

function CardFormatWiegandCodeLengthField() {
  const [{ id, wiegandLength, number }, updateCardFormat] =
    useCardFormatFragment<CardFormatWiegandCodeLengthField_cardFormat$key>(
      graphql`
        fragment CardFormatWiegandCodeLengthField_cardFormat on CardFormat {
          id
          wiegandLength
          number
        }
      `
    );

  const [panel] =
    usePanelFragment<CardFormatWiegandCodeLengthField_CardFormatList_panel$key>(
      graphql`
        fragment CardFormatWiegandCodeLengthField_CardFormatList_panel on Panel {
          cardFormats {
            id
            wiegandLength
          }
        }
      `
    );

  const fieldId = cardFormatWiegandCodeLengthFieldId(number);
  const { current: originalValue } = React.useRef(wiegandLength);
  const disabled = false;

  const otherWiegandLengthList = panel.cardFormats
    .filter((format) => format.id !== id && format.wiegandLength)
    .map((format) => format.wiegandLength);

  const duplicateWiegandLengthPattern = otherWiegandLengthList
    .map((length) => `^${length}$`)
    .join(`|`);

  const pattern = duplicateWiegandLengthPattern
    ? `(?!${duplicateWiegandLengthPattern}|^000$|^00$|^0$)^[0-1]?[0-9]{1,2}|2[0-4][0-9]|25[0-5]$`
    : `(?!^000$|^00$|^0$)^[0-1]?[0-9]{1,2}|2[0-4][0-9]|25[0-5]$`;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Wiegand Code Length"
      disabled={disabled}
      tooltip="Length of the code, including parity bits."
    >
      <TextInput
        id={fieldId}
        disabled={disabled}
        pattern={pattern}
        value={wiegandLength ?? 26}
        validationMessage="Valid values are 1-255."
        inlineHelp={"1-255"}
        required
        onChange={({ target }) => {
          if (!isNaN(Number(target.value))) {
            updateCardFormat((recordProxy) => {
              recordProxy.setValue(
                emptyStringOrNumber(target.value),
                "wiegandLength"
              );
            });
          }
        }}
        onBlur={({ target }) => {
          updateCardFormat((recordProxy) => {
            const valueAsNumber = Number(target.value);
            const value = isNaN(valueAsNumber)
              ? originalValue
              : clamp(1, 255, valueAsNumber);
            recordProxy.setValue(value, "wiegandLength");
          });
        }}
        getValidationMessage={(input) => {
          if (input.validity.patternMismatch) {
            if (`${wiegandLength}`.match(duplicateWiegandLengthPattern))
              return "Wiegand length already in use";
          }
          return "";
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default CardFormatWiegandCodeLengthField;
