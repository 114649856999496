/**
 * @ngdoc service
 * @name App.factory:CompetitorUpgradeAPI
 *
 * @description
 * API factory for Competitor Upgrade Program
 *
 */

App.factory("CompetitorUpgradeAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.dealerApiUrl +
        "/api/v1/CompetitorUpgrades/Dealer/:dealer_id/ControlSystem/:control_system_id/:action/:CompetitorUpgradeId",
      {},
      {
        getCompUpgrade: {
          method: "GET",
          params: {
            dealer_id: "@dealer_id",
            control_system_id: "@control_system_id",
          },
        },
        sendRebateInfo: { method: "POST", params: { dealer_id: "@dealer_id" } },
        deleteRebateInfo: {
          method: "DELETE",
          params: {
            dealer_id: "@dealer_id",
            action: "Upgrade",
            CompetitorUpgradeId: "@CompetitorUpgradeId",
          },
        },
        changeRebateInfo: {
          method: "PUT",
          params: {
            dealer_id: "@dealer_id",
            control_system_id: "@control_system_id",
          },
        },
      }
    );
  },
]);
