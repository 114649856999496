/**
 * @ngdoc service
 * @name App.factory:CompetitorUpgradeAuditAPI
 *
 * @description
 * API factory for Competitor Upgrade Audit Program
 *
 */

App.factory("CompetitorUpgradeAuditAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.dealerApiUrl + "/api/v1/CompetitorUpgrades",
      {},
      {
        getCompUpgrades: { method: "GET", isArray: true },
      }
    );
  },
]);
