import graphql from "babel-plugin-relay/macro";
import useEffectOnce from "common/react-hooks/use-effect-once";
import { isNotNullOrUndefinedOrEmpty } from "common/utils/universal/function";
import { Mutable } from "common/utils/universal/types";
import Alert from "components/Alert";
import Modal from "components/Modal";
import {
  DeleteConfirmModalBody,
  DeleteConfirmModalOverlay,
} from "components/Modal/ConfirmModal";
import Select from "components/Select";
import { always, assoc, clamp, prop, range, update } from "ramda";
import * as React from "react";
import { useFragment, useMutation } from "react-relay/hooks";
import { ErrorType } from "securecom-graphql/client";
import { useCreateNotification } from "../EntryPointContext";
import {
  FieldErrorMessage,
  FieldInputContainer,
  FieldLabel,
  FieldRow,
  NaturalNumberInput,
  Switch,
} from "../FormFields";
import { AddButton } from "../FormFields/SaveButton";
import Spacer from "../Layout/Spacer";
import { SavingState } from "../types";
import { CardFormatFormAddModal_site$key } from "./__generated__/CardFormatFormAddModal_site.graphql";
import {
  CardFormatFormAddMutation,
  NewCardFormatInput,
} from "./__generated__/CardFormatFormAddMutation.graphql";
import { CardFormatFormExistingModal_cardFormat$key } from "./__generated__/CardFormatFormExistingModal_cardFormat.graphql";
import { CardFormatFormExistingModal_site$key } from "./__generated__/CardFormatFormExistingModal_site.graphql";
import { CardFormatFormRemoveMutation } from "./__generated__/CardFormatFormRemoveMutation.graphql";
import {
  CardFormatFormUpdateMutation,
  CardFormatInput,
} from "./__generated__/CardFormatFormUpdateMutation.graphql";
import { CardFormatForm_site$key } from "./__generated__/CardFormatForm_site.graphql";

const addCardFormatMutation = graphql`
  mutation CardFormatFormAddMutation(
    $siteId: ID!
    $cardFormat: NewCardFormatInput!
  ) {
    addSiteCardFormat(siteId: $siteId, cardFormat: $cardFormat) {
      ... on AddSiteCardFormatSuccessResponse {
        site {
          ...SiteCardFormatsSection_site
        }
        cardFormat {
          ...CardFormatFormExistingModal_cardFormat
        }
      }
      ... on NotFoundError {
        errorType: type
      }
    }
  }
`;

const createNewInputFromState = ({
  name,
  type,
  wiegandLength,
  siteCodePosition,
  siteCodeLength,
  userCodePosition,
  userCodeLength,
  userCodeDigits,
  requireSiteCode,
  siteCodes,
}: CardFormatState): NewCardFormatInput => ({
  name,
  type,
  wiegandLength,
  siteCodePosition,
  siteCodeLength,
  userCodePosition,
  userCodeLength,
  userCodeDigits,
  requireSiteCode,
  siteCodes: siteCodes.map((siteCode) =>
    isNotNullOrUndefinedOrEmpty(siteCode) ? Number(siteCode) : null
  ),
});

const createUpdateInputFromState = ({
  id,
  name,
  type,
  wiegandLength,
  siteCodePosition,
  siteCodeLength,
  userCodePosition,
  userCodeLength,
  userCodeDigits,
  requireSiteCode,
  siteCodes,
}: CardFormatState): CardFormatInput => ({
  id,
  name,
  type,
  wiegandLength,
  siteCodePosition,
  siteCodeLength,
  userCodePosition,
  userCodeLength,
  userCodeDigits,
  requireSiteCode,
  siteCodes: siteCodes.map((siteCode) =>
    isNotNullOrUndefinedOrEmpty(siteCode) ? Number(siteCode) : null
  ),
});

const hasUniqueWiegandLength = (
  cardFormats: ReadonlyArray<{
    readonly id: string;
    readonly wiegandLength: number;
  }>,
  state: CardFormatState
): boolean => {
  return cardFormats.every(
    (cardFormat) =>
      cardFormat.id === state.id ||
      Number(cardFormat.wiegandLength) !== Number(state.wiegandLength)
  );
};

const nameIsValid = (state: CardFormatState): boolean => !!state.name.trim();

const formIsValid = (
  cardFormats: ReadonlyArray<{
    readonly id: string;
    readonly wiegandLength: number;
  }>,
  state: CardFormatState
): boolean => nameIsValid(state) && hasUniqueWiegandLength(cardFormats, state);

const updateCardFormatMutation = graphql`
  mutation CardFormatFormUpdateMutation(
    $siteId: ID!
    $cardFormat: CardFormatInput!
  ) {
    updateSiteCardFormat(siteId: $siteId, cardFormat: $cardFormat) {
      ... on UpdateSiteCardFormatSuccessResponse {
        site {
          ...SiteCardFormatsSection_site
        }
        cardFormat {
          ...CardFormatFormExistingModal_cardFormat
        }
      }
      ... on NotFoundError {
        errorType: type
      }
    }
  }
`;

const removeCardFormatMutation = graphql`
  mutation CardFormatFormRemoveMutation($siteId: ID!, $cardFormatId: ID!) {
    removeSiteCardFormat(siteId: $siteId, cardFormatId: $cardFormatId) {
      ... on RemoveSiteCardFormatSuccessResponse {
        site {
          ...SiteCardFormatsSection_site
        }
      }
      ... on NotFoundError {
        errorType: type
      }
      ... on ConnectionTimeoutError {
        errorType: type
      }
    }
  }
`;

export type CardFormatSiteCode = number | null;

export type CardFormatState = {
  id: string;
  name: string;
  isSubmitted: boolean;
  type: number;
  wiegandLength: number;
  siteCodePosition: number;
  siteCodeLength: number;
  userCodePosition: number;
  userCodeLength: number;
  userCodeDigits: number;
  requireSiteCode: boolean;
  siteCodes: CardFormatSiteCode[];
};

enum CardType {
  CUSTOM = 0,
  DMP = 1,
  DMP_BLUETOOTH = 2,
  STANDARD_26 = 3,
  STANDARD_37 = 4,
  STANDARD_37_NO_CODE = 5,
  CORP_35 = 6,
  CORP_48 = 7,
}

const wiegandLengthMin = always(1);
const wiegandLengthMax = always(255);
const clampWiegandLength = clamp(wiegandLengthMin(), wiegandLengthMax());

export const defaultCardFormatState: CardFormatState = {
  id: "new",
  name: "",
  isSubmitted: false,
  type: 0,
  wiegandLength: wiegandLengthMin(),
  siteCodePosition: 1,
  siteCodeLength: 1,
  userCodePosition: 0,
  userCodeLength: 16,
  userCodeDigits: 4,
  requireSiteCode: false,
  siteCodes: [null, null, null, null, null, null, null, null],
};

function CardFormatForm({
  state,
  onChange,
  siteRef,
}: {
  state: CardFormatState;
  onChange: React.Dispatch<React.SetStateAction<CardFormatState>>;
  siteRef: CardFormatForm_site$key;
}) {
  const data = useFragment(
    graphql`
      fragment CardFormatForm_site on Site {
        cardFormats {
          id
          wiegandLength
        }
      }
    `,
    siteRef
  );

  const nameFieldRef: React.RefObject<HTMLInputElement> = React.useRef(null);

  useEffectOnce(() => {
    if (!state.name && nameFieldRef.current) {
      nameFieldRef.current.focus();
    }
  });

  const label = (field: string) => `${state.id}-${field}`;
  const handleTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = parseInt(event.target.value);

    switch (value) {
      case CardType.DMP:
        onChange({
          ...state,
          type: value,
          wiegandLength: 26,
          siteCodePosition: 1,
          siteCodeLength: 7,
          userCodePosition: 8,
          userCodeLength: 17,
          userCodeDigits: 5,
        });
        break;
      case CardType.DMP_BLUETOOTH:
        onChange({
          ...state,
          type: value,
          wiegandLength: 56,
          siteCodePosition: 1,
          siteCodeLength: 16,
          userCodePosition: 17,
          userCodeLength: 34,
          userCodeDigits: 10,
        });
        break;
      case CardType.STANDARD_26:
        onChange({
          ...state,
          type: value,
          wiegandLength: 26,
          siteCodePosition: 1,
          siteCodeLength: 8,
          userCodePosition: 9,
          userCodeLength: 16,
          userCodeDigits: 5,
        });
        break;
      case CardType.STANDARD_37:
        onChange({
          ...state,
          type: value,
          wiegandLength: 37,
          siteCodePosition: 1,
          siteCodeLength: 16,
          userCodePosition: 17,
          userCodeLength: 19,
          userCodeDigits: 6,
        });
        break;
      case CardType.STANDARD_37_NO_CODE:
        onChange({
          ...state,
          type: value,
          wiegandLength: 37,
          siteCodePosition: 0,
          siteCodeLength: 1,
          userCodePosition: 1,
          userCodeLength: 35,
          userCodeDigits: 11,
        });
        break;
      case CardType.CORP_35:
        onChange({
          ...state,
          type: value,
          wiegandLength: 35,
          siteCodePosition: 2,
          siteCodeLength: 12,
          userCodePosition: 14,
          userCodeLength: 20,
          userCodeDigits: 6,
        });
        break;
      case CardType.CORP_48:
        onChange({
          ...state,
          type: value,
          wiegandLength: 48,
          siteCodePosition: 2,
          siteCodeLength: 22,
          userCodePosition: 24,
          userCodeLength: 23,
          userCodeDigits: 7,
        });
      default:
        onChange(assoc("type", value));
    }
  };

  return (
    <>
      <FieldRow error={!state.name && state.isSubmitted}>
        <FieldLabel htmlFor={label("name")}>Name</FieldLabel>
        <FieldInputContainer>
          <input
            ref={nameFieldRef}
            id={label("name")}
            name={label("name")}
            className="form-control"
            maxLength={32}
            type="text"
            value={state.name}
            onChange={({ target }) => {
              onChange(assoc("name", target.value));
            }}
            onBlur={({ target }) => {
              onChange(assoc("name", target.value.trim()));
            }}
          />
          {!state.name && state.isSubmitted && (
            <FieldErrorMessage>Name is required</FieldErrorMessage>
          )}
        </FieldInputContainer>
      </FieldRow>

      <FieldRow>
        <FieldLabel htmlFor={label("type")}>Type</FieldLabel>
        <FieldInputContainer>
          <Select value={state.type} onChange={handleTypeChange}>
            <Select.Option key={CardType.CUSTOM} value={CardType.CUSTOM}>
              Custom
            </Select.Option>
            <Select.Option key={CardType.DMP} value={CardType.DMP}>
              DMP
            </Select.Option>
            <Select.Option
              key={CardType.DMP_BLUETOOTH}
              value={CardType.DMP_BLUETOOTH}
            >
              DMP Bluetooth 56-Bit
            </Select.Option>
            <Select.Option
              key={CardType.STANDARD_26}
              value={CardType.STANDARD_26}
            >
              Standard 26 Bit
            </Select.Option>
            <Select.Option
              key={CardType.STANDARD_37}
              value={CardType.STANDARD_37}
            >
              Standard 37 Bit (with Facility Code)
            </Select.Option>
            <Select.Option
              key={CardType.STANDARD_37_NO_CODE}
              value={CardType.STANDARD_37_NO_CODE}
            >
              Standard 37 Bit (without Facility Code)
            </Select.Option>
            <Select.Option key={CardType.CORP_35} value={CardType.CORP_35}>
              Corporate 1000 (35 Bit)
            </Select.Option>
            <Select.Option key={CardType.CORP_48} value={CardType.CORP_48}>
              Corporate 1000 (48 Bit)
            </Select.Option>
          </Select>
        </FieldInputContainer>
      </FieldRow>

      <FieldRow error={!hasUniqueWiegandLength(data.cardFormats, state)}>
        <FieldLabel htmlFor={label("wiegandLength")}>Wiegand Length</FieldLabel>

        <FieldInputContainer>
          <NaturalNumberInput
            id={label("wiegandLength")}
            name={label("wiegandLength")}
            className="form-control"
            min={wiegandLengthMin()}
            max={wiegandLengthMax()}
            maxLength={3}
            value={state.wiegandLength}
            onChange={({ currentTarget }) => {
              onChange(
                assoc(
                  "wiegandLength",
                  currentTarget.value === ""
                    ? undefined
                    : Number(currentTarget.value)
                )
              );
            }}
            onBlur={() => {
              const clamppedWiegandLength = clampWiegandLength(
                Number(state.wiegandLength)
              );

              if (
                !isNaN(clamppedWiegandLength) &&
                state.wiegandLength !== clamppedWiegandLength
              ) {
                onChange(assoc("wiegandLength", clamppedWiegandLength));
              }
            }}
            disabled={state.type !== 0}
          />
          {!hasUniqueWiegandLength(data.cardFormats, state) && (
            <FieldErrorMessage>Wiegand Length already in use</FieldErrorMessage>
          )}
        </FieldInputContainer>
      </FieldRow>

      <FieldRow>
        <FieldLabel htmlFor={label("siteCodePosition")}>
          Site Code Position
        </FieldLabel>
        <NaturalNumberInput
          id={label("siteCodePosition")}
          name={label("siteCodePosition")}
          className="form-control"
          max={255}
          maxLength={3}
          value={state.siteCodePosition}
          onChange={({ currentTarget }) => {
            onChange(
              assoc(
                "siteCodePosition",
                currentTarget.value === ""
                  ? undefined
                  : Number(currentTarget.value)
              )
            );
          }}
          onBlur={({ currentTarget }) => {
            onChange(
              assoc(
                "siteCodePosition",
                clamp<number>(0, 255, Number(currentTarget.value))
              )
            );
          }}
          disabled={state.type !== 0}
        />
      </FieldRow>

      <FieldRow>
        <FieldLabel htmlFor={label("siteCodeLength")}>
          Site Code Length
        </FieldLabel>
        <NaturalNumberInput
          id={label("siteCodeLength")}
          name={label("siteCodeLength")}
          className="form-control"
          min={1}
          max={24}
          maxLength={2}
          value={state.siteCodeLength}
          onChange={({ currentTarget }) => {
            onChange(
              assoc(
                "siteCodeLength",
                currentTarget.value === ""
                  ? undefined
                  : Number(currentTarget.value)
              )
            );
          }}
          onBlur={({ currentTarget }) => {
            onChange(
              assoc(
                "siteCodeLength",
                clamp<number>(1, 24, Number(currentTarget.value))
              )
            );
          }}
          disabled={state.type !== 0}
        />
      </FieldRow>

      <FieldRow>
        <FieldLabel htmlFor={label("userCodePosition")}>
          User Code Position
        </FieldLabel>
        <NaturalNumberInput
          id={label("userCodePosition")}
          name={label("userCodePosition")}
          className="form-control"
          max={255}
          maxLength={3}
          value={state.userCodePosition}
          onChange={({ currentTarget }) => {
            onChange(
              assoc(
                "userCodePosition",
                currentTarget.value === ""
                  ? undefined
                  : Number(currentTarget.value)
              )
            );
          }}
          onBlur={({ currentTarget }) => {
            onChange(
              assoc(
                "userCodePosition",
                clamp<number>(0, 255, Number(currentTarget.value))
              )
            );
          }}
          disabled={state.type !== 0}
        />
      </FieldRow>

      <FieldRow>
        <FieldLabel htmlFor={label("userCodeLength")}>
          User Code Length
        </FieldLabel>
        <NaturalNumberInput
          id={label("userCodeLength")}
          name={label("userCodeLength")}
          className="form-control"
          min={16}
          max={64}
          maxLength={2}
          value={state.userCodeLength}
          onChange={({ currentTarget }) => {
            onChange(
              assoc(
                "userCodeLength",
                currentTarget.value === ""
                  ? undefined
                  : Number(currentTarget.value)
              )
            );
          }}
          onBlur={({ currentTarget }) => {
            onChange(
              assoc(
                "userCodeLength",
                clamp<number>(16, 64, Number(currentTarget.value))
              )
            );
          }}
          disabled={state.type !== 0}
        />
      </FieldRow>

      <FieldRow>
        <FieldLabel htmlFor={label("userCodeDigits")}>
          User Code Digits
        </FieldLabel>
        <NaturalNumberInput
          id={label("userCodeDigits")}
          name={label("userCodeDigits")}
          className="form-control"
          type="number"
          min={4}
          max={12}
          maxLength={2}
          value={state.userCodeDigits}
          onChange={({ currentTarget }) => {
            onChange(
              assoc(
                "userCodeDigits",
                currentTarget.value === ""
                  ? undefined
                  : Number(currentTarget.value)
              )
            );
          }}
          onBlur={({ currentTarget }) => {
            onChange(
              assoc(
                "userCodeDigits",
                clamp<number>(4, 12, Number(currentTarget.value))
              )
            );
          }}
          disabled={state.type !== 0}
        />
      </FieldRow>

      <FieldRow>
        <FieldLabel htmlFor={label("requireSiteCode")}>
          Require Site Code
        </FieldLabel>
        <Switch
          label="Require Site Code"
          id={label("requireSiteCode")}
          name={label("requireSiteCode")}
          checked={state.requireSiteCode}
          onChange={() => {
            onChange(assoc("requireSiteCode", !state.requireSiteCode));
          }}
        />
      </FieldRow>

      {state.requireSiteCode &&
        state.siteCodes.map((siteCode, index) => {
          const siteCodeLabel = label(`site-code:${index}`);

          return (
            <FieldRow key={index}>
              <FieldLabel htmlFor={siteCodeLabel}>
                Site Code {index + 1}
              </FieldLabel>
              <NaturalNumberInput
                id={siteCodeLabel}
                name={siteCodeLabel}
                className="form-control"
                min={0}
                max={16777214}
                maxLength={8}
                value={
                  siteCode === undefined || siteCode === null ? "" : siteCode
                }
                onChange={({ currentTarget }) => {
                  onChange({
                    ...state,
                    siteCodes: update(
                      index,
                      currentTarget.value === ""
                        ? null
                        : Number(currentTarget.value),
                      state.siteCodes
                    ),
                  });
                }}
              />
            </FieldRow>
          );
        })}
    </>
  );
}

export function NewCardFormatModal({
  siteRef,
  onCancel,
  onSaved,
}: {
  siteRef: CardFormatFormAddModal_site$key;
  onCancel: () => void;
  onSaved: () => void;
}) {
  const data = useFragment(
    graphql`
      fragment CardFormatFormAddModal_site on Site {
        ...CardFormatForm_site
        id
        cardFormats {
          id
          wiegandLength
        }
      }
    `,
    siteRef
  );

  const [state, setState] = React.useState(
    React.useMemo(() => {
      const takenWiegandLengths = new Set(
        data.cardFormats.map(prop("wiegandLength"))
      );
      return {
        ...defaultCardFormatState,
        wiegandLength:
          range(wiegandLengthMin(), wiegandLengthMax() + 1).find(
            (value) => !takenWiegandLengths.has(value)
          ) ?? wiegandLengthMin(),
      };
    }, [])
  );

  const [addCardFormat, addingCardFormat] =
    useMutation<CardFormatFormAddMutation>(addCardFormatMutation);

  const [saveError, setSaveError] = React.useState<React.ReactNode | null>(
    null
  );
  const createNotification = useCreateNotification();
  return (
    <Modal>
      <form
        onSubmit={(event) => {
          event.preventDefault();

          const formCanSubmit =
            formIsValid(data.cardFormats, state) && !addingCardFormat;

          if (formCanSubmit) {
            addCardFormat({
              variables: {
                siteId: data.id,
                cardFormat: createNewInputFromState(state),
              },
              onError: () => {
                setSaveError(() => "Unable to add card format.");
              },
              onCompleted({ addSiteCardFormat }) {
                if (addSiteCardFormat.cardFormat) {
                  onSaved();
                  createNotification({
                    type: "success",
                    text: "Card format was added.",
                  });
                } else {
                  setSaveError(() => {
                    switch (addSiteCardFormat.errorType) {
                      case ErrorType.NOT_FOUND:
                        return "Unable to find system.";
                      default:
                        return "Unable to add card format.";
                    }
                  });
                }
              },
            });
          } else {
            setState({
              ...state,
              isSubmitted: true,
            });
          }
        }}
      >
        <Modal.Header>
          <h3 className="mar-0">Add Card Format</h3>
        </Modal.Header>
        <Modal.Body>
          <CardFormatForm state={state} onChange={setState} siteRef={data} />
          {saveError && (
            <>
              <Spacer />
              <Alert type="error">{saveError}</Alert>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-default"
            disabled={addingCardFormat}
            onClick={onCancel}
          >
            Cancel
          </button>
          <AddButton
            type="submit"
            savingState={
              addingCardFormat ? SavingState.Saving : SavingState.Idle
            }
            disabled={addingCardFormat}
          />
        </Modal.Footer>
      </form>
    </Modal>
  );
}

export function ExistingCardFormatModal({
  siteRef,
  cardFormatRef,
  onSaved,
  onDeleted,
  onCancel,
}: {
  siteRef: CardFormatFormExistingModal_site$key;
  cardFormatRef: CardFormatFormExistingModal_cardFormat$key;
  onSaved: () => void;
  onDeleted: () => void;
  onCancel: () => void;
}) {
  const siteData = useFragment(
    graphql`
      fragment CardFormatFormExistingModal_site on Site {
        ...CardFormatForm_site
        id
        cardFormats {
          id
          wiegandLength
        }
      }
    `,
    siteRef
  );
  const cardFormatData = useFragment(
    graphql`
      fragment CardFormatFormExistingModal_cardFormat on CardFormat {
        id
        name
        type
        wiegandLength
        siteCodePosition
        siteCodeLength
        userCodePosition
        userCodeLength
        userCodeDigits
        requireSiteCode
        siteCodes
      }
    `,
    cardFormatRef
  );
  const [state, setState] = React.useState({
    id: cardFormatData.id,
    name: cardFormatData.name,
    isSubmitted: false,
    type: cardFormatData.type,
    wiegandLength: cardFormatData.wiegandLength,
    siteCodePosition: cardFormatData.siteCodePosition,
    siteCodeLength: cardFormatData.siteCodeLength,
    userCodePosition: cardFormatData.userCodePosition,
    userCodeLength: cardFormatData.userCodeLength,
    userCodeDigits: cardFormatData.userCodeDigits,
    requireSiteCode: cardFormatData.requireSiteCode,
    siteCodes: cardFormatData.siteCodes as Mutable<CardFormatSiteCode[]>,
  });

  const [saveError, setSaveError] = React.useState<React.ReactNode | null>(
    null
  );

  const [confirmingDelete, setConfirmingDelete] = React.useState(false);

  const [updateCardFormat, updatingCardFormat] =
    useMutation<CardFormatFormUpdateMutation>(updateCardFormatMutation);
  const [deleteCardFormat, deletingCardFormat] =
    useMutation<CardFormatFormRemoveMutation>(removeCardFormatMutation);
  const createNotification = useCreateNotification();
  return (
    <Modal>
      <form
        onSubmit={(event) => {
          event.preventDefault();

          if (formIsValid(siteData.cardFormats, state)) {
            updateCardFormat({
              variables: {
                siteId: siteData.id,
                cardFormat: {
                  ...createUpdateInputFromState(state),
                  id: cardFormatData.id,
                },
              },
              onError: () => {
                setSaveError(() => "Unable to update card format.");
              },
              onCompleted({ updateSiteCardFormat }) {
                if (updateSiteCardFormat.cardFormat) {
                  onSaved();
                  createNotification({
                    type: "success",
                    text: "Card format was updated.",
                  });
                } else {
                  setSaveError(() => {
                    switch (updateSiteCardFormat.errorType) {
                      case ErrorType.NOT_FOUND:
                        return "Unable to find system.";
                      default:
                        return "Unable to update card format.";
                    }
                  });
                }
              },
            });
          } else {
            setState({
              ...state,
              isSubmitted: true,
            });
          }
        }}
      >
        <Modal.Header>
          <h3 className="mar-0">Edit Card Format</h3>
        </Modal.Header>
        <Modal.Body>
          <CardFormatForm
            state={state}
            onChange={setState}
            siteRef={siteData}
          />
          {saveError && (
            <>
              <Spacer />
              <Alert type="error">{saveError}</Alert>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-default"
            disabled={updatingCardFormat || deletingCardFormat}
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-info"
            disabled={updatingCardFormat || deletingCardFormat}
          >
            Save
          </button>
          <button
            type="button"
            className="btn btn-danger"
            disabled={updatingCardFormat || deletingCardFormat}
            onClick={() => {
              setConfirmingDelete(true);
            }}
          >
            Delete
          </button>
        </Modal.Footer>
      </form>
      {confirmingDelete && (
        <DeleteConfirmModalOverlay>
          <DeleteConfirmModalBody
            actionPending={deletingCardFormat}
            onConfirm={() => {
              if (!deletingCardFormat) {
                deleteCardFormat({
                  variables: {
                    siteId: siteData.id,
                    cardFormatId: cardFormatData.id,
                  },
                  onError: () => {
                    setSaveError(() => "Unable to remove card format.");
                  },
                  onCompleted({ removeSiteCardFormat }) {
                    if (removeSiteCardFormat.site) {
                      onDeleted();
                      createNotification({
                        type: "success",
                        text: "Card format was deleted",
                      });
                    } else {
                      setSaveError(() => {
                        switch (removeSiteCardFormat.errorType) {
                          case ErrorType.NOT_FOUND:
                            return "Unable to find system.";
                          default:
                            return "Unable to remove card format.";
                        }
                      });
                    }
                  },
                });
              }
            }}
            onCancel={() => {
              setConfirmingDelete(false);
            }}
          >
            Are you sure you want to delete this card format?
          </DeleteConfirmModalBody>
        </DeleteConfirmModalOverlay>
      )}
    </Modal>
  );
}
