/**
 * @generated SignedSource<<e8212923b8b4f7568178fa34f4c0d96e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TestConnectionModalTestConnectionMutation$variables = {
  id: string;
};
export type TestConnectionModalTestConnectionMutation$data = {
  readonly testCameraConnection: {
    readonly __typename: string;
  };
};
export type TestConnectionModalTestConnectionMutation = {
  response: TestConnectionModalTestConnectionMutation$data;
  variables: TestConnectionModalTestConnectionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TestConnectionModalTestConnectionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "testCameraConnection",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TestConnectionModalTestConnectionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "testCameraConnection",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isTestCameraConnectionResponse"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "type": "Node",
            "abstractKey": "__isNode"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8cdad79fcfa2b25f75fbc46fe837a3cd",
    "id": null,
    "metadata": {},
    "name": "TestConnectionModalTestConnectionMutation",
    "operationKind": "mutation",
    "text": "mutation TestConnectionModalTestConnectionMutation(\n  $id: ID!\n) {\n  testCameraConnection(id: $id) {\n    __typename\n    __isTestCameraConnectionResponse: __typename\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "977ed599b35b19019eff2d9c65bade8f";

export default node;
