/* eslint-disable jsx-a11y/anchor-is-valid */
/**
 * Component: CustomerSummarySystemsTableComponent
 *
 * Description:
 * The `CustomerSummarySystemsTableComponent` is a React component that renders a table displaying a list of systems
 * with various functionalities like sorting, pagination, and actions (edit, delete, etc.). It's used in the context
 * of customer summaries to provide an interactive and detailed view of systems.
 *
 * Props:
 * - Includes various props like `stateParams`, `state`, `UserService`, guides (`PROGRAMMING_GUIDE`, `INSTALL_GUIDE`),
 *   action handlers (`moveSystem`, `serviceRequestModal`, `tempUserModal`, `authorizeTempAppUser`, `openDeleteSystemModal`),
 *   and `deletedSystemId` for tracking deleted systems.
 *
 * Behavior:
 * - Uses the `usePaginationState` hook for managing sorting and pagination state.
 * - Retrieves system data using the `useLazyLoadQuery` from React Relay, fetching data based on customer ID.
 * - Provides sorting functionality for the table based on different system attributes (account, system name, type, etc.).
 * - Handles pagination of the fetched data to display it in a user-friendly manner.
 * - Utilizes `SystemEditDropdown` for each system to provide additional actions based on user permissions.
 * - Adjusts the overflow property of the table for better UI experience when interacting with dropdown menus.
 * - Renders table headers, body, and footer, incorporating sorting, pagination, and actions.
 *
 * Styling:
 * - The component uses a combination of custom styles and styled-components for visual presentation.
 * - It applies styles conditionally based on the state (like open/closed states for dropdowns).
 *
 * Usage:
 * This component is intended for use in customer summary interfaces where a detailed view of systems, along with
 * various interactions like sorting, pagination, and editing, is required. It is designed to handle a potentially
 * large number of systems in an efficient and user-friendly manner.
 */

import graphql from "babel-plugin-relay/macro";
import {
  Input,
  Panel,
  Table,
  TableFooter,
  TableHeader,
} from "components/DaStyledElements";
import * as Paginate from "components/DaStyledElements/Paginator";
import usePaginationState, {
  ActionType,
  SortOrder,
} from "components/DaStyledElements/Paginator/usePaginationState";
import * as R from "ramda";
import * as React from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import {
  asID,
  fromControlSystemId,
  PanelHardwareModel,
  toCustomerId,
} from "securecom-graphql/client";
import CustomerSummaryTableHeader from "./CustomerSummaryTableHeader";
import SystemEditDropdown from "./SystemEditDropdown";
import SystemFirmwareComponent from "./SystemFirmwareComponent";
import {
  getCommType,
  getSystemTypePrettyPrint,
  navToSystemUrl,
  sortSystemsByAccount,
} from "./utils";
import { CustomerSummarySystemTableComponentQuery } from "./__generated__/CustomerSummarySystemTableComponentQuery.graphql";

export enum SortKey {
  Account,
  SystemName,
  SystemType,
  Version,
  Connection,
}

interface CustomerSummarySystemsTableComponentProps {
  stateParams: any;
  state: any;
  UserService: any;
  TEMP_DEALER_APP_USER: any;
  PROGRAMMING_GUIDE: any;
  INSTALL_GUIDE: any;
  moveSystem: any;
  serviceRequestModal: any;
  tempUserModal: any;
  authorizeTempAppUser: any;
  openDeleteSystemModal: any;
  deletedSystemId: string;
  movedSystemInfo: MovedSystemInfo;
}

export interface MovedSystemInfo {
  systemId: string;
  customerId: string;
}

export interface System {
  id: string;
  name: string;
  isX1: boolean;
  commType: string;
  panel: {
    accountNumber: string;
    accountPrefix: string;
    rawArmingSystem: string;
    hardwareModel: string;
    hardwareModelName: string;
    online: boolean;
    softwareDate: string;
    softwareVersion: string;
  };
  site: {
    id: string;
  };
}

const CustomerSummarySystemsTableComponent = (
  props: CustomerSummarySystemsTableComponentProps
) => {
  const {
    stateParams,
    state,
    UserService,
    TEMP_DEALER_APP_USER,
    PROGRAMMING_GUIDE,
    INSTALL_GUIDE,
    moveSystem,
    serviceRequestModal,
    tempUserModal,
    authorizeTempAppUser,
    openDeleteSystemModal,
    deletedSystemId,
    movedSystemInfo,
  } = props;
  //state values
  const [paginationState, dispatch] = usePaginationState({
    sortOrder: SortOrder.Ascending,
    sortKey: SortKey.Account,
  });
  const [pagedSystems, setPagedSystems] = React.useState<System[]>([]);

  const userCanCreateTempDealerCustomerUser =
    UserService.canCreateTempDealerCustomerUser() ||
    UserService.isTechSupport();

  const customerId = React.useMemo(
    () => String(toCustomerId(stateParams.customer_id)),
    [stateParams.customer_id]
  );

  const summaryData =
    useLazyLoadQuery<CustomerSummarySystemTableComponentQuery>(
      graphql`
        query CustomerSummarySystemTableComponentQuery(
          $customerId: ID!
          $first: Int
          $after: ID
          $search: SearchInput
          $sort: SortInput
          $ids: [ID!]
        ) {
          customer: node(id: $customerId) {
            ... on Customer {
              controlSystems(
                first: $first
                after: $after
                search: $search
                sort: $sort
                ids: $ids
              ) {
                edges {
                  node {
                    site {
                      id
                    }
                    id
                    name
                    commType
                    isX1
                    panel {
                      accountPrefix
                      accountNumber
                      rawArmingSystem
                      hardwareModel
                      hardwareModelName
                      online
                      softwareVersion
                      softwareDate
                    }
                  }
                }
              }
            }
          }
        }
      `,
      {
        customerId: String(toCustomerId(stateParams.customer_id)),
      },
      {
        fetchPolicy: "network-only",
        fetchKey:
          `${deletedSystemId ?? 0}-${movedSystemInfo?.systemId ?? 0}-${
            movedSystemInfo?.customerId ?? 0
          }` ?? stateParams.customer_id, // Fetch key to force refetch when a system is deleted or moved
      }
    );

  /**
   *
   * This function takes a system object and returns a value used as a key for sorting.
   * The sort key is determined by the current sorting criterion specified in
   * `paginationState.sortKey`. Different system attributes are used as sorting keys
   * based on this criterion.
   *
   * @param {System} system - The system object from which the sort key is generated.
   * @returns A value representing the sort key for the given system. This could be a
   *          combination of account prefix and number, system name, hardware model,
   *          software version, or communication type, depending on the current sorting
   *          criterion.
   */

  const systemSortKey = (system: System) => {
    switch (paginationState.sortKey) {
      case SortKey.Account:
        // Parse the account prefix and number for sorting
        const prefix = parseInt(system.panel.accountPrefix);
        const number = parseInt(system.panel.accountNumber);
        return `${prefix}-${number}`;
      case SortKey.SystemName:
        return system.name;
      case SortKey.SystemType:
        return system.panel.hardwareModel;
      case SortKey.Version:
        return parseFloat(system.panel.softwareVersion);
      case SortKey.Connection:
        return getCommType(system);
      default:
        return `${system.panel.accountPrefix}-${system.panel.accountNumber}`;
    }
  };

  const panelCount = summaryData?.customer?.controlSystems?.edges.length ?? 0;

  /**
   * searchedSystems
   *
   * This function computes and memoizes a filtered list of control systems based on the current search term.
   * It optimizes performance by recalculating the list only when `summaryData` or the search term in
   * `paginationState` changes.
   *
   */
  const searchedSystems = React.useMemo(() => {
    if (!summaryData?.customer?.controlSystems) return [];

    return summaryData.customer.controlSystems.edges
      .filter((edge) => {
        const system = edge.node;
        if (!system) return false;

        const searchTerm = paginationState.search.toLowerCase();
        const systemName = system.name.toLowerCase();
        const accountNumber = system.panel.accountNumber.toLowerCase();

        return (
          systemName.includes(searchTerm) || accountNumber.includes(searchTerm)
        );
      })
      .map((edge) => edge.node as System);
  }, [summaryData, paginationState.search]);

  /**
   * sortedSystems
   *
   * This function is used to compute a sorted list of systems based on the current sorting criteria
   * defined in `paginationState`. The sorting is applied to the already filtered systems
   * (obtained from `searchedSystems`). This hook ensures that sorting logic is applied efficiently
   * and only recalculated when necessary.
   */

  const sortedSystems = React.useMemo(() => {
    let sorted = [...searchedSystems];
    // Clone the array to avoid mutating the original

    if (paginationState.sortKey === SortKey.Account) {
      // Custom sorting for account numbers
      sorted.sort(sortSystemsByAccount);
      if (paginationState.sortOrder === SortOrder.Descending) {
        sorted.reverse();
      }
    } else {
      // For other sorting criteria, use Ramda's sort functionality
      const comparator =
        paginationState.sortOrder === SortOrder.Ascending
          ? R.ascend(systemSortKey)
          : R.descend(systemSortKey);

      sorted = R.sort(comparator, sorted);
    }
    return sorted;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationState.sortKey, paginationState.sortOrder, searchedSystems]);

  const maxPage = Math.ceil(sortedSystems.length / paginationState.pageSize);

  const startIndex =
    (paginationState.currentPage - 1) * paginationState.pageSize + 1;
  const endIndex = Math.min(
    startIndex + paginationState.pageSize - 1,
    sortedSystems.length
  );

  /**
   * -----------------
   * LifeCycle Methods
   * -----------------
   */

  /**
   * This useEffect is responsible for paginating the sorted list of systems based on the current page
   * and page size specified in `paginationState`. It updates `pagedSystems` state with the
   * appropriately sliced segment of the `sortedSystems` array.
   */
  React.useEffect(() => {
    const newPagedSystems =
      R.splitEvery(paginationState.pageSize, sortedSystems)[
        paginationState.currentPage - 1
      ] ?? [];
    setPagedSystems(newPagedSystems);
  }, [sortedSystems, paginationState.pageSize, paginationState.currentPage]);

  /**
   * This useEffect is used to manually adjust the CSS overflow property of elements with the
   * class 'table-responsive'. It sets the overflow to 'visible' on component mount and resets
   * it when the component unmounts. This is primarily a workaround for when a user clicks the edit dropdown
   * on a row at the bottom of the table.
   *
   */
  React.useEffect(() => {
    const tableResponsiveElements =
      document.getElementsByClassName("table-responsive");
    //This is a manual fix for the overflow on the tableResponsiveElement
    if (tableResponsiveElements.length > 0) {
      // Element exists, update the style
      const tableResponsive = tableResponsiveElements[0] as HTMLElement;
      tableResponsive.style.overflow = "visible";
    }

    // Reset style when component unmounts
    return () => {
      if (tableResponsiveElements.length > 0) {
        const tableResponsive = tableResponsiveElements[0] as HTMLElement;
        tableResponsive.style.overflow = "";
      }
    };
  }, []);

  return (
    <Panel.Table>
      <TableHeader>
        <TableHeader.Left>
          {panelCount > 10 ? (
            <>
              <label className="sr-only" htmlFor="search-input"></label>
              <Input.TableSearch
                id="search-input"
                value={paginationState.search}
                onChange={(search) => {
                  dispatch({
                    type: ActionType.SetSearch,
                    search,
                  });
                }}
              />
            </>
          ) : null}
        </TableHeader.Left>
      </TableHeader>
      <Table className={"table-fixed--not-mobile"}>
        <CustomerSummaryTableHeader
          columns={[
            { columnName: "Account", sortKey: SortKey.Account },
            { columnName: "System Name", sortKey: SortKey.SystemName },
            { columnName: "System Type", sortKey: SortKey.SystemType },
            { columnName: "Version", sortKey: SortKey.Version },
            { columnName: "Connection", sortKey: SortKey.Connection },
          ]}
          paginationState={paginationState}
          dispatch={dispatch}
          userCanCreateTempDealerCustomerUser={
            userCanCreateTempDealerCustomerUser
          }
        />
        <tbody>
          {!R.isEmpty(pagedSystems) ? (
            pagedSystems.map((system, idx) => {
              const { systemId } = fromControlSystemId(asID(system.id));

              return systemId !== deletedSystemId.toString() ? ( // Filter out deleted system if present. This is a workaround to prevent the deleted system from rendering if it takes a while to be removed.
                <tr key={systemId}>
                  <td>
                    {system.panel.accountPrefix}-{system.panel.accountNumber}
                  </td>
                  <td
                    style={{
                      color: "#3d85a3",
                      textDecoration: "none",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <a
                      style={{}}
                      className="link link-primary table-emphasis"
                      onClick={(e) => {
                        e.preventDefault();
                        return navToSystemUrl(
                          system,
                          state,
                          UserService,
                          customerId,
                          stateParams
                        );
                      }}
                    >
                      {system.name}
                    </a>
                  </td>
                  <td>{getSystemTypePrettyPrint(system)}</td>
                  {system.panel.hardwareModel !==
                  PanelHardwareModel.VIDEO_ONLY ? (
                    <>
                      <td>
                        <SystemFirmwareComponent
                          softwareDate={system.panel.softwareDate}
                          softwareVersion={system.panel.softwareVersion}
                        />
                      </td>
                      <td>{getCommType(system)}</td>
                    </>
                  ) : (
                    <>
                      <td>-</td>
                      <td>-</td>
                    </>
                  )}
                  <td>
                    {userCanCreateTempDealerCustomerUser &&
                    (system.panel.rawArmingSystem ||
                      system.panel.hardwareModel ===
                        PanelHardwareModel.X001) ? (
                      <span
                        className="da-temp-app-user btn btn-add--no-icon  btn-go-vk pull-right hidden-xs hidden-sm"
                        onClick={() => tempUserModal(Number(systemId))}
                      >
                        Log In As Customer
                      </span>
                    ) : null}
                  </td>
                  <td>
                    <SystemEditDropdown
                      userService={UserService}
                      TEMP_DEALER_APP_USER={TEMP_DEALER_APP_USER}
                      system={system}
                      state={state}
                      navToSystemUrl={navToSystemUrl}
                      INSTALL_GUIDE={INSTALL_GUIDE}
                      PROGRAMMING_GUIDE={PROGRAMMING_GUIDE}
                      moveSystem={moveSystem}
                      serviceRequestModal={serviceRequestModal}
                      tempUserModal={tempUserModal}
                      authorizeTempAppUser={authorizeTempAppUser}
                      openDeleteSystemModal={openDeleteSystemModal}
                      customerId={customerId}
                      UserService={UserService}
                      stateParams={stateParams}
                    />
                  </td>
                </tr>
              ) : null;
            })
          ) : (
            <tr>
              <td>{panelCount ? "No Systems Found" : "No Systems"}</td>
            </tr>
          )}
        </tbody>
      </Table>
      <TableFooter>
        <TableFooter.Left>
          Showing {sortedSystems.length ? startIndex : 0} to {endIndex} of{" "}
          {sortedSystems.length}
        </TableFooter.Left>
        <TableFooter.Center>
          {maxPage > 1 ? (
            <Paginate.Paginator
              state={paginationState}
              dispatch={dispatch}
              maxPage={maxPage}
            />
          ) : null}
        </TableFooter.Center>
        <TableFooter.Right>
          {sortedSystems.length > 10 ? (
            <Paginate.ItemsPerPage
              state={paginationState}
              dispatch={dispatch}
            />
          ) : null}
        </TableFooter.Right>
      </TableFooter>
    </Panel.Table>
  );
};

export default CustomerSummarySystemsTableComponent;
