App.controller("CompetitorUpgradeAuditCtrl", [
  "$scope",
  "$q",
  "$filter",
  "$compile",
  "$rootScope",
  "MIN_VIEW_SEARCH",
  "UserService",
  "CompetitorUpgradeService",
  "DTOptionsBuilder",
  "DTColumnBuilder",
  "PanelDataService",
  "DataTablesUtilsService",
  "$modal",
  "PROPS",
  "$sanitize",
  function (
    $scope,
    $q,
    $filter,
    $compile,
    $rootScope,
    MIN_VIEW_SEARCH,
    UserService,
    CompetitorUpgradeService,
    DTOptionsBuilder,
    DTColumnBuilder,
    PanelDataService,
    DataTablesUtilsService,
    $modal,
    PROPS,
    $sanitize
  ) {
    $scope.UserService = UserService;
    $scope.dtOptions = DataTablesUtilsService.getDTOptions(
      getList(),
      "Competitor Upgrade Audit"
    );
    $scope.dtColumns = DataTablesUtilsService.dtColumns;

    $scope.dtOptions
      .withDOM(
        '<"data-table__top  pad-24 pad-t-32" <"table-search"search"rf> <"column-selector" B>><t><"data-table__bottom pad-24 mar-b-40"<"data-table__bottom--left" i><"data-table__bottom--center"p><"data-table__bottom--right"l>>'
      ) //<Tlpi>
      .withOption("order", [0, "asc"])
      .withOption("createdRow", function (row) {
        $compile(angular.element(row).contents())($scope);
      })
      .withOption("targets", "_all")
      .withOption("render", $.fn.dataTable.render.text());

    //holds getList data
    $scope.upgradeSystems = [];

    function getList(refresh) {
      refresh = typeof refresh == "undefined" ? false : refresh;
      var deferred = $q.defer();
      var _this = this;
      CompetitorUpgradeService.getAudit()
        .then(
          function (data) {
            $scope.CompetitorName = data.CompetitorName;
            $scope.upgradeSystems = data;
            deferred.resolve(data);
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "Error retrieving Competitor Upgrade list",
              json: error,
            });
          },
          function (info) {}
        )
        .catch(function (error) {});

      return deferred.promise;
    }

    /** Creates and opens Competitor Photo modal */
    $scope.openCompetitorAuditPhoto = function (data) {
      $scope.imgSrc = data;
      var competitorAuditPhotoModal = $modal.open({
        templateUrl: "app/competitor-upgrade-audit/competitor-photo-modal.html",
        controller: "CompetitorAuditPhotoModalInstanceCtrl",
        windowClass: "",
        size: "lg",
        backdrop: true,
        scope: $scope,
      });
      competitorAuditPhotoModal.result.then(
        function (reason) {
          $state.forceReload();
        },
        function () {}
      );
    };
    /** A controller for the CompetitorAuditPhotoModal *********************************************************************************************/
    App.controller(
      "CompetitorAuditPhotoModalInstanceCtrl",
      function ($scope, $modalInstance, $state, UserService) {
        $scope.cancel = function () {
          $modalInstance.dismiss("cancel");
        };
        /** Function Continue() Fires firmware update and dismisses panel ***********************************************/
        $scope.cancelCompetitorAuditPhotoModal = function () {
          $modalInstance.dismiss("cancel");
        };
      }
    );

    getImageUrl = function (controlSysId, isThumb) {
      var url =
        PROPS.imageApiUrl +
        "/Uploads/CompetitorUpgrades/" +
        controlSysId +
        "/upgrade-image/";

      //thumb check
      url += !isThumb ? "upgrade-image.jpg" : "upgrade-image_100w_100h_max.jpg";
      return url;
    };

    //If the photo does not exist on the sever, display N/A
    onPhotoError = function (element) {
      //add text to the cell outside of the href and image
      angular
        .element(element)
        .parent()
        .parent()
        .parent()
        .html(
          '<p class="pad-v-16"><span style="position:relative; top: .7rem">Not Available</span></p>'
        );
      //remove table-pic-launcher
      angular.element(element).parent().parent().remove();
      //remove href
      angular.element(element).parent().remove();
      //remove image
      angular.element(element).remove();
    };

    getPhotoRender = function (controlSysId) {
      return (
        "<div class='table-pic-laucher' style='text-align: center'><a  ng-click=openCompetitorAuditPhoto('" +
        getImageUrl(controlSysId) +
        "'); target='_blank'>" +
        "<img class='table-pic-laucher__image' onerror='onPhotoError(this)' src='" +
        getImageUrl(controlSysId, true) +
        "'/></a></div>"
      );
    };

    addHtmlTitle = function (item) {
      let title = "";
      let text = "";

      //check if this item is complex
      if (typeof item === "object") {
        title = item.title;
        text = item.text;
      } else {
        //the item is a plain string, use that instead
        title = item;
        text = item;
      }

      if (title) return `<span title="${title}">${text}</span>`;
      else return "";
    };

    /**
     *  @param data ControlSystemId
     *  Returns object containing system name wrapped in a link to the system if it exists
     *  in dealer admin and plain text of system name
     */
    getSystemName = function (data) {
      let system = { title: "Not Available", text: "Not Available" };

      //get the system for this row
      let upgradeSystem = $scope.upgradeSystems.find(
        (x) => x.ControlSystemId == data
      );
      //if there is a system name, add a link to the system page
      if (upgradeSystem && upgradeSystem.SystemName) {
        system.text = `<a ui-sref='app.control_system({customer_id: ${
          upgradeSystem.CustomerId
        }, control_system_id:${upgradeSystem.ControlSystemId} })'>${$sanitize(
          upgradeSystem.SystemName
        )}</a>`;
        system.title = upgradeSystem.SystemName;
      }

      return system;
    };

    //List of columns, column properties, and custom methods used to display the data in the Data Table.
    $scope.dtColumns = [
      DTColumnBuilder.newColumn("DealerName")
        .withTitle("Dealer Name")
        .renderWith((data) => {
          return addHtmlTitle($sanitize(data));
        }),
      DTColumnBuilder.newColumn("ControlSystemId")
        .withTitle("System Name")
        .renderWith((data) => {
          return addHtmlTitle(getSystemName(data));
        }),
      DTColumnBuilder.newColumn("CompetitorCellId")
        .withTitle("Competitor Cell Id")
        .renderWith((data) => {
          return addHtmlTitle($sanitize(data));
        }),
      DTColumnBuilder.newColumn("CompetitorName")
        .withTitle("Competitor Brand")
        .renderWith((data) => {
          return addHtmlTitle($sanitize($filter("titleize")(data)));
        }),
      DTColumnBuilder.newColumn("ControlSystemId")
        .withTitle("Photo")
        .withOption("sTextAlign", "center")
        .renderWith((data) => {
          return getPhotoRender($sanitize(data));
        }),
    ];
  },
]);
