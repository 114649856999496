App.factory("CredentialsApi", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.apiUrl + "/v2/credentials/:locator",
      {},
      {
        // locator is a comma-separated list of numbers
        show_all: { method: "GET", isArray: true },
        update: { method: "PATCH" },
        destroy: { method: "DELETE" },
      }
    );
  },
]);

/**
 * Credentials attached to a control system.
 */
App.factory("ControlSystemsCredentialsApi", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.apiUrl +
        "/v2/control_systems/:controlSystemId/credentials/:white_rabbit",
      {},
      {
        create: { method: "POST" },
        index: {
          method: "GET",
          params: { white_rabbit: "owned" },
          isArray: true,
        },
      }
    );
  },
]);

/**
 * Credentials attached to a person
 */
App.factory("PersonsCredentialsApi", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.apiUrl + "/v2/persons/:personId/credentials",
      {},
      {
        create: { method: "POST" },
        index: { method: "GET", isArray: true },
      }
    );
  },
]);
