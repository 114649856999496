import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { fetchQuery, RecordProxy } from "relay-runtime";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import {
  ControlSystem,
  PanelHardwareModel,
  SystemType,
  toControlSystemId,
} from "securecom-graphql/client";
import { Concept } from "../../FullProgramming/common/FullProgrammingForm";
import FullProgrammingVersionEntryPoint from "../../FullProgramming/common/FullProgrammingVersionEntryPoint";
import {
  createConcepts,
  xt75FullProgrammingControlSystem,
  xt75FullProgrammingInlineControlSystem,
} from "../../FullProgramming/XT75FullProgramming";
import { XT75TemplateContainerTemplateQuery } from "./__generated__/XT75TemplateContainerTemplateQuery.graphql";

export const XT75TemplateContainer: React.FC<{
  dealerId: string;
  hardwareModel: PanelHardwareModel;
  softwareVersion: string;
}> = ({ dealerId, hardwareModel, softwareVersion }) => {
  const concepts = React.useMemo(
    () => createConcepts({ supportsCustomCardFormats: true }) as Concept[],
    []
  );

  return (
    <FullProgrammingVersionEntryPoint
      systemName={`Default ${hardwareModel}`}
      concepts={concepts}
      gqlQuery={graphql`
        query XT75TemplateContainerQuery(
          $dealerId: ID!
          $hardwareModel: PanelHardwareModel!
          $softwareVersion: String!
        ) {
          dealer: node(id: $dealerId) {
            ... on Dealer {
              id
              ...FullProgrammingVersionEntryPoint_dealer
            }
          }
          controlSystem: defaultControlSystem(
            hardwareModel: $hardwareModel
            softwareVersion: $softwareVersion
            dealerId: $dealerId
          ) {
            id
            name
            panel {
              softwareVersion
              hardwareModel
              systemOptions {
                ... on Xt75SystemOptions {
                  systemType
                }
              }
            }
            customer {
              id
            }

            ...NetworkOptionsDhcpEnabledField_controlSystem
            ...XT75FullProgramming_controlSystem
            ...XT75AreaInformationProgrammingConceptFormOriginalAreas_controlSystem
            ...XT75SystemOptionsProgrammingConceptFormInline_controlSystem
            ...NetworkOptionsDhcpEnabledField_controlSystem
            ...XT75OutputInformationProgrammingConceptFormInline_controlSystem
            ...XT75AreaInformationProgrammingConceptFormInline_controlSystem
            ...XT75DeviceSetupProgrammingConceptFormInline_controlSystem
            ...XT75CardFormatsProgrammingConceptFormInline_controlSystem
            ...XT75ZoneInformationsProgrammingConceptFormInline_controlSystem
          }
          controlSystemInline: defaultControlSystem(
            hardwareModel: $hardwareModel
            softwareVersion: $softwareVersion
            dealerId: $dealerId
          ) {
            id
            ...XT75FullProgrammingInline_controlSystem
          }
        }
      `}
      gqlQueryVariables={{
        hardwareModel,
        softwareVersion,
        dealerId,
      }}
      gqlFormControlSystemFragment={xt75FullProgrammingControlSystem}
      gqlFormControlSystemInlineFragment={
        xt75FullProgrammingInlineControlSystem
      }
      applyTemplate={applyTemplate(toControlSystemId(-1).toString(), concepts)}
    />
  );
};

const fetchTemplateData = (environment: RelayModernEnvironment, id: string) =>
  fetchQuery<XT75TemplateContainerTemplateQuery>(
    environment,
    graphql`
      query XT75TemplateContainerTemplateQuery($templateId: ID!) {
        programmingTemplate: node(id: $templateId) {
          id
          ... on ProgrammingTemplate {
            concepts {
              ... on Xt75ProgrammingTemplateConcepts {
                systemOptions {
                  systemType {
                    included
                  }
                }
                ...XT75CommunicationProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts
                ...XT75NetworkOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts
                ...XT75DeviceSetupProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts
                ...XT75CardFormatsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts
                ...XT75RemoteOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts
                ...XT75SystemReportsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts
                ...XT75SystemOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts
                ...XT75BellOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts
                ...XT75OutputInformationProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts
                ...XT75OutputOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts
                ...XT75AreaInformationProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts
                ...XT75ZoneInformationsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts
                ...XT75LockoutCodeProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts
              }
            }
          }
        }
      }
    `,
    { templateId: id },
    { fetchPolicy: "network-only" }
  )
    .toPromise()
    .then((response) => response?.programmingTemplate);

const applyTemplate =
  (systemId: string, concepts: Concept[]) =>
  async (environment: RelayModernEnvironment, templateId: string) => {
    const data = await fetchTemplateData(environment, templateId);
    if (data?.concepts) {
      environment.commitUpdate((store) => {
        const controlSystem =
          store.get<ControlSystem>(systemId) ??
          (store.create(
            systemId,
            "ControlSystem"
          ) as RecordProxy<ControlSystem>);
        //When applying a template to the default system for editing it is important that the system type is set to area mode unless it is included
        // in the template specifically.  The system type is used to determine which areas are shown in the programming form
        // if the system type is not set to area mode then all of the areas in the template will not be shown
        const panelRecord = controlSystem.getLinkedRecord("panel");
        const systemOptionsRecord =
          panelRecord.getLinkedRecord("systemOptions");
        if (!data?.concepts?.systemOptions?.systemType?.included) {
          systemOptionsRecord.setValue(SystemType.AREA, "systemType");
        }
        concepts.forEach((concept) => {
          if (concept.applyTemplateData) {
            concept.applyTemplateData(data.concepts, controlSystem, store);
          }
        });
      });
    }
  };
