/**
 * @ngdoc service
 * @name App.factory:CompetitorUpgradeService
 *
 * @description
 * Competitor Upgrade Program Service
 *
 */
App.factory("CompetitorUpgradeService", [
  "CompetitorUpgradeAPI",
  "CompetitorUpgradeAuditAPI",
  "$q",
  "$filter",
  "UserService",
  "$http",
  "PROPS",
  function (
    CompetitorUpgradeAPI,
    CompetitorUpgradeAuditAPI,
    $q,
    $filter,
    UserService,
    $http,
    PROPS
  ) {
    return {
      getAudit: function () {
        var deferred = $q.defer();
        var _this = this;
        CompetitorUpgradeAuditAPI.getCompUpgrades(
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
      getBase64Img: function (file) {
        let url = URL.createObjectURL(file);

        let deferred = $q.defer();

        const RESIZE_HEIGHT = 120;
        const RESIZE_WIDTH = 160;
        // $scope.string = "HELLO WORLD"
        // $scope.logins = [];
        // $scope.size = 0
        function convertImgToDataURLviaCanvas(url, callback) {
          var img = new Image();
          img.crossOrigin = "Anonymous";
          img.onload = function () {
            var canvas = document.createElement("CANVAS");
            var ctx = canvas.getContext("2d");
            var dataURL;
            // var width = img.naturalWidth;
            // var height = img.naturalHeight;
            // $scope.size = width * height;
            canvas.height = this.height;
            canvas.width = this.width;
            ctx.drawImage(this, 0, 0);
            dataURL = canvas.toDataURL("image/jpeg", 0.2);
            callback(dataURL);
            canvas = null;
          };
          img.src = url;
        }

        convertImgToDataURLviaCanvas(url, function (base64Img) {
          deferred.resolve(base64Img);
        });

        return deferred.promise;
      },
      sendRebateInfo: function (dealerId, controlSystemId, body) {
        var deferred = $q.defer();
        var _this = this;
        CompetitorUpgradeAPI.sendRebateInfo(
          {
            dealer_id: dealerId,
            control_system_id: controlSystemId,
          },
          body,
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
      getCompUpgradeInfo: function (dealerId, controlSystemId) {
        var deferred = $q.defer();
        var _this = this;
        CompetitorUpgradeAPI.getCompUpgrade(
          {
            dealer_id: dealerId,
            control_system_id: controlSystemId,
          },
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
      changeRebateInfo: function (dealerId, controlSystemId, body) {
        var deferred = $q.defer();
        var _this = this;
        CompetitorUpgradeAPI.changeRebateInfo(
          {
            dealer_id: dealerId,
            control_system_id: controlSystemId,
          },
          body,
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
      /**
       * Upload a user image
       */
      uploadImage: function (file, controlSystemId) {
        var deferred = $q.defer();
        var _this = this;

        var fd = new FormData();

        fd.append("Competitor", file);
        $http
          .post(
            PROPS.imageApiUrl +
              "/api/v1/CompetitorUpgrades/Dealer/" +
              UserService.dealer_id +
              "/ControlSystem/" +
              controlSystemId,
            fd,
            {
              transformRequest: angular.identity,
              headers: { "Content-Type": undefined },
            }
          )
          .then(
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });

        return deferred.promise;
      },

      taUploadImage: function (photoLocation, controlSystemId) {
        var deferred = $q.defer();
        window.resolveLocalFileSystemURL(
          photoLocation,
          function (fileEntry) {
            fileEntry.file(function (file) {
              let server = encodeURI(
                PROPS.imageApiUrl +
                  "/api/v1/CompetitorUpgrades/Dealer/" +
                  UserService.dealer_id +
                  "/ControlSystem/" +
                  controlSystemId
              );
              function successCallback(data) {
                deferred.resolve(PROPS.imageApiUrl + data.response);
              }

              function failCallback(error) {
                console.error(
                  "ControlSystemCtrl-i->CreateSystemImage(): error creating system images: " +
                    angular.toJson(error)
                );
                deferred.reject(error);
              }

              var options = new FileUploadOptions();
              options.fileName = file.name;
              options.httpMethod = "POST";
              options.mimeType = file.type;
              options.headers = {
                "Content-Type": undefined,
                Authorization: "Bearer " + UserService.authToken,
              };

              var ft = new FileTransfer();
              ft.upload(
                file.localURL,
                server,
                successCallback,
                failCallback,
                options
              );
            });
          },
          function (error) {
            console.error("No dice: " + angular.toJson(error));
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
    };
  },
]);
