import graphql from "babel-plugin-relay/macro";
import { IncludedTemplateFieldsType } from "components/FullProgramming/common/IncludedTemplateFieldsContext";
import {
  EditingFrom,
  useTemplateContext,
} from "components/FullProgramming/common/TemplateContext";
import { useStateDotGo } from "components/SystemDiagnostics/AngularStateProvider";
import { useShowAlert } from "contexts/AlertsContext";
import { useFragment, useMutation } from "react-relay";
import {
  asID,
  CommunicationComType,
  fromDealerId,
} from "securecom-graphql/client";
import { getInput as getAreaInformationInput } from "../Data/XT75AreaInformationTemplateData";
import { getInput as getBellOptionsInput } from "../Data/XT75BellOptionsTemplateData";
import { getInput as getCardFormatsInput } from "../Data/XT75CardFormatsTemplateData";
import { getInput as getCommunicationPathInput } from "../Data/XT75CommunicationPathTemplateData";
import { getInput as getDeviceInformationsInput } from "../Data/XT75DeviceInformationTemplateData";
import { getInput as getLockoutCodeInput } from "../Data/XT75LockoutCodeTemplateData";
import { getInput as getNetworkOptionsInput } from "../Data/XT75NetworkOptionsTemplateData";
import { getInput as getOutputInformationsInput } from "../Data/XT75OutputInformationTemplateData";
import { getInput as getOutputOptionsInput } from "../Data/XT75OutputOptionsTemplateData";
import { getInput as getRemoteOptionsInput } from "../Data/XT75RemoteOptionsTemplateData";
import { getInput as getSystemAreaInformationInput } from "../Data/XT75SystemAreaInformationTemplateData";
import { getInput as getSystemOptionsInput } from "../Data/XT75SystemOptionsTemplateData";
import { getInput as getSystemReportsInput } from "../Data/XT75SystemReportsTemplateData";
import { getInput as getZoneInformationInput } from "../Data/XT75ZoneInformationTemplateData";
import { xt75TemplateSaveMutation } from "./XT75TemplateSaveMutation";
import { XT75TemplateSaveHook_controlSystem$key } from "./__generated__/XT75TemplateSaveHook_controlSystem.graphql";
import { XT75TemplateSaveMutation } from "./__generated__/XT75TemplateSaveMutation.graphql";

export const useXT75TemplateSaveMutation = (
  controlSystem: XT75TemplateSaveHook_controlSystem$key
): [
  (
    includedFields: IncludedTemplateFieldsType,
    templateName: string,
    customerId: string,
    dealerId: string
  ) => void,
  boolean
] => {
  const [saveXt75Template, isSavingXT75Template] =
    useMutation<XT75TemplateSaveMutation>(xt75TemplateSaveMutation);

  const data = useFragment(
    graphql`
      fragment XT75TemplateSaveHook_controlSystem on ControlSystem {
        panel {
          hardwareModel
        }
        ...XT75CommunicationProgrammingConceptFormInline_controlSystem
        ...XT75NetworkOptionsProgrammingConceptFormInline_controlSystem
        ...XT75DeviceSetupProgrammingConceptFormInline_controlSystem
        ...XT75CardFormatsProgrammingConceptFormInline_controlSystem
        ...XT75RemoteOptionsProgrammingConceptFormInline_controlSystem
        ...XT75SystemReportsProgrammingConceptFormInline_controlSystem
        ...XT75SystemOptionsProgrammingConceptFormInline_controlSystem
        ...XT75BellOptionsProgrammingConceptFormInline_controlSystem
        ...XT75OutputInformationProgrammingConceptFormInline_controlSystem
        ...XT75OutputOptionsProgrammingConceptFormInline_controlSystem
        ...XT75AreaInformationProgrammingConceptFormInline_controlSystem
        ...XT75ZoneInformationsProgrammingConceptFormInline_controlSystem
        ...XT75LockoutCodeProgrammingConceptFormInline_controlSystem
      }
    `,
    controlSystem
  );

  const templateData = useTemplateContext();
  const showAlert = useShowAlert();
  const nav = useStateDotGo();

  return [
    (
      includedFields: IncludedTemplateFieldsType,
      templateName: string,
      customerId: string,
      dealerId: string
    ) => {
      saveXt75Template({
        variables: {
          dealerId: dealerId,
          templateData: {
            id: templateData.templateId ? templateData.templateId : null,
            name: templateName,
            hardwareModel: templateData.hardwareModel,
            dealerId: dealerId,
            receiverCommType: CommunicationComType.NONE,
            customerId: customerId,
            concepts: {
              areaInformations: getAreaInformationInput(data, includedFields),
              bellOptions: getBellOptionsInput(data, includedFields),
              cardFormats: getCardFormatsInput(data, includedFields),
              communication: getCommunicationPathInput(data, includedFields),
              deviceInformations: getDeviceInformationsInput(
                data,
                includedFields
              ),
              lockoutCode: getLockoutCodeInput(data, includedFields),
              networkOptions: getNetworkOptionsInput(data, includedFields),
              outputInformations: getOutputInformationsInput(
                data,
                includedFields
              ),
              outputOptions: getOutputOptionsInput(data, includedFields),
              remoteOptions: getRemoteOptionsInput(data, includedFields),
              systemAreaInformation: getSystemAreaInformationInput(
                data,
                includedFields
              ),
              systemOptions: getSystemOptionsInput(data, includedFields),
              systemReports: getSystemReportsInput(data, includedFields),
              zoneInformations: getZoneInformationInput(data, includedFields),
            },
          },
        },
        onCompleted: (response) => {
          if (
            response.saveXt75ProgrammingTemplate?.__typename ===
            "SaveProgrammingTemplateSuccessResult"
          ) {
            showAlert({
              type: "success",
              text: "Successfully saved template.",
            });
            if (templateData.editingFrom === EditingFrom.NEW_TEMPLATE_PAGE) {
              nav(
                "app.dealer.default_programming",
                {
                  dealer_id: fromDealerId(asID(dealerId)).dealerId,
                },
                { reload: true }
              );
            } else if (
              templateData.editingFrom === EditingFrom.PROGRAMMING_PAGE
            ) {
              templateData.toggleIsEditing();
              nav("app.panel_programming.programming", {}, { reload: true });
            }
          } else {
            if (
              response.saveXt75ProgrammingTemplate?.__typename ===
              "SaveProgrammingTemplateErrorResult"
            ) {
              showAlert({
                type: "error",
                text: `Error saving template`,
              });
            }
          }
        },
        onError: () => {
          showAlert({
            type: "error",
            text: `Error saving template`,
          });
        },
      });
    },
    isSavingXT75Template,
  ];
};
