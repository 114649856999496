import graphql from "babel-plugin-relay/macro";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { setDelete } from "common/utils/universal/set";
import Button from "components/Button";
import { Flex, Form, Panel } from "components/DaStyledElements";
import { NaturalNumberInput } from "components/SiteForm/FormFields";
import * as R from "ramda";
import * as React from "react";
import { useFragment, useMutation } from "react-relay/hooks";
import Select from "react-select";
import { SelectComponents } from "react-select/src/components";
import { FixedSizeList as List } from "react-window";
import styled from "styled-components";
import { IssueCredentialsFragment_dealer$key } from "./__generated__/IssueCredentialsFragment_dealer.graphql";
import { IssueCredentialsMutation } from "./__generated__/IssueCredentialsMutation.graphql";

const NO_CUSTOMER_VALUE = "0";
const TotalPriceArea = styled.div`
  display: inline;
  font-size: 1.6rem;
  font-weight: 600;
  margin-right: 0.8rem;
`;
enum ErrorTypes {
  AmountCantBeZero,
  NoCustomerSelected,
  FailedToSave,
}

const PanelFixedHeight = styled(Panel.Default)`
  height: 48rem;
`;
const customSelectStyles = {
  control: (base: any) => ({
    ...base,
    maxHeight: 34,
    minHeight: 34,
    border: "1px solid #dde6e9",
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    padding: 5,
  }),
  clearIndicator: (base: any) => ({
    ...base,
    padding: 5,
  }),

  valueContainer: (base: any) => ({
    ...base,
    padding: "0px 6px",
  }),
  input: (base: any) => ({
    ...base,
    margin: 0,
    padding: 0,
  }),
};

type State = {
  selectedCustomerId: string;
  amount: number | null;
  notes: string;
  submitted: boolean;
  errors: Set<ErrorTypes>;
};

enum ActionTypes {
  CustomerSelected,
  AmountUpdated,
  AmountBlurred,
  NotesUpdated,
  Submit,
  Saved,
  SaveFailed,
}

type Action =
  | { type: ActionTypes.CustomerSelected; customerId: string }
  | { type: ActionTypes.AmountUpdated; value: string }
  | { type: ActionTypes.AmountBlurred }
  | { type: ActionTypes.NotesUpdated; notes: string }
  | { type: ActionTypes.Submit }
  | { type: ActionTypes.Saved }
  | { type: ActionTypes.SaveFailed };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionTypes.CustomerSelected:
      return {
        ...state,
        selectedCustomerId: action.customerId,
        errors:
          action.customerId === NO_CUSTOMER_VALUE
            ? state.errors
            : (setDelete(ErrorTypes.NoCustomerSelected)(
                state.errors
              ) as State["errors"]),
      };
    case ActionTypes.AmountUpdated: {
      if (!action.value) {
        return { ...state, amount: null };
      } else {
        const amount = Number(action.value.slice(0, 5));
        return {
          ...state,
          amount: !isNaN(amount) ? amount : state.amount,
        };
      }
    }
    case ActionTypes.AmountBlurred: {
      return {
        ...state,
        amount: R.clamp(0, 1000, Number(state.amount)),
      };
    }
    case ActionTypes.NotesUpdated:
      return {
        ...state,
        notes: action.notes,
      };
    case ActionTypes.Submit: {
      const errors = new Set(
        [
          state.amount && state.amount <= 0
            ? ErrorTypes.AmountCantBeZero
            : null,
          state.selectedCustomerId === NO_CUSTOMER_VALUE
            ? ErrorTypes.NoCustomerSelected
            : null,
        ].filter(isNotNullOrUndefined)
      );

      return {
        ...state,
        submitted: errors.size === 0,
        errors,
      };
    }
    case ActionTypes.Saved:
      return {
        ...state,
        selectedCustomerId: NO_CUSTOMER_VALUE,
        amount: 0,
        notes: "",
        submitted: false,
        errors: new Set(),
      };
    case ActionTypes.SaveFailed:
      return {
        ...state,
        submitted: false,
        errors: new Set([ErrorTypes.FailedToSave]),
      };
    default:
      return state;
  }
};

const mutation = graphql`
  mutation IssueCredentialsMutation(
    $customerId: ID!
    $info: IssueMobileCredentialsToCustomerInput!
  ) {
    issueMobileCredentialsToCustomer(customerId: $customerId, info: $info) {
      __typename
      ... on Error {
        type
      }
      ... on IssueMobileCredentialSuccessResponse {
        customer {
          id
          name
          availableMobileCredentials
          dealer {
            ...HistoryTableFragment
            ...AvailableCredentialsTableFragment_dealer
          }
        }
      }
    }
  }
`;

const fragment = graphql`
  fragment IssueCredentialsFragment_dealer on Dealer {
    id
    mobileCredentialCost
    customersConnection {
      nodes {
        id
        name
        availableMobileCredentials
        unikeyEnabled
      }
    }
  }
`;
/* List for React Window - used to improve search speed in react-select */
const listHeight = 35;
const listWidth = "100%";
const CustomerMenuList: SelectComponents<any, false>["MenuList"] = (props) => {
  const { options, children, maxHeight, getValue } = props;
  const [value] = getValue();
  const initialOffset = options.indexOf(value) * listHeight;
  const items = React.Children.toArray(children);
  return (
    <List
      width={listWidth}
      height={maxHeight}
      itemCount={items.length}
      itemSize={listHeight}
      initialScrollOffset={initialOffset}
    >
      {({ index, style }) => <div style={style}>{items[index]}</div>}
    </List>
  );
};
const IssueCredentials = ({
  dealer: dealerRef,
  createNotification,
}: {
  dealer: IssueCredentialsFragment_dealer$key;
  createNotification: (notification: {
    type: "success" | "warning" | "error";
    text: string;
  }) => void;
}) => {
  const data = useFragment(fragment, dealerRef);
  const [issueCredentials, isIssuingCredentials] =
    useMutation<IssueCredentialsMutation>(mutation);

  const [state, dispatch] = React.useReducer(reducer, {
    selectedCustomerId: NO_CUSTOMER_VALUE,
    amount: 0,
    notes: "",
    errors: new Set<ErrorTypes>(),
    submitted: false,
  });

  React.useEffect(() => {
    if (state.submitted) {
      issueCredentials({
        variables: {
          customerId: state.selectedCustomerId,
          info: {
            amount: state.amount ?? 0,
            notes: state.notes,
          },
        },
        onCompleted: (result) => {
          if (result.issueMobileCredentialsToCustomer.type) {
            dispatch({ type: ActionTypes.SaveFailed });
          } else {
            dispatch({ type: ActionTypes.Saved });

            createNotification({
              type: "success",
              text: "Successfully purchased credentials.",
            });
          }
        },
        onError: () => dispatch({ type: ActionTypes.SaveFailed }),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.submitted]);

  React.useEffect(() => {
    if (state.errors.has(ErrorTypes.FailedToSave)) {
      createNotification({
        type: "error",
        text: "Failed to purchase credentials.",
      });
    }
  }, [createNotification, state.errors]);
  /* Sorts  customers with Unikey enabled to top of list*/
  const sortedCustomers = React.useMemo(
    () =>
      R.sort((a, b) => {
        const aShouldBeFirst = -1;
        const bShouldBeFirst = 1;
        if (a.unikeyEnabled && !b.unikeyEnabled) {
          return aShouldBeFirst;
        }
        if (b.unikeyEnabled && !a.unikeyEnabled) {
          return bShouldBeFirst;
        }

        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      }, data.customersConnection.nodes),
    [data.customersConnection.nodes]
  );
  const customerOptions = React.useMemo(
    () =>
      sortedCustomers.map((customer) => ({
        value: customer.id,
        label: `${customer.name} (${customer.availableMobileCredentials})`,
      })),
    [sortedCustomers]
  );
  const selectedOption = React.useMemo(
    () =>
      state.selectedCustomerId
        ? customerOptions.find(
            (option) => option.value === state.selectedCustomerId
          )
        : null,
    [customerOptions, state.selectedCustomerId]
  );
  return (
    <PanelFixedHeight
      className={
        isIssuingCredentials
          ? `mar-b-0 pad-24 csspinner traditional`
          : `mar-b-0 pad-24`
      }
    >
      <Flex.Row>
        <Flex.Col>
          <p>
            Search for a customer below to purchase credentials. After
            purchasing, the credentials can be issued to a user in Virtual
            Keypad&trade;.
          </p>
        </Flex.Col>
      </Flex.Row>
      <Flex.Row>
        <Flex.Col size={0.8}>
          <Form.Group error={state.errors.has(ErrorTypes.NoCustomerSelected)}>
            <Form.Label>Customer</Form.Label>
            <Select
              key={state.selectedCustomerId}
              components={{ MenuList: CustomerMenuList }}
              styles={customSelectStyles}
              options={customerOptions}
              isSearchable
              backspaceRemovesValue
              value={selectedOption}
              onChange={(customer) =>
                customer &&
                dispatch({
                  type: ActionTypes.CustomerSelected,
                  customerId: customer.value,
                })
              }
            />
          </Form.Group>
        </Flex.Col>
        <Flex.Col size={0.3}>
          <Form.Group error={state.errors.has(ErrorTypes.AmountCantBeZero)}>
            <Form.Label>Quantity</Form.Label>

            <NaturalNumberInput
              className="form-control"
              value={state.amount ?? ""}
              max={1000}
              onChange={(event) => {
                dispatch({
                  type: ActionTypes.AmountUpdated,
                  value: event.currentTarget.value,
                });
              }}
              onBlur={() => {
                dispatch({
                  type: ActionTypes.AmountBlurred,
                });
              }}
            />

            {state.errors.has(ErrorTypes.AmountCantBeZero) ? (
              <Form.ErrorMsg>Please enter 1 or more credentials</Form.ErrorMsg>
            ) : null}
          </Form.Group>
        </Flex.Col>
      </Flex.Row>
      <Flex.Row>
        <Flex.Col>
          <Form.Group>
            <Form.Label>Notes/PO</Form.Label>

            <textarea
              id="notes"
              className="form-control"
              rows={10}
              cols={50}
              max-length="256"
              value={state.notes}
              onChange={(event) =>
                dispatch({
                  type: ActionTypes.NotesUpdated,
                  notes: event.currentTarget.value,
                })
              }
            ></textarea>
          </Form.Group>
        </Flex.Col>
      </Flex.Row>
      <Flex.Row justify="flex-end">
        <Flex.Col size={1} align-self="end">
          <span
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <TotalPriceArea>
              {state.amount && state.amount > 0
                ? `Total: $
              ${(data.mobileCredentialCost * state.amount).toFixed(2)}`
                : null}
            </TotalPriceArea>
            <Button
              design="primary"
              size="sm"
              type="submit"
              disabled={isIssuingCredentials}
              onClick={() =>
                dispatch({
                  type: ActionTypes.Submit,
                })
              }
            >
              Purchase Credentials
            </Button>
          </span>
        </Flex.Col>
      </Flex.Row>
    </PanelFixedHeight>
  );
};

export default IssueCredentials;
