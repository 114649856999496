import { camelCaseToTitleCase } from "common/utils";
import Icon from "components/Icon";
import { GenericConfirmModal } from "components/Modal/ConfirmModal";
import { CameraStatusBadge } from "components/VarStatusBadge";
import React from "react";
import { InputLabel, ModalInputContainer } from "./CameraEditStyledComponents";

interface CameraDetailsModalProps {
  status: boolean;
  cameraName: string;
  macAddress: string;
  serialNumber?: string | null;
  ipAddress?: string;
  resolution?: number;
  userName?: string;
  password?: string;
  activationStatus?: string;
  timeZone?: string;
  daylightSavingsTime?: boolean;
  lastCheckIn?: string | null;
  wirelessSignalStrength?: number | null;
  firmwareVersion?: string | null;
  sdCardStatus?: string;
  onFormatSdCard?: () => void;
  onCancel: () => void;
  ableToFormatSdCard?: boolean;
}
function CameraDetailsModal(props: CameraDetailsModalProps) {
  const { status, userName, onCancel, onFormatSdCard, ableToFormatSdCard } =
    props;

  const [formatSdCardModalIsOpen, setFormatSdCardModalIsOpen] =
    React.useState(false);

  return (
    <>
      <GenericConfirmModal
        header="Camera Details"
        cancelText="Close"
        onCancel={onCancel}
        suppressConfirm={true}
      >
        <ModalInputContainer>
          <CameraStatusBadge status={status} />
        </ModalInputContainer>

        {Object.entries(props).map((field) => {
          let title = camelCaseToTitleCase(field[0]);
          let value = field[1] || "";
          let placeholder = "";
          let show = true;
          let type = "text";

          switch (field[0]) {
            case "status":
            case "onCancel":
              show = false;
              break;
            case "macAddress":
              title = "MAC Address";
              placeholder = "MAC Address Not Available";
              break;
            case "ipAddress":
              title = "IP Address";
              break;
            case "resolution":
              value = `${value} MP`;
              placeholder = "0 MP";
              break;
            case "userName":
              show = field[1] !== "";
              break;
            case "password":
              show = userName !== "";
              break;
            case "daylightSavingsTime":
              value = value.toString().toUpperCase();
              break;
            case "lastCheckIn":
              value = value.slice(0, -6); // Dropping timezone info from datetime string
              type = "datetime-local";
              break;
            case "wirelessSignalStrength":
              value = value === "" ? "Wireless Not Connected" : (value += "%");
              break;
            case "sdCardStatus":
              title = "SD Card Status";
              value = value ? value.toUpperCase() : "SD Card Not Available";
              break;
            case "onFormatSdCard":
              show = false;
              break;
            case "ableToFormatSdCard":
              show = false;
              break;
            default:
              break;
          }

          return (
            show && (
              <ModalInputContainer>
                <div className="col-xs-4">
                  <InputLabel>{title}</InputLabel>
                </div>
                <div className="col-xs-8">
                  {title !== "SD Card Status" ? (
                    <input
                      type={type}
                      className="form-control"
                      disabled
                      value={value}
                      placeholder={placeholder}
                    />
                  ) : ableToFormatSdCard ? (
                    <div className="input-group ">
                      <input
                        type={type}
                        className="form-control"
                        disabled
                        value={value}
                        placeholder={placeholder}
                      />
                      <span className="input-group-btn">
                        <button
                          type="button"
                          className="btn btn-default"
                          onClick={() => setFormatSdCardModalIsOpen(true)}
                        >
                          Format
                        </button>
                      </span>
                    </div>
                  ) : (
                    <input
                      type={type}
                      className="form-control"
                      disabled
                      value={value}
                      placeholder={placeholder}
                    />
                  )}
                </div>
              </ModalInputContainer>
            )
          );
        })}
      </GenericConfirmModal>

      {formatSdCardModalIsOpen && (
        <GenericConfirmModal
          header={"Format SD Card"}
          children={
            "Are you sure you want to format the SD Card? Any existing video recordings will be deleted. This action cannot be undone."
          }
          confirmText={"Proceed"}
          cancelText={"Cancel"}
          pendingText={"Working..."}
          onConfirm={() => {
            onFormatSdCard && onFormatSdCard();
            setFormatSdCardModalIsOpen(false);
            onCancel();
          }}
          onCancel={() => setFormatSdCardModalIsOpen(false)}
          icon={<Icon name="radial_info" />}
        />
      )}
    </>
  );
}

export default CameraDetailsModal;
