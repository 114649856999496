import graphql from "babel-plugin-relay/macro";
import Icon from "components/Icon";
import { CameraStatusPuckWithActivationStatus } from "components/VarStatusBadge";
import * as React from "react";
import { useEffect } from "react";
import { useRefetchableFragment } from "react-relay/hooks";
import {
  asID,
  fromVideoChannelId,
  VideoDeviceStatus,
} from "securecom-graphql/client";
import {
  CssGridTable,
  HeaderRow,
  TableBody,
  TableButtons,
  TableDataCell,
  TableHead,
  TableHeadCell,
  TableResponsive,
  TableRow,
  VideoDeviceHeader,
  VideoDeviceHeaderButtons,
} from "./styles";
import TestConnectionButton, { DeviceType } from "./TestConnectionButton";

import AddCameraModal from "components/AddCameraModal/AddCameraModal";
import Modal from "components/Modal";
import styled from "styled-components/macro";
import { CameraTable_controlSystem$key } from "./__generated__/CameraTable_controlSystem.graphql";
import { CameraTable_controlSystemQuery } from "./__generated__/CameraTable_controlSystemQuery.graphql";
interface CameraTableProps {
  state: any;
  customerId: string;
  systemId: string;
  isRefreshing: boolean;
  systemRef: CameraTable_controlSystem$key;
  userService: any;
  videoDeviceService: any;
  controlSystemService: any;
  scope: any;
}
export const CameraTable = (props: CameraTableProps) => {
  const {
    state,
    customerId,
    systemId,
    isRefreshing,
    systemRef,
    userService,
    videoDeviceService,
  } = props;
  const [cameraList, refetchCameraList] = useRefetchableFragment<
    CameraTable_controlSystemQuery,
    CameraTable_controlSystem$key
  >(
    graphql`
      fragment CameraTable_controlSystem on ControlSystem
      @refetchable(queryName: "CameraTable_controlSystemQuery") {
        secureComCameras {
          id
          deviceType
          name
          lastCheckIn
          isOnline
          status
          model
          formFactor
          imageSize
          macAddress
          networkReady
          manufacturer
        }
      }
    `,
    systemRef
  );

  useEffect(() => {
    if (isRefreshing) {
      refetchCameraList({}, { fetchPolicy: "network-only" });
    }
  }, [isRefreshing, refetchCameraList]);

  const [modalIsOpen, setIsModalOpen] = React.useState(false);

  return (
    <>
      <VideoDeviceHeader>
        <Icon name="camera" size="4rem" style={{ paddingRight: "1rem" }} />
        Cameras
        <div style={{ marginLeft: "auto" }}>
          {userService.canEditVideoDevice() ? (
            <VideoDeviceHeaderButtons>
              <button
                onClick={() =>
                  (location.href = `/#/app/customers/${userService.customer_id}/control_systems/${userService.control_system_id}/customers/${userService.customer_id}/control_systems/${userService.control_system_id}/camera-mappings`)
                }
                className="btn btn-default btn-sm"
              >
                Assign Cameras to Zones
              </button>
              <button
                onClick={() => {
                  setIsModalOpen(true);
                }}
                className="btn btn-dmp btn-sm"
                disabled={!videoDeviceService.canAddChannels}
              >
                <InnerAddCameraButtonContainer>
                  <Icon name="add" size="1.6rem" color="primary" />
                  <CameraButtonSpan>Camera</CameraButtonSpan>
                </InnerAddCameraButtonContainer>
              </button>
            </VideoDeviceHeaderButtons>
          ) : null}
        </div>
        {modalIsOpen ? (
          <Modal size="medium">
            <AddCameraModal
              handleModalClose={() => setIsModalOpen(false)}
              {...props}
            />
          </Modal>
        ) : null}
      </VideoDeviceHeader>

      {cameraList?.secureComCameras.length ? (
        <TableResponsive>
          <CssGridTable
            className="video-device__header--camera__table table table-striped "
            style={{
              marginBottom: "var(--measure-3x)",
              width: "100%",
              tableLayout: "fixed",
            }}
          >
            <TableHead>
              <HeaderRow>
                <TableHeadCell>Camera</TableHeadCell>
                <TableHeadCell>Model</TableHeadCell>
                <TableHeadCell>Resolution</TableHeadCell>
                <TableHeadCell></TableHeadCell>
              </HeaderRow>
            </TableHead>
            <TableBody>
              {cameraList.secureComCameras.map((camera) => {
                const { deviceId, videoChannelId } = fromVideoChannelId(
                  asID(camera.id)
                );

                return (
                  <TableRow key={deviceId}>
                    <TableDataCell>
                      <div
                        style={{
                          display: "grid",
                          gridAutoFlow: "column",
                          justifyContent: "start",
                          alignItems: "center",
                        }}
                      >
                        <CameraStatusPuckWithActivationStatus
                          activationStatus={
                            VideoDeviceStatus[
                              camera.status as VideoDeviceStatus
                            ]
                          }
                          onlineStatus={
                            !!camera.isOnline ? camera.networkReady : null
                          }
                          lastCheckIn={
                            camera.lastCheckIn ? camera.lastCheckIn : undefined
                          }
                        />
                        <TableButtons
                          title={`${camera.name}\n${camera.macAddress}`}
                          onClick={(event) => {
                            event.preventDefault();
                            const app =
                              camera.manufacturer === "Uniview"
                                ? "app.uniview-camera-edit"
                                : "app.view-camera";
                            state.go(app, {
                              customer_id: customerId,
                              control_system_id: systemId,
                              device_id: deviceId,
                              channel_id: videoChannelId,
                            });
                          }}
                        >
                          {camera.name}
                        </TableButtons>
                      </div>
                    </TableDataCell>
                    <TableDataCell>
                      <span title={camera?.model ?? ""}>{camera?.model}</span>
                    </TableDataCell>
                    <TableDataCell>
                      {camera.imageSize ? camera.imageSize : "1MP"}
                    </TableDataCell>
                    <TableDataCell style={{ textAlign: "right" }}>
                      <TestConnectionButton
                        deviceType={DeviceType.Camera}
                        deviceId={camera.id}
                      />
                    </TableDataCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </CssGridTable>
        </TableResponsive>
      ) : (
        <NoCamerasContainer>
          <span className="info-alert mar-l-24">
            <Icon name="radial_info" size="1.6rem" color="hsl(0, 0%, 65%)" />
            Camera service is enabled, but no camera devices have been
            configured
          </span>
        </NoCamerasContainer>
      )}
    </>
  );
};

export const NoCamerasContainer = styled.div`
  font-size: 1.2rem;
`;
export const InnerAddCameraButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const CameraButtonSpan = styled.span`
  margin-left: 0.4rem;
`;
