import { pick } from "ramda";
import React, { useEffect, useReducer } from "react";
import { getDMPTimezoneOffset, getIsDST } from "utils/dates";
import {
  useCustomerId,
  useGenericControlSystemStateFromAngular,
  useSilentlyRedirectToSite as useRedirectToSite,
  useSyncAngularState,
} from "../EntryPointContext";
import Sections from "../Layout/Layout";
import { GenericControlSystemState, SavingState } from "../types";
import GenericControlSystemForm from "./GenericControlSystemForm";
import { reducer, State } from "./reducer";
import NewSiteFormWalkthrough from "./Walkthrough";

import graphql from "babel-plugin-relay/macro";
import Portal from "common/components/web/Portal";
import RedirectTo404 from "components/RedirectTo404";
import { useLazyLoadQuery } from "react-relay/hooks";
import {
  ControlSystemCommType,
  SiteControlSystemIntendedUsage,
} from "securecom-graphql/client";
import { NewSiteQuery } from "./__generated__/NewSiteQuery.graphql";

function toGenericControlSystemState(state: State): GenericControlSystemState {
  return {
    useBillingAddress: state.useBillingAddress,
    type: state.type,
    ...pick(
      [
        "name",
        "address1",
        "address2",
        "city",
        "state",
        "postalCode",
        "country",
      ],
      state
    ),
  };
}

/**
 * The new control system url is overloaded to save
 * both control systems and sites. This component handles
 * switching between the two types of forms.
 */
export default function NewSite({
  setSiteId,
}: {
  setSiteId: (siteId: string) => void;
}) {
  const genericControlSystemState = useGenericControlSystemStateFromAngular();
  const [customerId] = useCustomerId();

  const { customer, billingAddress } = useLazyLoadQuery<NewSiteQuery>(
    graphql`
      query NewSiteQuery($customerId: ID!) {
        customer(id: $customerId) {
          name
        }
        billingAddress: customer(id: $customerId) {
          address1
          address2
          city
          state
          postalCode
          country
        }
      }
    `,
    {
      customerId,
    }
  );

  const [state, dispatch] = useReducer(reducer, {
    useBillingAddress: !!genericControlSystemState.useBillingAddress,
    type: genericControlSystemState.type || "",
    ...(billingAddress
      ? billingAddress
      : pick(
          ["address1", "address2", "city", "state", "postalCode", "country"],
          genericControlSystemState
        )),
    name: genericControlSystemState.name,
    observesDaylightSavingsTime: getIsDST(),
    timezoneOffset: getDMPTimezoneOffset(),
    savingState: SavingState.Idle,
    firstDoorSerialNumber: "",
    preProgram: false,
    intendedUsage: SiteControlSystemIntendedUsage.DOOR_ACCESS,
    connectionType: ControlSystemCommType.PERSISTENT,
  });

  const redirectToSite = useRedirectToSite();
  const syncAngularState = useSyncAngularState();

  useEffect(() => {
    if (state.type && state.type !== "X001") {
      // Angular will unmount the AngularEntryPoint component
      // and entirely and rerender with an angular form.
      syncAngularState(toGenericControlSystemState(state));
    }
  }, [state, syncAngularState]);

  return customer && billingAddress ? (
    <>
      <Portal element={document.querySelector("#site-name")}>
        New System for {customer.name}
      </Portal>
      <Sections>
        <Sections.Section>
          {state.type === "X001" ? (
            <NewSiteFormWalkthrough
              onSave={(siteId: string) => {
                redirectToSite(siteId);
                setSiteId(siteId);
              }}
              state={state}
              dispatch={dispatch}
              billingAddress={billingAddress}
            />
          ) : (
            <GenericControlSystemForm
              state={toGenericControlSystemState(state)}
              dispatch={dispatch}
              billingAddress={billingAddress}
            />
          )}
        </Sections.Section>
      </Sections>
    </>
  ) : (
    <RedirectTo404 />
  );
}
