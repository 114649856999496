import graphql from "babel-plugin-relay/macro";
import { IncludedTemplateFieldsType } from "components/FullProgramming/common/IncludedTemplateFieldsContext";
import { Dispatch, SetStateAction } from "react";
import { readInlineData } from "react-relay";
import { setIncludedFields as setAreaInformationIncludedFields } from "./Data/XT75AreaInformationTemplateData";
import { setIncludedFields as setBellOptionsIncludedFields } from "./Data/XT75BellOptionsTemplateData";
import { setIncludedFields as setCardFormatsIncludedFields } from "./Data/XT75CardFormatsTemplateData";
import { setIncludedFields as setCommunicationIncludedFields } from "./Data/XT75CommunicationPathTemplateData";
import { setIncludedFields as setDeviceInformationIncludedFields } from "./Data/XT75DeviceInformationTemplateData";
import { setIncludedFields as setLockoutCodeIncludedFields } from "./Data/XT75LockoutCodeTemplateData";
import { setIncludedFields as setNetworkOptionsIncludedFields } from "./Data/XT75NetworkOptionsTemplateData";
import { setIncludedFields as setOutputInformationsIncludedFields } from "./Data/XT75OutputInformationTemplateData";
import { setIncludedFields as setOutputOptionsIncludedFields } from "./Data/XT75OutputOptionsTemplateData";
import { setIncludedFields as setRemoteOptionsIncludedFields } from "./Data/XT75RemoteOptionsTemplateData";
import { setIncludedFields as setSystemAreaInformationIncludedFields } from "./Data/XT75SystemAreaInformationTemplateData";
import { setIncludedFields as setSystemOptionsIncludedFields } from "./Data/XT75SystemOptionsTemplateData";
import { setIncludedFields as setSystemReportsIncludedFields } from "./Data/XT75SystemReportsTemplateData";
import { setIncludedFields as setZoneInformationIncludedFields } from "./Data/XT75ZoneInformationTemplateData";
import { XT75TemplateDataInline_xt75ProgrammingTemplateConcepts$key } from "./__generated__/XT75TemplateDataInline_xt75ProgrammingTemplateConcepts.graphql";

export const getState = (
  template: XT75TemplateDataInline_xt75ProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XT75TemplateDataInline_xt75ProgrammingTemplateConcepts on Xt75ProgrammingTemplateConcepts
      @inline {
        ...XT75AreaInformationTemplateDataInline_xt75ProgrammingTemplateConcepts
        ...XT75BellOptionsTemplateDataInline_xt75ProgrammingTemplateConcepts
        ...XT75CardFormatsTemplateDataInline_xt75ProgrammingTemplateConcepts
        ...XT75CommunicationPathTemplateDataInline_xt75ProgrammingTemplateConcepts
        ...XT75DeviceInformationTemplateDataInline_xt75ProgrammingTemplateConcepts
        ...XT75LockoutCodeTemplateDataInline_xt75ProgrammingTemplateConcepts
        ...XT75NetworkOptionsTemplateDataInline_xt75ProgrammingTemplateConcepts
        ...XT75OutputInformationTemplateDataInline_xt75ProgrammingTemplateConcepts
        ...XT75OutputOptionsTemplateDataInline_xt75ProgrammingTemplateConcepts
        ...XT75RemoteOptionsTemplateDataInline_xt75ProgrammingTemplateConcepts
        ...XT75SystemAreaInformationTemplateDataInline_xt75ProgrammingTemplateConcepts
        ...XT75SystemOptionsTemplateDataInline_xt75ProgrammingTemplateConcepts
        ...XT75SystemReportsTemplateDataInline_xt75ProgrammingTemplateConcepts
        ...XT75ZoneInformationTemplateDataInline_xt75ProgrammingTemplateConcepts
      }
    `,
    template
  );

export const setAllIncludedFields = (
  template: XT75TemplateDataInline_xt75ProgrammingTemplateConcepts$key,
  setIncludedFields: Dispatch<SetStateAction<IncludedTemplateFieldsType>>
) => {
  const templateData = getState(template);

  setAreaInformationIncludedFields(templateData, setIncludedFields);
  setBellOptionsIncludedFields(templateData, setIncludedFields);
  setCardFormatsIncludedFields(templateData, setIncludedFields);
  setCommunicationIncludedFields(templateData, setIncludedFields);
  setDeviceInformationIncludedFields(templateData, setIncludedFields);
  setLockoutCodeIncludedFields(templateData, setIncludedFields);
  setNetworkOptionsIncludedFields(templateData, setIncludedFields);
  setOutputOptionsIncludedFields(templateData, setIncludedFields);
  setOutputInformationsIncludedFields(templateData, setIncludedFields);
  setRemoteOptionsIncludedFields(templateData, setIncludedFields);
  setSystemAreaInformationIncludedFields(templateData, setIncludedFields);
  setSystemOptionsIncludedFields(templateData, setIncludedFields);
  setSystemReportsIncludedFields(templateData, setIncludedFields);
  setZoneInformationIncludedFields(templateData, setIncludedFields);
};
