import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { fetchQuery, RecordProxy } from "relay-runtime";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import {
  ControlSystem,
  PanelHardwareModel,
  SystemType,
  toControlSystemId,
} from "securecom-graphql/client";
import { Concept } from "../../FullProgramming/common/FullProgrammingForm";
import FullProgrammingVersionEntryPoint from "../../FullProgramming/common/FullProgrammingVersionEntryPoint";
import {
  createConcepts,
  xtFullProgrammingControlSystem,
  xtFullProgrammingInlineControlSystem,
} from "../../FullProgramming/XTFullProgramming";
import { XTTemplateContainerTemplateQuery } from "./__generated__/XTTemplateContainerTemplateQuery.graphql";

export const XTTemplateContainer: React.FC<{
  dealerId: string;
  hardwareModel: PanelHardwareModel;
  softwareVersion: string;
}> = ({ dealerId, hardwareModel, softwareVersion }) => {
  const concepts = React.useMemo(
    () =>
      createConcepts({
        softwareVersion: Number(softwareVersion),
        hasMessagingSetup: false,
        hardwareModel,
      }) as Concept[],
    [softwareVersion]
  );

  return (
    <FullProgrammingVersionEntryPoint
      systemName={`Default ${hardwareModel}`}
      concepts={concepts}
      gqlQuery={graphql`
        query XTTemplateContainerQuery(
          $dealerId: ID!
          $hardwareModel: PanelHardwareModel!
          $softwareVersion: String!
        ) {
          dealer: node(id: $dealerId) {
            ... on Dealer {
              id
              ...FullProgrammingVersionEntryPoint_dealer
            }
          }
          controlSystem: defaultControlSystem(
            hardwareModel: $hardwareModel
            softwareVersion: $softwareVersion
            dealerId: $dealerId
          ) {
            id
            name
            panel {
              softwareVersion
              hardwareModel
              systemOptions {
                ... on XtSystemOptions {
                  systemType
                }
              }
            }
            customer {
              id
            }

            ...NetworkOptionsDhcpEnabledField_controlSystem
            ...XTFullProgramming_controlSystem
            ...XTAreaInformationProgrammingConceptFormOriginalAreas_controlSystem
            ...XTSystemOptionsProgrammingConceptFormInline_controlSystem
            ...NetworkOptionsDhcpEnabledField_controlSystem
            ...XTOutputInformationProgrammingConceptFormInline_controlSystem
            ...XTAreaInformationProgrammingConceptFormInline_controlSystem
            ...XTDeviceSetupProgrammingConceptFormInline_controlSystem
            ...XTKeyfobProgrammingConceptFormInline_controlSystem
            ...XTZoneInformationsProgrammingConceptFormInline_controlSystem
          }
          controlSystemInline: defaultControlSystem(
            hardwareModel: $hardwareModel
            softwareVersion: $softwareVersion
            dealerId: $dealerId
          ) {
            id
            ...XTFullProgrammingInline_controlSystem
          }
        }
      `}
      gqlQueryVariables={{
        hardwareModel,
        softwareVersion,
        dealerId,
      }}
      gqlFormControlSystemFragment={xtFullProgrammingControlSystem}
      gqlFormControlSystemInlineFragment={xtFullProgrammingInlineControlSystem}
      applyTemplate={applyTemplate(toControlSystemId(-1).toString(), concepts)}
    />
  );
};

const fetchTemplateData = (environment: RelayModernEnvironment, id: string) =>
  fetchQuery<XTTemplateContainerTemplateQuery>(
    environment,
    graphql`
      query XTTemplateContainerTemplateQuery($templateId: ID!) {
        programmingTemplate: node(id: $templateId) {
          id
          ... on ProgrammingTemplate {
            concepts {
              ... on XtProgrammingTemplateConcepts {
                systemOptions {
                  systemType {
                    included
                  }
                }
                ...XTCommunicationProgrammingConceptFormInline_xtProgrammingTemplateConcepts
                ...XTNetworkOptionsProgrammingConceptFormInline_xtProgrammingTemplateConcepts
                ...XTDeviceSetupProgrammingConceptFormInline_xrProgrammingTemplateConcepts
                ...XTRemoteOptionsProgrammingConceptFormInline_xtProgrammingTemplateConcepts
                ...XTSystemReportsProgrammingConceptFormInline_xtProgrammingTemplateConcepts
                ...XTSystemOptionsProgrammingConceptFormInline_xtProgrammingTemplateConcepts
                ...XTBellOptionsProgrammingConceptFormInline_xrProgrammingTemplateConcepts
                ...XTOutputInformationProgrammingConceptFormInline_xrProgrammingTemplateConcepts
                ...XTOutputOptionsProgrammingConceptFormInline_xtProgrammingTemplateConcepts
                ...XTAreaInformationProgrammingConceptFormInline_xtProgrammingTemplateConcepts
                ...XTZoneInformationsProgrammingConceptFormInline_xtProgrammingTemplateConcepts
                ...XTKeyfobProgrammingConceptFormInline_xrProgrammingTemplateConcepts
                ...XTLockoutCodeProgrammingConceptFormInline_xtProgrammingTemplateConcepts
              }
            }
          }
        }
      }
    `,
    { templateId: id },
    { fetchPolicy: "network-only" }
  )
    .toPromise()
    .then((response) => response?.programmingTemplate);

const applyTemplate =
  (systemId: string, concepts: Concept[]) =>
  async (environment: RelayModernEnvironment, templateId: string) => {
    const data = await fetchTemplateData(environment, templateId);
    if (data?.concepts) {
      environment.commitUpdate((store) => {
        const controlSystem =
          store.get<ControlSystem>(systemId) ??
          (store.create(
            systemId,
            "ControlSystem"
          ) as RecordProxy<ControlSystem>);
        //When applying a template to the default system for editing it is important that the system type is set to area mode unless it is included
        // in the template specifically.  The system type is used to determine which areas are shown in the programming form
        // if the system type is not set to area mode then all of the areas in the template will not be shown
        const panelRecord = controlSystem.getLinkedRecord("panel");
        const systemOptionsRecord =
          panelRecord.getLinkedRecord("systemOptions");
        if (!data?.concepts?.systemOptions?.systemType?.included) {
          systemOptionsRecord.setValue(SystemType.AREA, "systemType");
        }
        concepts.forEach((concept) => {
          if (concept.applyTemplateData) {
            concept.applyTemplateData(data.concepts, controlSystem, store);
          }
        });
      });
    }
  };
