import graphql from "babel-plugin-relay/macro";
import { emptyStringOrNumber } from "components/FullProgramming/utils/format";
import { clamp } from "ramda";
import * as React from "react";
import NumericInput from "../NumericInput";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCardFormatFragment } from "./CardFormatContext";
import { CardFormatUserCodeLengthField_cardFormat$key } from "./__generated__/CardFormatUserCodeLengthField_cardFormat.graphql";

export const cardFormatUserCodeLengthFieldId = (number: string) =>
  `card-format-user-code-length-${number}`;

function CardFormatUserCodeLengthField() {
  const [{ userCodeLength, number }, updateCardFormat] =
    useCardFormatFragment<CardFormatUserCodeLengthField_cardFormat$key>(
      graphql`
        fragment CardFormatUserCodeLengthField_cardFormat on CardFormat {
          userCodeLength
          number
        }
      `
    );

  const fieldId = cardFormatUserCodeLengthFieldId(number);
  const { current: originalValue } = React.useRef(userCodeLength);
  const disabled = false;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="User Code Length"
      disabled={disabled}
      tooltip="Length of the User Code within the card format."
    >
      <NumericInput
        id={fieldId}
        disabled={disabled}
        pattern={"(1[6-9]|[2-5][0-9]|6[0-4])"}
        validationMessage="Valid values are 16-64."
        value={userCodeLength ?? 16}
        inlineHelp={"16-64"}
        min={16}
        max={64}
        required
        onChange={({ target }) => {
          updateCardFormat((recordProxy) => {
            recordProxy.setValue(
              emptyStringOrNumber(target.value),
              "userCodeLength"
            );
          });
        }}
        onBlur={({ target }) => {
          updateCardFormat((recordProxy) => {
            const valueAsNumber = Number(target.value);
            const value = isNaN(valueAsNumber)
              ? originalValue
              : clamp(16, 64, valueAsNumber);
            recordProxy.setValue(value, "userCodeLength");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default CardFormatUserCodeLengthField;
