/**
 * @generated SignedSource<<5864028d4db479e7c8446d12311afb09>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateSiteDetailsInput = {
  address1: string;
  address2: string;
  armablePanelSerialNumber?: string | null;
  city: string;
  country: string;
  id: string;
  name: string;
  observesDaylightSavingsTime: boolean;
  postalCode: string;
  showDoors: boolean;
  showElevators: boolean;
  showOutputs: boolean;
  showVideoServices: boolean;
  state: string;
  timezoneOffset: number;
};
export type SiteEditModalUpdateSiteDetailsMutation$variables = {
  site: UpdateSiteDetailsInput;
};
export type SiteEditModalUpdateSiteDetailsMutation$data = {
  readonly updateSiteDetails: {
    readonly __typename: "Site";
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"SiteEditModal_site">;
  } | {
    readonly __typename: "UpdateSiteDetailsFailedToSetSystemOptionsOnDoorsError";
    readonly totalFailures: number;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type SiteEditModalUpdateSiteDetailsMutation = {
  response: SiteEditModalUpdateSiteDetailsMutation$data;
  variables: SiteEditModalUpdateSiteDetailsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "site"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "site",
    "variableName": "site"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalFailures",
      "storageKey": null
    }
  ],
  "type": "UpdateSiteDetailsFailedToSetSystemOptionsOnDoorsError",
  "abstractKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address1",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address2",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "city",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "postalCode",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "country",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SiteEditModalUpdateSiteDetailsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "updateSiteDetails",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SiteEditModal_site"
              }
            ],
            "type": "Site",
            "abstractKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SiteEditModalUpdateSiteDetailsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "updateSiteDetails",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "observesDaylightSavingsTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timezoneOffset",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "showDoors",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "showElevators",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "showOutputs",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "showVideoServices",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "alarmPanelX1Integration",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "supportsXrCommunication",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "xrCommunicationEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "armablePanelId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "armablePanelSerialNumber",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sitePassphrase",
                "storageKey": null
              },
              {
                "alias": "customerBillingAddress",
                "args": null,
                "concreteType": "Customer",
                "kind": "LinkedField",
                "name": "customer",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SiteControlSystem",
                "kind": "LinkedField",
                "name": "controlSystems",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "intendedUsage",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "include",
                    "value": "OUTPUTS"
                  }
                ],
                "concreteType": "SiteOutputModulesConnection",
                "kind": "LinkedField",
                "name": "outputModulesConnection",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SiteOutputModule",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "address",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SiteControlSystem",
                        "kind": "LinkedField",
                        "name": "controlSystem",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "serialNumber",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SiteDoor",
                            "kind": "LinkedField",
                            "name": "doors",
                            "plural": true,
                            "selections": [
                              (v5/*: any*/),
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SiteOutputModuleOutputsConnection",
                        "kind": "LinkedField",
                        "name": "outputsConnection",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SiteOutput",
                            "kind": "LinkedField",
                            "name": "nodes",
                            "plural": true,
                            "selections": [
                              (v5/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "relayNumber",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "type",
                                "storageKey": null
                              },
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "outputModulesConnection(include:\"OUTPUTS\")"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "videoEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "securecomNvrEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hikvisionDoorbellEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "openEyeEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hikvisionNvrEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "eagleEyeEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "digitalWatchdogSpectrumEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hanwhaWaveEnabled",
                "storageKey": null
              }
            ],
            "type": "Site",
            "abstractKey": null
          },
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/)
            ],
            "type": "Node",
            "abstractKey": "__isNode"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7a6080317bd1b36fbfe98df711224a64",
    "id": null,
    "metadata": {},
    "name": "SiteEditModalUpdateSiteDetailsMutation",
    "operationKind": "mutation",
    "text": "mutation SiteEditModalUpdateSiteDetailsMutation(\n  $site: UpdateSiteDetailsInput!\n) {\n  updateSiteDetails(site: $site) {\n    __typename\n    ... on Site {\n      id\n      ...SiteEditModal_site\n    }\n    ... on UpdateSiteDetailsFailedToSetSystemOptionsOnDoorsError {\n      totalFailures\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment SiteEditModal_site on Site {\n  id\n  name\n  address1\n  address2\n  city\n  state\n  postalCode\n  country\n  observesDaylightSavingsTime\n  timezoneOffset\n  showDoors\n  showElevators\n  showOutputs\n  showVideoServices\n  alarmPanelX1Integration\n  supportsXrCommunication\n  xrCommunicationEnabled\n  armablePanelId\n  armablePanelSerialNumber\n  sitePassphrase\n  customerBillingAddress: customer {\n    address1\n    address2\n    city\n    state\n    postalCode\n    country\n    id\n  }\n  controlSystems {\n    intendedUsage\n    id\n  }\n  outputModulesConnection(include: OUTPUTS) {\n    nodes {\n      id\n      name\n      address\n      controlSystem {\n        serialNumber\n        doors {\n          name\n          id\n        }\n        id\n      }\n      outputsConnection {\n        nodes {\n          name\n          relayNumber\n          type\n          id\n        }\n      }\n    }\n  }\n  videoEnabled\n  securecomNvrEnabled\n  hikvisionDoorbellEnabled\n  openEyeEnabled\n  hikvisionNvrEnabled\n  eagleEyeEnabled\n  digitalWatchdogSpectrumEnabled\n  hanwhaWaveEnabled\n}\n"
  }
};
})();

(node as any).hash = "a4588259a6d46e67cf7900ba43fbccc8";

export default node;
