/**=========================================================
 * Module: config.js
 * App routes and resources configuration
 =========================================================*/

/**
 * The $stateProvider uses `dot notation` to prefix urls together.
 * A route's url is then prefixed with all other urls in the dot notation:
 *
 * E.G.:
 * ```
 *  $stateProvider.state("app", { url: "/app", ... })
 *  $stateProvider.state("app.dealer", { url: "/dealers/:dealer_id", ... })
 *  $stateProvider.state("app.dealer.page", { url: "page", ... })
 * ```
 *
 * The `app.dealer.page` url would result in: `#/app/dealers/:dealer_id/page`
 */

/**
 * Abstract routes are placeholders for other routes and are non-navigable.
 * This does not affect sub-routes. Each subroute can also be abstract if desired.
 *
 * E.G.:
 * ```
 * $stateProvider.state("app", { url: "/app", abstract: true, ... })
 * ```
 *
 * `/app` cannot be directly navigated to, but can be used as a prefix: `app.page`
 */
import graphql from "babel-plugin-relay/macro";
import { toSiteId } from "securecom-graphql/client";

App.config([
  "$stateProvider",
  "$urlRouterProvider",
  "$controllerProvider",
  "$compileProvider",
  "$filterProvider",
  "$provide",
  "$ocLazyLoadProvider",
  "APP_REQUIRES",
  function (
    $stateProvider,
    $urlRouterProvider,
    $controllerProvider,
    $compileProvider,
    $filterProvider,
    $provide,
    $ocLazyLoadProvider,
    appRequires
  ) {
    "use strict";

    App.controller = $controllerProvider.register;
    App.directive = $compileProvider.directive;
    App.filter = $filterProvider.register;
    App.factory = $provide.factory;
    App.service = $provide.service;
    App.constant = $provide.constant;
    App.value = $provide.value;

    // ---------------------------
    // Lazy Modules
    // ---------------------------

    $ocLazyLoadProvider.config({
      debug: false,
      events: true,
      modules: appRequires.modules,
    });

    // Send unknown page requests to 404
    $urlRouterProvider.otherwise("/page/404");

    // Set the default page request to login
    $urlRouterProvider.when("", "/page/login");

    // ===========================
    // Styleguide Routes
    // ===========================

    /**
     * Styleguide
     * @desc - a hidden view of styles for dealer-admin
     */
    $stateProvider
      .state("styleguide", {
        url: "/styleguide",
        abstract: true,
        title: "Styleguide",
        templateUrl: "app/styleguide/styleguide-parent.html",
        controller: "AppController",
      })
      .state("styleguide.home", {
        url: "/styleguide/home",
        title: "SG-Home",
        templateUrl: "app/styleguide/00_home/home.html",
        controller: "AppController",
      })
      .state("styleguide.colors", {
        url: "/styleguide/colors",
        title: "SG-Colors",
        templateUrl: "app/styleguide/01_color-palette/color-palette.html",
        controller: "ColorPaletteCtrl",
      })
      .state("styleguide.typography", {
        url: "/styleguide/typography",
        title: "SG-Typography",
        templateUrl: "app/styleguide/005_typography/typography.html",
        controller: "TypographyCtrl",
      })
      .state("styleguide.buttons", {
        url: "/styleguide/buttons",
        title: "SG-Buttons",
        templateUrl: "app/styleguide/010_buttons/buttons.html",
        controller: "AppController",
      })
      .state("styleguide.layout", {
        url: "/styleguide/layout",
        title: "SG-Layout",
        templateUrl: "app/styleguide/015_layout/layout.html",
        controller: "LayoutCtrl",
      })
      .state("styleguide.forms", {
        url: "/styleguide/forms",
        title: "SG-Forms",
        templateUrl: "app/styleguide/020_forms/forms.html",
        controller: "FormsCtrl",
      })
      .state("styleguide.notification", {
        url: "/styleguide/notification",
        title: "SG-Notification",
        templateUrl: "app/styleguide/023_notification/notification.html",
        controller: "NotificationCtrl",
      })
      .state("styleguide.icons", {
        url: "/styleguide/icons",
        title: "SG-Icons",
        templateUrl: "app/styleguide/025_icons/icons.html",
        controller: "IconsCtrl",
      })
      .state("styleguide.components", {
        url: "/styleguide/components",
        title: "SG-Components",
        templateUrl: "app/styleguide/030_components/components.html",
        controller: "AppController",
      })
      .state("styleguide.directives", {
        url: "/styleguide/directives",
        title: "SG-Directives",
        templateUrl: "app/styleguide/035_directives/directives.html",
        controller: "DirectivesCtrl",
      })
      .state("styleguide.cssUtility", {
        url: "/styleguide/css-utility-classes",
        title: "SG-CSS Utlity Classes",
        templateUrl: "app/styleguide/040_css-utilities/css-utilities.html",
        controller: "AppController",
      });

    // ===========================
    // Application Routes
    // ===========================

    // User-facing routes for the application

    $stateProvider
      .state("app", {
        url: "/app",
        abstract: true,
        templateUrl: "app/common/app.html",
        controller: "AppController",
        resolve: resolveFor(
          "fastclick",
          "modernizr",
          "icons",
          "animo",
          "sparklines",
          "slimscroll",
          "classyloader",
          "csspiner",
          "checklist-model"
        ),
      })

      // ===========================
      // Dealer Routes
      // ===========================

      .state("app.dealer", {
        abstract: true,
        url: "/dealers/:dealer_id",
        title: "Dealer View",
        template: "<div ui-view></div>",
        resolve: {
          init: function ($stateParams, UserService, DealerService, $state) {
            return UserService.setDealerInfo(
              new DealerService({ dealer_id: $stateParams.dealer_id })
            )
              .then(
                function () {},
                function () {
                  $state.go("page.404");
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
          dealer_id: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.dealer_id;
            },
          ],
          clearCrumb: function (UserService) {
            UserService.setCustomerInfo(undefined);
            UserService.setControlSystem(undefined);
            UserService.setSite(undefined);
          },
        },
      })
      .state("page.loginEuRedirect", {
        url: "/eulogin",
        title: "EULogin",
        templateUrl: "app/common/pages/login-eula-redirect.html",
        controller: "LoginFormCtrl",
      })

      .state("app.dealer.systemDiagnostics", {
        url: "/system-diagnostics/:panelId?",
        title: "System Diagnostics",
        templateUrl: "app/system-diagnostics/system-diagnostics.html",
        resolve: {
          criteria: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.criteria;
            },
          ],
          parameter: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.parameter;
            },
          ],
        },
      })

      .state("app.dealer.password_change", {
        url: "/password_change",
        title: "Change Password",
        templateUrl: "app/common/pages/password-change.html",
        controller: "PasswordChangeCtrl",
      })

      .state("app.dealer.search", {
        url: "/search/:criteria/:parameter",
        title: "Search",
        templateUrl: "app/search/search-main.html",
        controller: "SearchPageCtrl",
        resolve: {
          criteria: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.criteria;
            },
          ],
          parameter: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.parameter;
            },
          ],
        },
        // route needs search params
      })

      .state("app.dealer.dealers", {
        url: "/dealers",
        title: "Dealer List",
        templateUrl: "app/dealer/parent/parent-dealer.html",
        controller: "ParentDealerCtrl",
      })

      .state("app.dealer.dashboard", {
        url: "/dashboard",
        title: "Welcome to Dealer Admin Site",
        views: {
          "": {
            templateUrl: "app/dashboard/dashboard.html",
            controller: "DashboardCtrl",
          },
          "search@app.dealer.dashboard": {
            templateUrl: "app/search/search-main.html",
            controller: "SearchPageCtrl",
          },
        },
        resolve: {
          parameter: function () {
            return "";
          },
          criteria: function () {
            return "Customers";
          },
        },
      })

      .state("app.dealer.customer-engagement", {
        url: "/customer-engagement",
        title: "Customer Engagement",
        templateUrl: "app/dealer/customer-engagement/customer-engagement.html",
        controller: "CustomerEngagementCtrl",
      })

      // ---------------------------
      // Dealer Reports and Analytics
      // ---------------------------

      .state("app.dealer.camera-status-report", {
        url: "/camera-status-report/:report_type",
        title: "Camera Info",
        templateUrl: "app/dealer-reports/camera-status-report.html",
        controller: "CameraStatusReportCtrl",
      })

      .state("app.dealer.systems-without-apps", {
        url: "/systems-without-apps",
        title: "Camera Info",
        templateUrl: "app/dealer-reports/systems-without-apps.html",
        controller: "SystemsWithoutAppsCtrl",
      })

      .state("app.dealer.auto-programming-errors", {
        url: "/auto-programming-errors",
        title: "auto programming errors",
        templateUrl: "app/dealer-reports/auto-programming-errors.html",
        controller: "AutoProgrammingErrorsCtrl",
      })

      .state("app.dealer.systems-with-arming-app", {
        url: "/systems-with-arming-app",
        title: "Camera Info",
        templateUrl: "app/dealer-reports/systems-with-arming-app.html",
        controller: "SystemsWithArmingAppCtrl",
      })

      .state("app.dealer.dealer-reports", {
        url: "/dealer-reports",
        title: "Reporting & Analytics",
        templateUrl: "app/dealer-reports/dealer-reports-main.html",
        controller: "DealerReportsCtrl",
      })

      .state("app.dealer.business-health-report", {
        url: "/dealer-dashboard",
        title: "Dealer Dashboard",
        templateUrl: "app/dealer-dashboard/business-health-report.html",
        controller: "BusinessHealthReportCtrl",
      })

      .state("app.dealer.dmp-health-report", {
        url: "/dmp-analytics",
        title: "DMP Analytics",
        templateUrl: "app/dmp-business-health-report/dmp-health-report.html",
        controller: "DmpHealthReportCtrl",
      })

      .state("app.dealer.dealer-invoices-download", {
        url: "/invoices",
        title: "Invoices Download",
        templateUrl: "app/billing/invoice-download.html",
        controller: "InvoiceDownloadCtrl",
      })

      .state("app.dealer.reports_management", {
        url: "/reports_management",
        title: "Reports Management Dashboard",
        templateUrl: "app/dealer/tools/reports-management.html",
        controller: "ReportsManagementCtrl",
      })

      .state("app.dealer.modem-reports", {
        url: "/modem-reports",
        title: "Modem Reports",
        templateUrl: "app/dealer-reports/modem-reports.html",
        controller: "ModemReportsCtrl",
      })

      // ---------------------------
      // Reports - Quick Reports
      // ---------------------------

      .state("app.dealer.all-app-users", {
        url: "/all-app-users",
        title: "All App Users",
        templateUrl: "app/dealer-reports/all-app-users.html",
        controller: "AllAppUsersCtrl",
      })

      .state("app.dealer.low-cell-signal-users", {
        url: "/low-cell-signal-users",
        title: "Low Cell Signal Users",
        templateUrl: "app/dealer-reports/low-cell-signal-users.html",
        controller: "LowCellSignalUsersCtrl",
      })

      .state("app.dealer.non-lte-users", {
        url: "/non-lte-users",
        title: "Non Lte Users",
        templateUrl: "app/dealer-reports/non-lte-users.html",
        controller: "NonLteUsersCtrl",
      })

      .state("app.dealer.systems-without-app-use-for-month", {
        url: "/systems-without-app-use-for-month",
        title: "Systems Without App Use For Month",
        templateUrl:
          "app/dealer-reports/systems-without-app-use-for-month.html",
        controller: "SystemsWithoutAppUseCtrl",
      })

      .state("app.dealer.system-added-today", {
        url: "/system-added-today",
        title: "System Added Today",
        templateUrl: "app/dealer-reports/system-added-today.html",
        controller: "SystemAddedTodayCtrl",
      })

      .state("app.dealer.cell-changes-today", {
        url: "/cell-changes-today",
        title: "Cell Changes Today",
        templateUrl: "app/dealer-reports/cell-changes-today.html",
        controller: "CellChangesTodayCtrl",
      })

      .state("app.dealer.installs-by-salespersons", {
        url: "/installs-by-salesperson",
        title: "Installs by SalesPerson",
        templateUrl:
          "app/dealer-reports/installation-reports/installs-by-salespersons/installs-by-salespersons.html",
        controller: "InstallsBySalespersonsCtrl",
      })

      .state("app.dealer.installs-by-installers", {
        url: "/installs-by-installers",
        title: "Installs by Installers",
        templateUrl:
          "app/dealer-reports/installation-reports/installs-by-installers/installs-by-installers.html",
        controller: "InstallsByInstallersCtrl",
      })

      .state("app.dealer.installs-by-type", {
        url: "/installs-by-type",
        title: "Installs by Installers",
        templateUrl:
          "app/dealer-reports/installation-reports/installs-by-type/installs-by-type.html",
        controller: "InstallsByTypeCtrl",
      })

      .state("app.dealer.installs-by-date", {
        url: "/installs-by-date",
        title: "Installs by Date",
        templateUrl:
          "app/dealer-reports/installation-reports/installs-by-date/installs-by-date.html",
        controller: "InstallsByDateCtrl",
      })

      .state("app.dealer.customer_list", {
        url: "/customers",
        title: "Customer List",
        templateUrl: "app/dealer-reports/customers/dealer-customers.html",
        controller: "DealerCustomers",
        controllerAs: "customer_list",
      })

      .state("app.dealer.custom_report_view", {
        url: "/custom-report-view",
        title: "Custom Report",
        templateUrl: "app/dealer-reports/custom-report-view.html",
        controller: "CustomReportCtrl",
      })

      .state("app.dealer.systems-with-late-to-open", {
        url: "/systems-with-late-to-open",
        title: "Late to Open Report",
        templateUrl: "app/dealer-reports/systems-with-late-to-open.html",
        controller: "SystemsWithLateToOpenCtrl",
      })

      .state("app.dealer.systems-with-early-to-close", {
        url: "/systems-with-early-to-close",
        title: "Early to Close Report",
        templateUrl: "app/dealer-reports/systems-with-early-to-close.html",
        controller: "SystemsWithEarlyToClose",
      })

      .state("app.dealer.systems-with-lockdown", {
        url: "/systems-with-lockdown",
        title: "Lockdown Report",
        templateUrl: "app/dealer-reports/systems-with-lockdown.html",
        controller: "SystemsWithLockdown",
      })
      .state("app.dealer.systems-with-bypassed-zone", {
        url: "/systems-with-bypassed-zone",
        title: "Bypassed Zone Report",
        templateUrl: "app/dealer-reports/systems-with-bypassed-zone.html",
        controller: "SystemsWithByPassedZones",
      })
      .state("app.dealer.fire-inspection-due", {
        url: "/fire-inspection-due",
        title: "Fire Inspection Due",
        templateUrl: "app/dealer-reports/fire-inspection-due.html",
        controller: "FireInspectionDue",
      })

      // ---------------------------
      // Audits
      // ---------------------------

      .state("app.dealer.app_user_audit", {
        url: "/app_user_audit",
        title: "app user audit",
        templateUrl: "app/dealer-reports/dealer-audit.html",
      })

      .state("app.dealer.personnel_audit", {
        url: "/personnel_audit",
        title: "personnel audit",
        templateUrl: "app/dealer-reports/dealer-audit.html",
      })

      .state("app.dealer.systems_audit", {
        url: "/systems_audit",
        title: "systems audit",
        templateUrl: "app/dealer-reports/dealer-audit.html",
      })

      /**
       * Audit logs of a single system of the dealer for showing the full report
       */
      .state("app.dealer.single_system_audit", {
        url: "/control_system/:control_system_id/single_system_audit",
        title: "single system audit",
        templateUrl: "app/dealer-reports/dealer-single-system-audit.html",
        resolve: {
          control_system_id: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.control_system_id;
            },
          ],
        },
      })

      /**
       * Audit logs of a x1 site of the dealer for showing the full report
       */
      .state("app.dealer.site_audit", {
        url: "/sites/:site_id/site_audit",
        title: "X1 Site audit",
        templateUrl: "app/dealer-reports/dealer-site-audit.html",
        resolve: {
          site_id: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.site_id;
            },
          ],
        },
      })

      /**
       * Audit logs of a single person of the dealer for showing the full report
       */
      .state("app.dealer.single_person_audit", {
        url: "/control_system/:user_id/single_person_audit",
        title: "single person audit",
        templateUrl: "app/dealer-reports/dealer-single-person-audit.html",
        resolve: {
          user_id: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.user_id;
            },
          ],
        },
      })

      /**
       * Audit logs of all activity for the dealer
       */
      .state("app.dealer.activity_audit", {
        url: "/activity_audit",
        title: "activity audit",
        templateUrl: "app/dealer-reports/dealer-audit.html",
      })

      .state("app.dealer.remote_update_tools", {
        url: "/remote_update_tools/:active_tab/:data_amount",
        title: "Remote Update",
        templateUrl: "app/remote-update/bulk-remote-update.html",
        controller: "BulkRemoteUpdateCtrl",
        controllerAs: "RemoteUpdateDash",
        resolve: {
          activeTab: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.active_tab;
            },
          ],
          init: function ($stateParams, UserService) {
            UserService.customer_id = $stateParams.customer_id;
            UserService.control_system_id = $stateParams.control_system_id;
          },
        },
      })

      // ---------------------------
      // Dealer Settings
      // ---------------------------

      .state("app.dealer.settings-new", {
        url: "/settings-new",
        title: "Create New Dealer",
        templateUrl: "app/dealer-settings/dealer-settings.html",
        controller: "DealerSettingsCtrl",
      })
      .state("app.dealer.settings-view", {
        url: "/settings",
        title: "Dealer Settings",
        templateUrl: "app/dealer-settings/dealer-settings.html",
        controller: "DealerSettingsCtrl",
      })
      .state("app.dealer.settings-edit", {
        url: "/settings/edit",
        title: "Dealer Settings",
        templateUrl: "app/dealer-settings/dealer-settings.html",
        controller: "DealerSettingsCtrl",
      })

      // ---------------------------
      // Dealer Personnel
      // ---------------------------

      .state("app.dealer.personnel", {
        url: "/personnel",
        title: "Personnel",
        templateUrl: "app/personnel/personnel-list.html",
        controller: "PersonnelListCtrl",
      })

      .state("app.dealer.personnel_new", {
        url: "/personnel/new",
        title: "Add New Personnel",
        templateUrl: "app/personnel/personnel-edit.html",
        controller: "PersonnelEditCtrl",
        resolve: {
          user_id: [
            "$stateParams",
            function () {
              return "";
            },
          ],
        },
      })

      .state("app.dealer.personnel_edit", {
        url: "/personnel/:user_id/edit",
        title: "Personnel",
        templateUrl: "app/personnel/personnel-edit.html",
        controller: "PersonnelEditCtrl",
        resolve: {
          user_id: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.user_id;
            },
          ],
        },
      })

      .state("app.dealer.personnel_custom_roles_list", {
        url: "/personnel/custom-roles",
        title: "Roles",
        templateUrl: "app/personnel/custom-roles/custom-roles-list.html",
        controller: "CustomRolesListCtrl",
      })

      .state("app.dealer.downloads", {
        url: "/downloads",
        title: "Downloads",
        templateUrl: "app/downloads/downloads.html",
      })

      .state("app.dealer.personnel_custom_roles_new", {
        url: "/personnel/custom-roles/new",
        title: "Add New Role",
        templateUrl: "app/personnel/custom-roles/custom-roles-edit.html",
        controller: "CustomRolesEditCtrl",
        resolve: {
          role_id: [
            "$stateParams",
            function () {
              return "";
            },
          ],
        },
      })

      .state("app.dealer.personnel_custom_roles_edit", {
        url: "/personnel/custom-roles/:role_id/edit",
        title: "Edit Role",
        templateUrl: "app/personnel/custom-roles/custom-roles-edit.html",
        controller: "CustomRolesEditCtrl",
        resolve: {
          role_id: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.role_id;
            },
          ],
        },
      })

      .state("app.dealer.tags-settings", {
        url: "/tags-settings",
        title: "Tags Settings",
        templateUrl: "app/dealer/tags-settings/tags-settings.html",
      })

      .state("app.dealer.tag-edit", {
        url: "/tag/:tag_id/tag-edit",
        title: "Edit Tag",
        templateUrl: "app/dealer/tags-settings/tag-edit.html",
        resolve: {
          tag_id: ["$stateParams", ($stateParams) => $stateParams.tag_id],
        },
      })

      .state("app.dealer.tag-create", {
        url: "/tag/:tag_id/tag-create",
        title: "Create Tag",
        templateUrl: "app/dealer/tags-settings/tag-edit.html",
        resolve: {
          tag_id: ["$stateParams", ($stateParams) => $stateParams.tag_id],
        },
      })

      .state("app.dealer.central_station", {
        url: "/central-station",
        title: "Monitoring Center",
        templateUrl: "app/dealer/central-station/central-station.html",
      })

      // ---------------------------
      // Company Store
      // ---------------------------

      .state("app.dealer.store_dashboard", {
        url: "/store-dashboard",
        title: "Company Store Dashboard",
        templateUrl: "app/store-settings/dashboard/store-dashboard.html",
        //TODO: Controller will need to be updated at a later time.
        controller: "StoreDashboardCtrl",
      })

      .state("app.dealer.store_view", {
        url: "/store",
        title: "Company Store - View",
        templateUrl: "app/store-settings/costore-secura-settings.html",
        controller: "StoreCtrl",
        resolve: {
          stripe_success: [
            "$stateParams",
            function ($stateParams) {
              return "";
            },
          ],
        },
      })

      .state("app.dealer.store_edit", {
        url: "/store/edit?stripe_success",
        title: "Company Store - Edit",
        templateUrl: "app/store-settings/costore-secura-settings.html",
        controller: "StoreCtrl",
        resolve: {
          stripe_success: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.stripe_success;
            },
          ],
        },
      })

      // ---------------------------
      // Sales App Settings
      // ---------------------------

      .state("app.dealer.salesapp_view", {
        url: "/salesapp",
        title: "Sales App - View",
        templateUrl: "app/store-settings/costore-secura-settings.html",
        controller: "StoreCtrl",
        resolve: {
          stripe_success: [
            "$stateParams",
            function ($stateParams) {
              return "";
            },
          ],
        },
      })

      .state("app.dealer.salesapp_edit", {
        url: "/salesapp/edit?stripe_success",
        title: "Sales App - Edit",
        templateUrl: "app/store-settings/costore-secura-settings.html",
        controller: "StoreCtrl",
        resolve: {
          stripe_success: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.stripe_success;
            },
          ],
        },
      })

      // ---------------------------
      // Templates
      // ---------------------------

      .state("app.dealer.default_programming", {
        url: "/defaults",
        title: "Templates - List",
        templateUrl: "app/dealer/default-programming/defaults-list.html",
        controller: "DefaultProgrammingListCtrl",
      })

      .state("app.dealer.default_programming_new", {
        url: "/defaults/new",
        title: "Templates - Edit",
        templateUrl: "app/dealer/default-programming/defaults-edit.html",
        controller: "DefaultProgrammingEditCtrl",
      })

      // ---------------------------
      // Templates - NEW (in React)
      // ---------------------------

      .state("app.dealer.new_template", {
        url: "/new-template",
        title: "New Template",
        templateUrl: "app/templates/templates.html",
      })

      // ---------------------------
      // Templates - NEW (in React)
      // ---------------------------

      .state("app.dealer.dealer_activity", {
        url: "/dealer-activity",
        title: "Dealer Activity",
        templateUrl: "app/dealer-activity/dealer-activity.html",
      })

      .state("app.dealer.template_edit", {
        url: "/template/:id/:hardware_model/edit",
        title: "Templates - Edit",
        templateUrl: "app/templates/templates.html",
      })

      // ---------------------------
      // Mass Programming
      // ---------------------------

      .state("app.dealer.mass_programming", {
        url: "/mass-programming/",
        title: "Mass Programming",
        templateUrl:
          "app/dealer/mass-programming/mass-programming-dashboard.html",
        controller: "MassProgrammingDashboardCtrl",
      })

      .state("app.dealer.mass_programming_new", {
        url: "/mass-programming/new",
        title: "Mass Programming New",
        templateUrl: "app/dealer/mass-programming/mass-programming-new.html",
        controller: "MassProgrammingCtrl",
      })

      .state("app.dealer.mass_programming_view", {
        url: "/mass-programming/:job_id/view",
        title: "Mass Programming View",
        templateUrl: "app/dealer/mass-programming/mass-programming-view.html",
        controller: "MassProgrammingCtrl",
      })

      .state("app.dealer.mass_programming_global_holiday_dates_job_new", {
        url: "/mass-programming/global-holiday-dates/new",
        title: "Mass Programming Global Holiday Dates New",
        templateUrl:
          "app/dealer/mass-programming/mass-programming-global-holiday-dates-job-new.html",
      })

      .state("app.dealer.massProgrammingGlobalHolidayDatesView", {
        url: "/mass-programming/global-holiday-dates/:job_id/view",
        title: "Mass Programming Global Holiday Date View",
        templateUrl:
          "app/dealer/mass-programming/mass-programming-global-holiday-dates-view.html",
        controller: "MassProgrammingCtrl",
      })

      // ---------------------------
      // Service Request
      // ---------------------------

      .state("app.dealer.service-request-dashboard", {
        url: "/service-requests",
        title: "Service Requests",
        templateUrl:
          "app/dealer/service-request/service-request-dashboard.html",
        controller: "ServiceRequestDashboardCtrl",
      })

      // ---------------------------
      // On Demand Settings
      // ---------------------------

      .state("app.dealer.ondemand_settings_view", {
        url: "/monitoring/ondemand",
        title: "On-Demand Settings",
        templateUrl: "app/store-settings/on-demand/on-demand-settings.html",
        controller: "OnDemandSettingsCtrl",
        resolve: {
          stripe_success: [
            "$stateParams",
            function ($stateParams) {
              return "";
            },
          ],
        },
      })

      .state("app.dealer.ondemand_settings_edit", {
        url: "/monitoring/ondemand/edit?stripe_success",
        title: "On-Demand Settings - Edit",
        templateUrl: "app/store-settings/on-demand/on-demand-settings.html",
        controller: "OnDemandSettingsCtrl",
        resolve: {
          stripe_success: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.stripe_success;
            },
          ],
        },
      })

      // ---------------------------
      // Track My Tech
      // ---------------------------

      .state("app.dealer.track_technician", {
        url: "/track-my-tech",
        title: "Track my Tech",
        templateUrl: "app/tech-track/tech-track.html",
        controller: "TechTrackCtrl",
      })

      // ---------------------------
      // Customer Referrals
      // ---------------------------

      .state("app.dealer.customerreferrals", {
        url: "/customer-referrals",
        title: "Customer Referrals Program",
        templateUrl:
          "app/dealer/customer-referrals/customer-referrals-view.html",
        controller: "CustomerReferralsCtrl",
      })

      .state("app.dealer.integration_settings_view", {
        url: "/integrations",
        title: "3rd Party Integrations",
        templateUrl: "app/integration-settings/integration-settings.html",
        controller: "IntegrationSettingsCtrl",
      })

      .state("app.dealer.integration_settings_edit", {
        url: "/integrations/edit",
        title: "3rd Party Integrations - Edit",
        templateUrl: "app/integration-settings/integration-settings.html",
        controller: "IntegrationSettingsCtrl",
      })

      // ===========================
      // Global Holiday Dates
      // ===========================
      .state("app.dealer.globalHolidayDates", {
        url: "/global-holiday-dates?active_tab",
        title: "Global Holiday Dates",
        templateUrl:
          "app/dealer/global-holiday-dates/global-holiday-dates.html",
        resolve: {
          activeTab: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.active_tab;
            },
          ],
        },
      })

      .state("app.dealer.globalHolidayDatesJobNew", {
        url: "/global-holiday-dates/new",
        title: "Send Global Holiday Date",
        templateUrl:
          "app/dealer/mass-programming/mass-programming-global-holiday-dates-job-new.html",
      })

      .state("app.dealer.globalHolidayDatesViewSingleJob", {
        url: "/global-holiday-dates/:job_id/view",
        title: "Global Holiday Date Job View",
        templateUrl:
          "app/dealer/mass-programming/mass-programming-global-holiday-dates-view.html",
        controller: "MassProgrammingCtrl",
      })

      // ===========================
      // Mobile Credentials
      // ===========================
      .state("app.dealer.mobileCredentials", {
        url: "/configure-mobile-credentials",
        title: "Configure Mobile Credentials",
        templateUrl: "app/mobile-credentials/mobile-credentials.html",
      })

      // ===========================
      // Accounting Reports
      // ===========================

      .state("app.accounting-reports", {
        url: "/accounting-reports",
        title: "Accounting Reports",
        templateUrl: "app/accounting-reports/accounting-reports.html",
        controller: "AccountingReportsCtrl as ctrl",
      })

      // ===========================
      // Competitor Upgrade Audit
      // ===========================

      .state("app.competitorupgrade", {
        url: "/competitor-upgrade-audit",
        title: "Audit Competitor Upgrade",
        templateUrl:
          "app/competitor-upgrade-audit/competitor-upgrade-audit.html",
        controller: "CompetitorUpgradeAuditCtrl",
      })

      // ===========================
      // News Items
      // ===========================

      .state("app.newsitems", {
        url: "/news-items",
        title: "News Items",
        templateUrl: "app/dashboard/news_items/news-items.html",
        controller: "NewsItemsCtrl",
      })

      // ===========================
      // Customers
      // ===========================

      .state("app.customers", {
        abstract: true,
        url: "/dealers/:dealer_id/customers/{customer_id:\\d*}",
        template: "<div ui-view></div>",
        resolve: {
          dealer_id: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.dealer_id;
            },
          ],
          customer_id: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.customer_id;
            },
          ],
          init: function ($stateParams, UserService, DealerService, $state) {
            return UserService.setDealerInfo(
              new DealerService({
                dealer_id: $stateParams.dealer_id,
                customer_id: $stateParams.customer_id,
              })
            )
              .then(
                function () {
                  UserService.setControlSystem(undefined);
                  UserService.setSite(undefined);
                  UserService.customer_id = $stateParams.customer_id;
                },
                function (error) {
                  console.error(
                    "Error Getting Dealer Info: " + angular.toJson(error)
                  );
                  $state.go("page.404");
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
        },
      })

      .state("app.customers.new", {
        url: "new",
        title: "New Customer",
        templateUrl: "app/customer/customer-edit.html",
        controller: "CustomerEditCtrl as custedit",
      })

      .state("app.customers.customersummary", {
        url: "",
        title: "Customer Summary",
        templateUrl: "app/customer/customer-summary.html",
        controller: "CustomerSummaryCtrl as summary",
      })

      .state("app.customers.customeredit", {
        url: "/edit",
        title: "Edit Customer",
        templateUrl: "app/customer/customer-edit.html",
        controller: "CustomerEditCtrl as custedit",
      })

      // ===========================
      // New Users
      // ===========================

      /**
       * This route is intentionally placed BEFORE app.user, so ui-router doesn't see us as /users/new, with :user_id = 'new'
       */
      .state("app.newuser", {
        url: "/customers/:customer_id/users/new",
        title: "App User New",
        templateUrl: "app/users/user-edit.html",
        controller: "UserEditCtrl as newedituser",
        resolve: {
          // The user_id must be resolved, even though this state won't use it, since we are sharing a controller with User Edit
          user_id: [
            function () {
              return "";
            },
          ],
          customer_id: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.customer_id;
            },
          ],
          init: function ($stateParams, UserService, DealerService, $state) {
            return UserService.setDealerInfo(
              new DealerService({ customer_id: $stateParams.customer_id })
            )
              .then(
                function () {
                  UserService.customer_id = $stateParams.customer_id;
                },
                function (error) {
                  console.error(
                    "Error Getting Dealer Info: " + angular.toJson(error)
                  );
                  $state.go("page.404");
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
        },
      })

      // ===========================
      // Users
      // ===========================

      .state("app.user", {
        abstract: true,
        url: "/customers/:customer_id/users/{user_id:\\d*}",
        title: "App User View",
        template: "<div ui-view></div>",
        resolve: {
          user_id: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.user_id;
            },
          ],
          customer_id: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.customer_id;
            },
          ],
          init: function ($stateParams, UserService, DealerService, $state) {
            return UserService.setDealerInfo(
              new DealerService({ customer_id: $stateParams.customer_id })
            )
              .then(
                function () {
                  UserService.customer_id = $stateParams.customer_id;
                },
                function (error) {
                  console.error(
                    "Error Getting Dealer Info: " + angular.toJson(error)
                  );
                  $state.go("page.404");
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
        },
      })

      .state("app.user.viewuser", {
        url: "",
        title: "App User View",
        templateUrl: "app/users/user-view.html",
        controller: "UserEditCtrl as newedituser",
      })

      .state("app.user.edituser", {
        url: "/edit",
        title: "App User Edit",
        templateUrl: "app/users/user-edit.html",
        controller: "UserEditCtrl as newedituser",
      })

      // ===========================
      // Control System New
      // ===========================

      .state("app.control_system_new", {
        // Controller expects control_system_id to be on $stateParams
        // for the new route this MUST be set to 'new'
        url: "/customers/:customer_id/control_systems/new",
        title: "System Information",
        templateUrl: "app/control_system/control-system-edit.html",
        controller: "ControlSystemCtrl",
        resolve: {
          init: function (
            $stateParams,
            UserService,
            DealerService,
            ControlSystemsService,
            $state
          ) {
            $stateParams.control_system_id = "new";

            return ControlSystemsService.getControlSystem(
              $stateParams.control_system_id,
              $stateParams.customer_id
            )
              .then(
                function () {},
                function (error) {
                  console.error(
                    "Control System New: failed to load route",
                    angular.toJson(error)
                  );
                  $state.go("page.404");
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
        },
      })

      // ===========================
      // Sites
      // ===========================

      .state("app.sites", {
        abstract: true,
        url: "/customers/:customer_id/sites/:site_id",
        title: "Site View",
        template: "<div ui-view ></div>",

        resolve: {
          customer_id: [
            "$stateParams",
            ($stateParams) => $stateParams.customer_id,
          ],
          site_id: ["$stateParams", ($stateParams) => $stateParams.site_id],
          init: (
            $stateParams,
            UserService,
            DealerService,
            RelayService,
            Customer,
            $rootScope,
            RecentActivityService,
            $state
          ) => {
            return RelayService.query(
              graphql`
                query configAppSitesSiteQuery($siteId: ID!) {
                  node(id: $siteId) {
                    ... on Site {
                      id
                      name
                      billingControlSystemId
                    }
                  }
                }
              `,
              {
                siteId: toSiteId(
                  $stateParams.customer_id,
                  $stateParams.site_id
                ),
              }
            )
              .toPromise()
              .then(
                async (data) => {
                  if (data.node?.id) {
                    UserService.setSite(data.node);
                    UserService.customer_id = $stateParams.customer_id;
                    UserService.control_system_id =
                      data.node.billingControlSystemId;
                    await UserService.setCustomerInfo(
                      new Customer($stateParams.customer_id)
                    );
                    await UserService.setDealerInfo(
                      new DealerService({
                        customer_id: $stateParams.customer_id,
                      })
                    );
                  } else {
                    $rootScope.alerts.push({
                      type: "error",
                      text: "Error navigating to System",
                    });
                    RecentActivityService.removeSystem(
                      $stateParams.site_id,
                      true
                    );
                    $state.go("app.dealer.dashboard", {
                      dealer_id: UserService.dealer_id,
                    });
                  }
                },
                () => {
                  $rootScope.alerts.push({
                    type: "error",
                    text: "Error navigating to System",
                  });
                  RecentActivityService.removeSystem(
                    $stateParams.site_id,
                    true
                  );
                  $state.go("app.dealer.dashboard", {
                    dealer_id: UserService.dealer_id,
                  });
                }
              );
          },
        },
      })
      .state("app.sites.edit", {
        url: "/",
        title: "Site Information",
        templateUrl: "app/control_system/site-edit.html",
        controller: "SiteCtrl",
      })
      .state("app.sites.diagnostics", {
        url: "/diagnostics",
        title: "Site Diagnostics",
        templateUrl: "app/system-diagnostics-x1/system-diagnostics-x1.html",
      })

      // ===========================
      // Control System Edit
      // ===========================

      .state("app.control_system_edit", {
        url: "/customers/:customer_id/control_systems/:control_system_id/edit/:hardware_model/:simNumber",
        title: "System Information",
        templateUrl: "app/control_system/control-system-edit.html",
        controller: "ControlSystemCtrl",
        resolve: {
          init: function (
            $stateParams,
            UserService,
            DealerService,
            ControlSystemsService,
            $state,
            $rootScope,
            RecentActivityService
          ) {
            return ControlSystemsService.getControlSystem(
              $stateParams.control_system_id,
              $stateParams.customer_id
            )
              .then(
                function () {
                  UserService.hardware_model = $stateParams.hardware_model;
                },
                function (error) {
                  $rootScope.alerts.push({
                    type: "error",
                    text: "Error navigating to System",
                  });
                  RecentActivityService.removeSystem(
                    $stateParams.control_system_id,
                    false
                  );
                  $state.go("app.dealer.dashboard", {
                    dealer_id: UserService.dealer_id,
                  });
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
        },
      })

      // ===========================
      // Control System
      // ===========================

      .state("app.control_system", {
        url: "/customers/:customer_id/control_systems/:control_system_id",
        title: "System Information",
        views: {
          "": {
            templateUrl: "app/control_system/nested-state-tpl.html",
            controller: "NestedStateViewCtrl",
          },
          "control_system@app.control_system": {
            templateUrl: "app/control_system/control-system-view.html",
            controller: "ControlSystemViewCtrl",
          },
          "video_devices@app.control_system": {
            templateUrl:
              "app/control_system/video_devices/video-control-system-page-view.html",
            controller: "CSVideoDevicesCtrl",
          },
          "tech_notes@app.control_system": {
            templateUrl: "app/control_system/tech-notes/tech-notes-tpl.html",
            controller: "TechNotesCtrl",
          },
          "system_images@app.control_system": {
            templateUrl:
              "app/control_system/system-images/system-image-display.html",
            controller: "SystemImageCtrl",
          },
          "programming_archive@app.control_system": {
            templateUrl:
              "app/control_system/programming-archive/panel-prog-archive-tpl.html",
            controller: "PanelProgArchiveCtrl",
          },
          "nearestTechs@app.control_system": {
            templateUrl: "app/control_system/nearest-tech/nearest-tech.html",
            controller: "NearestTechController",
          },
        },
        resolve: {
          init: function (
            $stateParams,
            UserService,
            DealerService,
            ControlSystemsService,
            $state,
            $rootScope,
            RecentActivityService
          ) {
            return ControlSystemsService.showControlSystem(
              $stateParams.control_system_id,
              $stateParams.customer_id
            )
              .then(
                function () {
                  UserService.customer_id = $stateParams.customer_id;
                  UserService.control_system_id =
                    $stateParams.control_system_id;
                },
                function (error) {
                  console.error(
                    "Error Getting Control System: " + angular.toJson(error)
                  );
                  $rootScope.alerts.push({
                    type: "error",
                    text: "Error navigating to Control System",
                  });
                  RecentActivityService.removeSystem(
                    $stateParams.control_system_id,
                    false
                  );
                  $state.go("app.dealer.dashboard", {
                    dealer_id: UserService.dealer_id,
                  });
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
        },
      })

      .state("app.control_system.panel_statistics", {
        url: "/panelstatistics",
        title: "System Analytics",
        templateUrl: "app/analytics/panelstatistics.html",
        controller: "PanelDataController",
        resolve: angular.extend(
          resolveFor("flot-chart", "flot-chart-plugins"),
          {
            control_system_id: [
              "$stateParams",
              function ($stateParams) {
                return $stateParams.control_system_id;
              },
            ],
            customer_id: [
              "$stateParams",
              function ($stateParams) {
                return $stateParams.customer_id;
              },
            ],
            init: function (
              $stateParams,
              UserService,
              DealerService,
              ControlSystemsService,
              $state
            ) {
              return ControlSystemsService.showControlSystem(
                $stateParams.control_system_id,
                $stateParams.customer_id
              )
                .then(
                  function () {
                    UserService.customer_id = $stateParams.customer_id;
                    UserService.control_system_id =
                      $stateParams.control_system_id;
                  },
                  function (error) {
                    console.error(
                      "Error Getting Control System: " + angular.toJson(error)
                    );
                    $state.go("page.404");
                  }
                )
                .catch(function (error) {
                  console.error(error);
                });
            },
          }
        ),
      })

      .state("app.control_system.site_panel_statistics", {
        url: "/sites/:site_id/site_panelstatistics",
        title: "System Analytics",
        templateUrl: "app/analytics/panelstatistics.html",
        controller: "PanelDataController",
        resolve: angular.extend(
          resolveFor("flot-chart", "flot-chart-plugins"),
          {
            control_system_id: [
              "$stateParams",
              function ($stateParams) {
                return $stateParams.control_system_id;
              },
            ],
            customer_id: [
              "$stateParams",
              function ($stateParams) {
                return $stateParams.customer_id;
              },
            ],
            site_id: [
              "$stateParams",
              function ($stateParams) {
                return $stateParams.site_id;
              },
            ],
            init: function (
              $stateParams,
              UserService,
              ControlSystemsService,
              $state,
              RelayService
            ) {
              return Promise.all([
                ControlSystemsService.showControlSystem(
                  $stateParams.control_system_id,
                  $stateParams.customer_id
                ),
                RelayService.query(
                  graphql`
                    query configAppControlSystemsSitePanelStatisticsSiteQuery(
                      $siteId: ID!
                    ) {
                      site(id: $siteId) {
                        id
                        name
                      }
                    }
                  `,
                  {
                    siteId: toSiteId(
                      $stateParams.customer_id,
                      $stateParams.site_id
                    ),
                  }
                ).toPromise(),
              ])
                .then(
                  function (results) {
                    UserService.customer_id = $stateParams.customer_id;
                    UserService.control_system_id =
                      $stateParams.control_system_id;
                    UserService.setSite(results[1].site);
                  },
                  function (error) {
                    console.error(
                      "Error Getting Control System: " + angular.toJson(error)
                    );
                    $state.go("page.404");
                  }
                )
                .catch(function (error) {
                  console.error(error);
                });
            },
          }
        ),
      })

      .state("app.control_system.remote_update", {
        url: "/remote_update",
        title: "Remote Update",
        templateUrl: "app/remote-update/remote-update.html",
        controller: "RemoteUpdateCtrl",
        resolve: {
          user_id: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.user_id;
            },
          ],
          control_system_id: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.control_system_id;
            },
          ],
          customer_id: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.customer_id;
            },
          ],
          init: function (
            $stateParams,
            UserService,
            DealerService,
            ControlSystemsService,
            $state
          ) {
            return ControlSystemsService.showControlSystem(
              $stateParams.control_system_id,
              $stateParams.customer_id
            )
              .then(
                function (controlSystem) {
                  UserService.customer_id = $stateParams.customer_id;
                  UserService.control_system_id =
                    $stateParams.control_system_id;
                  UserService.setControlSystem(controlSystem);
                },
                function (error) {
                  $state.go("page.404");
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
        },
      })

      // Z-Wave Automation
      .state("app.control_system.automation", {
        url: "/automation",
        title: "Automation List",
        templateUrl: "app/control_system/automation/automation-list.html",
        controller: "AutomationCtrl",
        resolve: {
          init: function (
            $stateParams,
            UserService,
            DealerService,
            ControlSystemsService,
            $state
          ) {
            return ControlSystemsService.showControlSystem(
              $stateParams.control_system_id,
              $stateParams.customer_id
            )
              .then(
                function () {
                  UserService.customer_id = $stateParams.customer_id;
                  UserService.control_system_id =
                    $stateParams.control_system_id;
                  UserService.panel_id = $stateParams.control_system_id;
                },
                function (error) {
                  console.error(
                    "Error Getting Control System: " + angular.toJson(error)
                  );
                  $state.go("page.404");
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
        },
      })

      .state("app.control_system.edit-camera-mappings", {
        url: "/customers/:customer_id/control_systems/:control_system_id/camera-mappings",
        title: "Camera Mappings",
        templateUrl: "app/video/camera-mapping/camera-mapping.html",
        controller: "CameraMappingCtrl",
        resolve: {
          init: function (
            $stateParams,
            UserService,
            DealerService,
            ControlSystemsService,
            $state
          ) {
            return ControlSystemsService.showControlSystem(
              $stateParams.control_system_id,
              $stateParams.customer_id
            ).then(
              function (controlSystem) {
                UserService.customer_id = $stateParams.customer_id;
                UserService.control_system_id = $stateParams.control_system_id;
                UserService.setControlSystem(controlSystem);
              },
              function (error) {
                console.error(
                  "Error Getting Control System: " + angular.toJson(error)
                );
                $state.go("page.404");
              }
            );
          },
        },
      })

      // ===========================
      // Panel Programming
      // ===========================

      .state("app.panel_programming", {
        url: "/customers/:customer_id/control_systems/:control_system_id/panels/:panel_id/programming",
        title: "Panel Programming",
        templateUrl: "app/programming/progrouter.html",
        controller: "ProgrammingRouterCtrl",
        resolve: {
          customer_id: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.customer_id;
            },
          ],
          control_system_id: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.control_system_id;
            },
          ],
          panel_id: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.panel_id;
            },
          ],
          init: function (
            $stateParams,
            UserService,
            ControlSystemsService,
            $state
          ) {
            return ControlSystemsService.showControlSystem(
              $stateParams.control_system_id,
              $stateParams.customer_id
            )
              .then(
                function () {
                  UserService.customer_id = $stateParams.customer_id;
                  UserService.control_system_id =
                    $stateParams.control_system_id;
                },
                function (error) {
                  console.error(
                    "Error Getting Control System: " + angular.toJson(error)
                  );
                  $state.go("page.404");
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
        },
      })

      .state("app.panel_programming.programming_full", {
        url: "/programming_full",
        title: "Panel Programming",
        templateUrl: "app/programming/progrouter_full.html",
        resolve: resolveFor("panels"),
      })

      .state("app.panel_programming.programming", {
        url: "/programming?:headless",
        title: "Panel Programming",
        templateUrl: "app/programming/full_programming_entry.html",
        props: {
          headless: { squash: true, value: false },
        },
      })

      .state("app.panel_programming.programming_print", {
        url: "/programming-print",
        title: "Panel Programming Printable Output",
        templateUrl: "app/programming/print.html",
      })

      /**
       * Basic Panel Programming (currently for CellCom and iComSL only)
       */
      .state("app.panel_programming.programming_com", {
        url: "/com",
        title: "Panel Programming",
        templateUrl: "app/programming/programming-com.html",
      })

      /**
       * XT Arming Schedule Programming New
       * Must be before other schedule routes so that it doesn't see 'new' as the ID of a schedule.
       */
      .state("app.panel_programming.schedule_new_arming_xt", {
        url: "/schedules/xt/arming/new",
        title: "New Arming Schedule",
        templateUrl: "app/programming/schedules/xt-views/schedule-edit-xt.html",
        controller: "SchedulesXTBaseCtrl",
        resolve: {
          schedule_type: [
            "PANEL_CONCEPTS",
            function (PANEL_CONCEPTS) {
              return PANEL_CONCEPTS.schedules;
            },
          ],
          control_system_id: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.control_system_id;
            },
          ],
          panel_id: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.panel_id;
            },
          ],
          init: function (
            $stateParams,
            UserService,
            ControlSystemsService,
            $state
          ) {
            return ControlSystemsService.showControlSystem(
              $stateParams.control_system_id,
              $stateParams.customer_id
            )
              .then(
                function () {
                  UserService.customer_id = $stateParams.customer_id;
                  UserService.control_system_id =
                    $stateParams.control_system_id;
                },
                function (error) {
                  console.error(
                    "Error Getting Control System: " + angular.toJson(error)
                  );
                  $state.go("page.404");
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
        },
      })

      /**
       * XT Arming Schedule Programming Edit
       */
      .state("app.panel_programming.schedule_edit_arming_xt", {
        url: "/schedules/xt/arming/edit",
        title: "Edit Arming Schedule",
        templateUrl: "app/programming/schedules/xt-views/schedule-edit-xt.html",
        controller: "SchedulesXTBaseCtrl",
        resolve: {
          schedule_type: [
            "PANEL_CONCEPTS",
            function (PANEL_CONCEPTS) {
              return PANEL_CONCEPTS.schedules;
            },
          ],
          control_system_id: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.control_system_id;
            },
          ],
          panel_id: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.panel_id;
            },
          ],
          init: function (
            $stateParams,
            UserService,
            ControlSystemsService,
            $state
          ) {
            return ControlSystemsService.showControlSystem(
              $stateParams.control_system_id,
              $stateParams.customer_id
            )
              .then(
                function () {
                  UserService.customer_id = $stateParams.customer_id;
                  UserService.control_system_id =
                    $stateParams.control_system_id;
                },
                function (error) {
                  console.error(
                    "Error Getting Control System: " + angular.toJson(error)
                  );
                  $state.go("page.404");
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
        },
      })

      /**
       * Schedules Programming list view
       */
      .state("app.panel_programming.schedules_list_xt", {
        url: "/schedules_list_xt",
        title: "Schedules List",
        templateUrl:
          "app/programming/schedules/xt-views/schedules-view-xt.html",
        controller: "SchedulesXTListCtrl",
      })

      /**
       * Schedules Output Programming Create New
       */
      .state("app.panel_programming.schedule_new_output_xt", {
        url: "/schedules/xt/output/new",
        title: "Create New Output Schedule",
        templateUrl: "app/programming/schedules/xt-views/schedule-edit-xt.html",
        controller: "SchedulesXTBaseCtrl",
        resolve: {
          schedule_type: [
            "PANEL_CONCEPTS",
            function (PANEL_CONCEPTS) {
              return PANEL_CONCEPTS.output_schedules;
            },
          ],
          control_system_id: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.control_system_id;
            },
          ],
          panel_id: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.panel_id;
            },
          ],
          init: function (
            $stateParams,
            UserService,
            ControlSystemsService,
            $state
          ) {
            return ControlSystemsService.showControlSystem(
              $stateParams.control_system_id,
              $stateParams.customer_id
            )
              .then(
                function () {
                  UserService.customer_id = $stateParams.customer_id;
                  UserService.control_system_id =
                    $stateParams.control_system_id;
                },
                function (error) {
                  console.error(
                    "Error Getting Control System: " + angular.toJson(error)
                  );
                  $state.go("page.404");
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
        },
      })

      /**
       * Schedules Output Programming Edit
       */
      .state("app.panel_programming.schedule_edit_output_xt", {
        url: "/schedules/xt/output/:number/edit",
        title: "Edit Output Schedule",
        templateUrl: "app/programming/schedules/xt-views/schedule-edit-xt.html",
        controller: "SchedulesXTBaseCtrl",
        resolve: {
          schedule_type: [
            "PANEL_CONCEPTS",
            function (PANEL_CONCEPTS) {
              return PANEL_CONCEPTS.output_schedules;
            },
          ],
          control_system_id: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.control_system_id;
            },
          ],
          panel_id: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.panel_id;
            },
          ],
          init: function (
            $stateParams,
            UserService,
            ControlSystemsService,
            $state
          ) {
            return ControlSystemsService.showControlSystem(
              $stateParams.control_system_id,
              $stateParams.customer_id
            )
              .then(
                function () {
                  UserService.customer_id = $stateParams.customer_id;
                  UserService.control_system_id =
                    $stateParams.control_system_id;
                },
                function (error) {
                  console.error(
                    "Error Getting Control System: " + angular.toJson(error)
                  );
                  $state.go("page.404");
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
        },
      })

      /**
       * Schedules Favorite Programming Create New
       */
      .state("app.panel_programming.schedule_new_favorite_xt", {
        url: "/schedules/xt/favorite/new",
        title: "Create New Favorite Schedule",
        templateUrl: "app/programming/schedules/xt-views/schedule-edit-xt.html",
        controller: "SchedulesXTBaseCtrl",
        resolve: {
          schedule_type: [
            "PANEL_CONCEPTS",
            function (PANEL_CONCEPTS) {
              return PANEL_CONCEPTS.favorite_schedules;
            },
          ],
          control_system_id: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.control_system_id;
            },
          ],
          panel_id: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.panel_id;
            },
          ],
          favorite_number: [
            function () {
              return "";
            },
          ],
          init: function (
            $stateParams,
            UserService,
            ControlSystemsService,
            $state
          ) {
            return ControlSystemsService.showControlSystem(
              $stateParams.control_system_id,
              $stateParams.customer_id
            )
              .then(
                function () {
                  UserService.customer_id = $stateParams.customer_id;
                  UserService.control_system_id =
                    $stateParams.control_system_id;
                },
                function (error) {
                  console.error(
                    "Error Getting Control System: " + angular.toJson(error)
                  );
                  $state.go("page.404");
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
        },
      })

      /**
       * Schedules Favorite Programming Edit
       */
      .state("app.panel_programming.schedule_edit_favorite_xt", {
        url: "/schedules/xt/favorite/:number/edit",
        title: "Edit Favorite Schedule",
        templateUrl: "app/programming/schedules/xt-views/schedule-edit-xt.html",
        controller: "SchedulesXTBaseCtrl",
        resolve: {
          schedule_type: [
            "PANEL_CONCEPTS",
            function (PANEL_CONCEPTS) {
              return PANEL_CONCEPTS.favorite_schedules;
            },
          ],
          control_system_id: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.control_system_id;
            },
          ],
          panel_id: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.panel_id;
            },
          ],
          favorite_number: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.number;
            },
          ],
          init: function (
            $stateParams,
            UserService,
            ControlSystemsService,
            $state
          ) {
            return ControlSystemsService.showControlSystem(
              $stateParams.control_system_id,
              $stateParams.customer_id
            )
              .then(
                function () {
                  UserService.customer_id = $stateParams.customer_id;
                  UserService.control_system_id =
                    $stateParams.control_system_id;
                },
                function (error) {
                  console.error(
                    "Error Getting Control System: " + angular.toJson(error)
                  );
                  $state.go("page.404");
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
        },
      })

      /**
       * Schedules Programming New
       * This route must be before the other schedule routes so that it doesn't see 'new' as the ID of a schedule.
       */
      .state("app.panel_programming.schedule_new_xr", {
        url: "/schedules/xr/new",
        title: "Create New Schedule",
        templateUrl: "app/programming/schedules/xr-views/schedule-edit-xr.html",
        controller: "SchedulesXREditCtrl",
        controllerAs: "editSched",
        resolve: {
          customer_id: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.customer_id;
            },
          ],
          init: function (
            $stateParams,
            UserService,
            DealerService,
            ControlSystemsService,
            $state
          ) {
            return ControlSystemsService.getControlSystem(
              $stateParams.control_system_id,
              $stateParams.customer_id
            )
              .then(
                function () {
                  UserService.customer_id = $stateParams.customer_id;
                  UserService.control_system_id =
                    $stateParams.control_system_id;
                },
                function (error) {
                  console.error(
                    "Error Getting Control System: " + angular.toJson(error)
                  );
                  $state.go("page.404");
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
        },
      })

      /**
       * Schedules Programming list view
       */
      .state("app.panel_programming.schedules_xr", {
        url: "/schedules/xr",
        title: "Schedules List",
        templateUrl:
          "app/programming/schedules/xr-views/schedules-view-xr.html",
        controller: "SchedulesXRViewCtrl",
        controllerAs: "schedules",
        resolve: {
          init: function (
            $stateParams,
            UserService,
            DealerService,
            ControlSystemsService,
            $state
          ) {
            return ControlSystemsService.getControlSystem(
              $stateParams.control_system_id,
              $stateParams.customer_id
            )
              .then(
                function () {
                  UserService.customer_id = $stateParams.customer_id;
                  UserService.control_system_id =
                    $stateParams.control_system_id;
                },
                function (error) {
                  console.error(
                    "Error Getting Control System: " + angular.toJson(error)
                  );
                  $state.go("page.404");
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
        },
      })

      /**
       * Schedules Programming edit
       */
      .state("app.panel_programming.schedule_edit_xr", {
        url: "/schedules/xr/:schedule_number/edit",
        title: "Edit Schedule",
        templateUrl: "app/programming/schedules/xr-views/schedule-edit-xr.html",
        controller: "SchedulesXREditCtrl",
        controllerAs: "editSched",
        resolve: {
          schedule_number: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.schedule_number;
            },
          ],
          init: function (
            $stateParams,
            UserService,
            DealerService,
            ControlSystemsService,
            $state
          ) {
            return ControlSystemsService.getControlSystem(
              $stateParams.control_system_id,
              $stateParams.customer_id
            )
              .then(
                function () {
                  UserService.customer_id = $stateParams.customer_id;
                  UserService.control_system_id =
                    $stateParams.control_system_id;
                },
                function (error) {
                  console.error(
                    "Error Getting Control System: " + angular.toJson(error)
                  );
                  $state.go("page.404");
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
        },
      })

      /**
       * Schedule Area Settings edit
       */
      .state("app.panel_programming.area_settings_xr", {
        url: "/area-settings-xr",
        title: "Area Settings",
        templateUrl: "app/programming/schedules/xr-views/area-settings-xr.html",
        controller: "SchedulesXREditCtrl",
        controllerAs: "AreaControl",
      })

      /**
       * User_Codes Programming Create New
       **/
      .state("app.panel_programming.user_codes_new", {
        url: "/user_codes/new/credentials/:credential_id",
        title: "Create User Code",
        templateUrl: "app/programming/user_codes/usercode-edit.html",
        controller: "UserCodeEditCtrl",
        resolve: {
          user_number: [
            "$stateParams",
            function () {
              return "";
            },
          ],
          credential_id: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.credential_id || 0;
            },
          ],
          init: function (
            $stateParams,
            UserService,
            DealerService,
            ControlSystemsService,
            $state
          ) {
            return ControlSystemsService.getControlSystem(
              $stateParams.control_system_id,
              $stateParams.customer_id
            )
              .then(
                function () {
                  UserService.customer_id = $stateParams.customer_id;
                  UserService.control_system_id =
                    $stateParams.control_system_id;
                },
                function (error) {
                  console.error(
                    "Error Getting Control System: " + angular.toJson(error)
                  );
                  $state.go("page.404");
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
        },
      })

      /**
       * User_Codes Programming Edit
       **/
      .state("app.panel_programming.user_codes_edit", {
        url: "/user_codes/:user_number/edit",
        title: "Edit User Code",
        templateUrl: "app/programming/user_codes/usercode-edit.html",
        controller: "UserCodeEditCtrl",
        resolve: {
          user_number: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.user_number;
            },
          ],
          credential_id: [
            "$stateParams",
            function () {
              return null;
            },
          ],
          init: function (
            $stateParams,
            UserService,
            ControlSystemsService,
            $state
          ) {
            return ControlSystemsService.showControlSystem(
              $stateParams.control_system_id,
              $stateParams.customer_id
            )
              .then(
                function () {
                  UserService.customer_id = $stateParams.customer_id;
                  UserService.control_system_id =
                    $stateParams.control_system_id;
                },
                function (error) {
                  console.error(
                    "Error Getting Control System: " + angular.toJson(error)
                  );
                  $state.go("page.404");
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
        },
      })

      /**
       * User_Codes Programming List view
       **/
      .state("app.panel_programming.user_codes", {
        url: "/user_codes",
        title: "User Codes List",
        templateUrl: "app/programming/user_codes/usercode-list.html",
        controller: "UserCodeEditCtrl",
        resolve: {
          user_number: [
            "$stateParams",
            function () {
              return "";
            },
          ],
          credential_id: [
            "$stateParams",
            function () {
              return null;
            },
          ],
          init: function (
            $stateParams,
            UserService,
            ControlSystemsService,
            $state
          ) {
            return ControlSystemsService.showControlSystem(
              $stateParams.control_system_id,
              $stateParams.customer_id
            )
              .then(
                function () {
                  UserService.customer_id = $stateParams.customer_id;
                  UserService.control_system_id =
                    $stateParams.control_system_id;
                },
                function (error) {
                  console.error(
                    "Error Getting Control System: " + angular.toJson(error)
                  );
                  $state.go("page.404");
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
        },
      })

      /**
       * Profiles Programming Create New
       **/
      .state("app.panel_programming.profiles_new", {
        url: "/profiles/new",
        title: "Create Profile",
        templateUrl: "app/programming/profiles/profile-edit.html",
        controller: "ProfileEditCtrl",
        resolve: {
          profile_number: [
            function () {
              return "";
            },
          ],
          customer_id: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.customer_id;
            },
          ],
          init: function (
            $stateParams,
            UserService,
            DealerService,
            ControlSystemsService,
            $state
          ) {
            return ControlSystemsService.getControlSystem(
              $stateParams.control_system_id,
              $stateParams.customer_id
            )
              .then(
                function () {
                  UserService.customer_id = $stateParams.customer_id;
                  UserService.control_system_id =
                    $stateParams.control_system_id;
                },
                function (error) {
                  console.error(
                    "Error Getting Control System: " + angular.toJson(error)
                  );
                  $state.go("page.404");
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
        },
      })

      /**
       * Profiles Programming Edit
       **/
      .state("app.panel_programming.profiles_edit", {
        url: "/profiles/:profile_number/edit",
        title: "Edit Profile",
        templateUrl: "app/programming/profiles/profile-edit.html",
        controller: "ProfileEditCtrl",
        resolve: {
          profile_number: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.profile_number;
            },
          ],
          customer_id: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.customer_id;
            },
          ],
          init: function (
            $stateParams,
            UserService,
            DealerService,
            ControlSystemsService,
            $state
          ) {
            return ControlSystemsService.getControlSystem(
              $stateParams.control_system_id,
              $stateParams.customer_id
            )
              .then(
                function () {
                  UserService.customer_id = $stateParams.customer_id;
                  UserService.control_system_id =
                    $stateParams.control_system_id;
                },
                function (error) {
                  console.error(
                    "Error Getting Control System: " + angular.toJson(error)
                  );
                  $state.go("page.404");
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
        },
      })

      /**
       * Profiles Programming List view
       **/
      .state("app.panel_programming.profiles", {
        url: "/profiles",
        title: "Profiles List",
        templateUrl: "app/programming/profiles/profile-list.html",
        controller: "ProfileEditCtrl",
        resolve: {
          profile_number: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.profile_number;
            },
          ],
          customer_id: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.customer_id;
            },
          ],
          init: function (
            $stateParams,
            UserService,
            DealerService,
            ControlSystemsService,
            $state
          ) {
            return ControlSystemsService.getControlSystem(
              $stateParams.control_system_id,
              $stateParams.customer_id
            )
              .then(
                function () {
                  UserService.customer_id = $stateParams.customer_id;
                  UserService.control_system_id =
                    $stateParams.control_system_id;
                },
                function (error) {
                  console.error(
                    "Error Getting Control System: " + angular.toJson(error)
                  );
                  $state.go("page.404");
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
        },
      })

      /**
       * Groups Programming Create New
       **/
      .state("app.panel_programming.groups_new", {
        url: "/groups/new",
        title: "Create Group",
        templateUrl: "app/programming/groups/group-new.html",
      })

      /**
       * Groups Programming Edit
       **/
      .state("app.panel_programming.groups_edit", {
        url: "/groups/:group_number/edit",
        title: "Edit Group",
        templateUrl: "app/programming/groups/group-edit.html",
      })

      /**
       * Groups Programming List view
       **/
      .state("app.panel_programming.groups", {
        url: "/groups",
        title: "Groups List",
        templateUrl: "app/programming/groups/group-list.html",
      })

      .state("app.panel_programming.system_tests", {
        url: "/system_tests.html",
        title: "System Tests",
        templateUrl: "app/system-tests/system-tests.html",
        controller: "SystemTestsCtrl",
      })

      // ===========================
      // System Status
      // ===========================

      .state("app.system_status", {
        url: "/customers/:customer_id/control-systems/:control_system_id/panels/:panel_id/system-status",
        title: "System Status",
        templateUrl: "app/system-status/system-status.html",
        resolve: {
          init: function (
            $stateParams,
            UserService,
            DealerService,
            ControlSystemsService,
            $state
          ) {
            return ControlSystemsService.getControlSystem(
              $stateParams.control_system_id,
              $stateParams.customer_id
            )
              .then(
                function () {
                  UserService.customer_id = $stateParams.customer_id;
                  UserService.control_system_id =
                    $stateParams.control_system_id;
                },
                function (error) {
                  console.error(
                    "Error Getting Control System: " + angular.toJson(error)
                  );
                  $state.go("page.404");
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
        },
      })

      // ===========================
      // Reports
      // ===========================

      .state("app.report-gen", {
        url: "/customers/:customer_id/control_systems/:control_system_id/panels/:panel_id/report-config",
        title: "Report Configuration",
        templateUrl: "app/reports/report-configuration.html",
        controller: "ReportConfigController",
        controllerAs: "reportCtrl",
        resolve: {
          init: function (
            $stateParams,
            UserService,
            DealerService,
            ControlSystemsService,
            $state
          ) {
            return ControlSystemsService.getControlSystem(
              $stateParams.control_system_id,
              $stateParams.customer_id
            )
              .then(
                function () {
                  UserService.customer_id = $stateParams.customer_id;
                  UserService.control_system_id =
                    $stateParams.control_system_id;
                },
                function (error) {
                  console.error(
                    "Error Getting Control System: " + angular.toJson(error)
                  );
                  $state.go("page.404");
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
        },
      })

      // ===========================
      // Report Display
      // ===========================

      .state("app.report-display", {
        url: "/customers/:customer_id/control_systems/:control_system_id/panels/:panel_id/report-display/:report_type",
        title: "Report Display",
        templateUrl: "app/reports/report-display.html",
        controller: "ReportDisplayController",
        controllerAs: "reportDisplayCtrl",
        resolve: {
          init: function (
            $stateParams,
            UserService,
            DealerService,
            ControlSystemsService,
            $state
          ) {
            return ControlSystemsService.getControlSystem(
              $stateParams.control_system_id,
              $stateParams.customer_id
            )
              .then(
                function () {
                  UserService.customer_id = $stateParams.customer_id;
                  UserService.control_system_id =
                    $stateParams.control_system_id;
                },
                function (error) {
                  console.error(
                    "Error Getting Control System: " + angular.toJson(error)
                  );
                  $state.go("page.404");
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
        },
      })

      // ===========================
      // Edit Camera
      // ===========================

      .state("app.edit-camera", {
        url: "/customers/:customer_id/control_systems/:control_system_id/camera/:device_id/edit",
        title: "Edit Camera",
        templateUrl: "app/video/camera/camera-edit.html",
        controller: "CameraCtrl",
        params: {
          force_send_tz: false,
        },
        resolve: {
          device_id: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.device_id;
            },
          ],
          init: function (
            $stateParams,
            UserService,
            DealerService,
            ControlSystemsService,
            VideoDeviceService,
            $state
          ) {
            return ControlSystemsService.showControlSystem(
              $stateParams.control_system_id,
              $stateParams.customer_id
            )
              .then(
                function () {
                  UserService.customer_id = $stateParams.customer_id;
                  UserService.control_system_id =
                    $stateParams.control_system_id;
                  // We need to get all video devices in order limit the number of channels a user can add
                  VideoDeviceService.getVideoDevices();
                },
                function (error) {
                  console.error(
                    "Error Getting Control System: " + angular.toJson(error)
                  );
                  $state.go("page.404");
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
        },
      })
      .state("app.uniview-camera-edit", {
        url: "/customers/:customer_id/control_systems/:control_system_id/camera/:device_id/channel/:channel_id/uniview_camera_edit",
        title: "Edit Camera",
        templateUrl: "app/video/camera/uniview-camera-edit.html",
        resolve: {
          device_id: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.device_id;
            },
          ],
          channel_id: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.channel_id;
            },
          ],
          init: function (
            $stateParams,
            UserService,
            ControlSystemsService,
            VideoDeviceService,
            $state
          ) {
            return ControlSystemsService.showControlSystem(
              $stateParams.control_system_id,
              $stateParams.customer_id
            )
              .then(
                () => {
                  UserService.customer_id = $stateParams.customer_id;
                  UserService.control_system_id =
                    $stateParams.control_system_id;
                  // We need to get all video devices in order limit the number of channels a user can add
                  VideoDeviceService.getVideoDevices();
                },
                (error) => {
                  console.error(
                    "Error Getting Control System: " + angular.toJson(error)
                  );
                  $state.go("page.404");
                }
              )
              .catch((error) => {
                console.error(error);
              });
          },
        },
      })
      .state("app.edit-camera-sites", {
        url: "/customers/:customer_id/sites/:site_id/camera/:device_id/edit",
        title: "Edit Camera",
        templateUrl: "app/video/camera/camera-edit.html",
        controller: "CameraCtrl",
        params: {
          force_send_tz: false,
        },
        resolve: {
          device_id: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.device_id;
            },
          ],
          init: function (
            $stateParams,
            UserService,
            DealerService,
            ControlSystemsService,
            Customer,
            VideoDeviceService,
            $state,
            RelayService
          ) {
            return RelayService.query(
              graphql`
                query configAppEditCameraSitesSiteQuery($siteId: ID!) {
                  site(id: $siteId) {
                    id
                    name
                    billingControlSystemId
                  }
                }
              `,
              {
                siteId: toSiteId(
                  $stateParams.customer_id,
                  $stateParams.site_id
                ),
              }
            )
              .toPromise()
              .then(
                async function (data) {
                  UserService.setSite(data.site);
                  UserService.customer_id = $stateParams.customer_id;
                  UserService.control_system_id =
                    data.node.billingControlSystemId;
                  await UserService.setCustomerInfo(
                    new Customer($stateParams.customer_id)
                  );
                  await UserService.setDealerInfo(
                    new DealerService({ customer_id: $stateParams.customer_id })
                  );
                  // We need to get all video devices in order limit the number of channels a user can add
                  VideoDeviceService.getVideoDevices();
                },
                function (error) {
                  console.error(
                    "Error Getting Control System: " + angular.toJson(error)
                  );
                  $state.go("page.404");
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
        },
      })
      .state("app.uniview-camera-edit-sites", {
        url: "/customers/:customer_id/sites/:site_id/camera/:device_id/channel/:channel_id/uniview_camera_edit",
        title: "Edit Camera",
        templateUrl: "app/video/camera/uniview-camera-edit.html",
        resolve: {
          device_id: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.device_id;
            },
          ],
          channel_id: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.channel_id;
            },
          ],
          init: function (
            $stateParams,
            UserService,
            Customer,
            DealerService,
            VideoDeviceService,
            $state,
            RelayService
          ) {
            return RelayService.query(
              graphql`
                query configAppUniviewCameraEditSitesSiteQuery($siteId: ID!) {
                  site(id: $siteId) {
                    id
                    name
                    billingControlSystemId
                  }
                }
              `,
              {
                siteId: toSiteId(
                  $stateParams.customer_id,
                  $stateParams.site_id
                ),
              }
            )
              .toPromise()
              .then(
                async (data) => {
                  UserService.setSite(data.site);

                  await UserService.setCustomerInfo(
                    new Customer($stateParams.customer_id)
                  );
                  await UserService.setDealerInfo(
                    new DealerService({ customer_id: $stateParams.customer_id })
                  );
                  UserService.customer_id = $stateParams.customer_id;
                  UserService.control_system_id =
                    data.node.billingControlSystemId;
                  // We need to get all video devices in order limit the number of channels a user can add
                  VideoDeviceService.getVideoDevices();
                },
                (error) => {
                  console.error(
                    `Error Getting Control System: ${angular.toJson(error)}`
                  );
                  $state.go("page.404");
                }
              )
              .catch((error) => {
                console.error(error);
              });
          },
        },
      })

      // ===========================
      // Recorder Options
      // ===========================

      .state("app.edit-var", {
        url: "/customers/:customer_id/control_systems/:control_system_id/var/:var_hub_id/edit",
        title: "Edit XV Gateway",
        templateUrl: "app/video/var/var.html",
        resolve: {
          var_hub_id: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.var_hub_id;
            },
          ],
          init: function (
            $stateParams,
            UserService,
            DealerService,
            ControlSystemsService,
            $state
          ) {
            return ControlSystemsService.showControlSystem(
              $stateParams.control_system_id,
              $stateParams.customer_id
            )
              .then(
                function () {
                  UserService.customer_id = $stateParams.customer_id;
                  UserService.control_system_id =
                    $stateParams.control_system_id;
                },
                function (error) {
                  console.error(
                    "Error Getting Control System: " + angular.toJson(error)
                  );
                  $state.go("page.404");
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
        },
      })
      .state("app.edit-cam", {
        url: "/customers/:customer_id/control_systems/:control_system_id/hubs/:hub_id/var_cam/:camera_id",
        title: "Camera Settings",
        templateUrl: "app/video/var/camera-edit.html",
        resolve: {
          hub_id: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.hub_id;
            },
          ],
          camera_id: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.camera_id;
            },
          ],
          init: function (
            $stateParams,
            UserService,
            ControlSystemsService,
            $state
          ) {
            return ControlSystemsService.showControlSystem(
              $stateParams.control_system_id,
              $stateParams.customer_id
            )
              .then(
                function () {
                  UserService.customer_id = $stateParams.customer_id;
                  UserService.control_system_id =
                    $stateParams.control_system_id;
                },
                function (error) {
                  console.error(
                    "Error Getting Control System: " + angular.toJson(error)
                  );
                  $state.go("page.404");
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
        },
      })

      // ===========================
      // iENSO Cameras
      // ===========================

      .state("app.edit-ienso-cameras", {
        url: "/customers/:customer_id/control_systems/:control_system_id/xv_cameras",
        title: "XV Camera Settings",
        templateUrl: "app/video/ienso/camera-edit.html",
        resolve: {
          init: function (
            $stateParams,
            UserService,
            ControlSystemsService,
            $state
          ) {
            return ControlSystemsService.showControlSystem(
              $stateParams.control_system_id,
              $stateParams.customer_id
            )
              .then(
                function () {
                  UserService.customer_id = $stateParams.customer_id;
                  UserService.control_system_id =
                    $stateParams.control_system_id;
                },
                function (error) {
                  console.error(
                    "Error Getting Control System: " + angular.toJson(error)
                  );
                  $state.go("page.404");
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
        },
      })

      .state("app.edit-ienso-camera", {
        url: "/customers/:customer_id/control_systems/:control_system_id/xv_cameras/:serial_number",
        title: "XV Camera Settings",
        controller: function ($stateParams, $scope) {
          $scope.serial_number = $stateParams.serial_number;
        },
        templateUrl: "app/video/ienso/camera-edit.html",
        resolve: {
          init: function (
            $stateParams,
            UserService,
            ControlSystemsService,
            $state
          ) {
            return ControlSystemsService.showControlSystem(
              $stateParams.control_system_id,
              $stateParams.customer_id
            )
              .then(
                function () {
                  UserService.customer_id = $stateParams.customer_id;
                  UserService.control_system_id =
                    $stateParams.control_system_id;
                },
                function (error) {
                  console.error(
                    "Error Getting Control System: " + angular.toJson(error)
                  );
                  $state.go("page.404");
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
        },
      })

      // ===========================
      // View Camera
      // ===========================

      .state("app.view-camera", {
        url: "/customers/:customer_id/control_systems/:control_system_id/camera/:device_id/view",
        title: "View Camera",
        templateUrl: "app/video/camera/camera-view.html",
        controller: "CameraCtrl",
        resolve: {
          device_id: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.device_id;
            },
          ],
          init: function (
            $stateParams,
            UserService,
            DealerService,
            ControlSystemsService,
            $state
          ) {
            return ControlSystemsService.showControlSystem(
              $stateParams.control_system_id,
              $stateParams.customer_id
            )
              .then(
                function () {
                  UserService.customer_id = $stateParams.customer_id;
                  UserService.control_system_id =
                    $stateParams.control_system_id;
                },
                function (error) {
                  console.error(
                    "Error Getting Control System: " + angular.toJson(error)
                  );
                  $state.go("page.404");
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
        },
      })
      .state("app.view-camera-sites", {
        url: "/customers/:customer_id/sites/:site_id/camera/:device_id/view",
        title: "View Camera",
        templateUrl: "app/video/camera/camera-view.html",
        controller: "CameraCtrl",
        resolve: {
          device_id: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.device_id;
            },
          ],
          init: function (
            $stateParams,
            UserService,
            DealerService,
            Customer,
            ControlSystemsService,
            $state,
            RelayService
          ) {
            return RelayService.query(
              graphql`
                query configAppViewCameraSitesSiteQuery($siteId: ID!) {
                  site(id: $siteId) {
                    id
                    name
                    billingControlSystemId
                  }
                }
              `,
              {
                siteId: toSiteId(
                  $stateParams.customer_id,
                  $stateParams.site_id
                ),
              }
            )
              .toPromise()
              .then(
                async function (data) {
                  UserService.customer_id = $stateParams.customer_id;
                  UserService.control_system_id =
                    data.node.billingControlSystemId;
                  UserService.setSite(data.site);
                  await UserService.setCustomerInfo(
                    new Customer($stateParams.customer_id)
                  );
                  await UserService.setDealerInfo(
                    new DealerService({ customer_id: $stateParams.customer_id })
                  );
                },
                function (error) {
                  console.error(
                    "Error Getting Control System: " + angular.toJson(error)
                  );
                  $state.go("page.404");
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
        },
      })

      // ===========================
      // Edit NVR
      // ===========================

      .state("app.edit-nvr", {
        url: "/customers/:customer_id/control_systems/:control_system_id/nvr/:device_id",
        title: "Edit NVR",
        templateUrl: "app/video/nvr/nvr-edit.html",
        controller: "NvrCtrl",
        params: {
          force_send_tz: false,
        },
        resolve: {
          device_id: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.device_id;
            },
          ],
          channel_id: [
            "$stateParams",
            function ($stateParams) {
              return null;
            },
          ],
          init: function (
            $stateParams,
            UserService,
            DealerService,
            ControlSystemsService,
            VideoDeviceService,
            $state
          ) {
            return ControlSystemsService.showControlSystem(
              $stateParams.control_system_id,
              $stateParams.customer_id
            )
              .then(
                function () {
                  UserService.customer_id = $stateParams.customer_id;
                  UserService.control_system_id =
                    $stateParams.control_system_id;
                  // We need to get all video devices in order limit the number of channels a user can add
                  VideoDeviceService.getVideoDevices();
                },
                function (error) {
                  console.error(
                    "Error Getting Control System: " + angular.toJson(error)
                  );
                  $state.go("page.404");
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
        },
      })
      .state("app.edit-nvr-sites", {
        url: "/customers/:customer_id/sites/:site_id/nvr/:device_id",
        title: "Edit NVR",
        templateUrl: "app/video/nvr/nvr-edit.html",
        controller: "NvrCtrl",
        params: {
          force_send_tz: false,
        },
        resolve: {
          device_id: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.device_id;
            },
          ],
          channel_id: [
            "$stateParams",
            function ($stateParams) {
              return null;
            },
          ],
          init: function (
            $stateParams,
            UserService,
            DealerService,
            Customer,
            ControlSystemsService,
            VideoDeviceService,
            $state,
            RelayService
          ) {
            return RelayService.query(
              graphql`
                query configAppEditNvrSitesSiteQuery($siteId: ID!) {
                  site(id: $siteId) {
                    id
                    name
                    billingControlSystemId
                  }
                }
              `,
              {
                siteId: toSiteId(
                  $stateParams.customer_id,
                  $stateParams.site_id
                ),
              }
            )
              .toPromise()
              .then(
                async function (data) {
                  UserService.customer_id = $stateParams.customer_id;
                  UserService.control_system_id =
                    data.node.billingControlSystemId;
                  // We need to get all video devices in order limit the number of channels a user can add
                  VideoDeviceService.getVideoDevices();
                  UserService.setSite(data.site);
                  await UserService.setCustomerInfo(
                    new Customer($stateParams.customer_id)
                  );
                  await UserService.setDealerInfo(
                    new DealerService({ customer_id: $stateParams.customer_id })
                  );
                },
                function (error) {
                  console.error(
                    "Error Getting Control System: " + angular.toJson(error)
                  );
                  $state.go("page.404");
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
        },
      })

      // ===========================
      // View NVR
      // ===========================

      .state("app.view-nvr", {
        url: "/customers/:customer_id/control_systems/:control_system_id/nvr/:device_id/channel/:channel_id/view",
        title: "View NVR",
        templateUrl: "app/video/nvr/nvr-view.html",
        controller: "NvrCtrl",
        resolve: {
          device_id: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.device_id;
            },
          ],
          channel_id: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.channel_id;
            },
          ],
          init: function (
            $stateParams,
            UserService,
            DealerService,
            ControlSystemsService,
            $state
          ) {
            return ControlSystemsService.showControlSystem(
              $stateParams.control_system_id,
              $stateParams.customer_id
            )
              .then(
                function () {
                  UserService.customer_id = $stateParams.customer_id;
                  UserService.control_system_id =
                    $stateParams.control_system_id;
                },
                function (error) {
                  console.error(
                    "Error Getting Control System: " + angular.toJson(error)
                  );
                  $state.go("page.404");
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
        },
      })
      .state("app.view-nvr-sites", {
        url: "/customers/:customer_id/sites/:site_id/nvr/:device_id/channel/:channel_id/view",
        title: "View NVR",
        templateUrl: "app/video/nvr/nvr-view.html",
        controller: "NvrCtrl",
        resolve: {
          device_id: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.device_id;
            },
          ],
          channel_id: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.channel_id;
            },
          ],
          init: function (
            $stateParams,
            UserService,
            DealerService,
            Customer,
            ControlSystemsService,
            $state,
            RelayService
          ) {
            return RelayService.query(
              graphql`
                query configAppViewNvrSitesSiteQuery($siteId: ID!) {
                  site(id: $siteId) {
                    id
                    name
                    billingControlSystemId
                  }
                }
              `,
              {
                siteId: toSiteId(
                  $stateParams.customer_id,
                  $stateParams.site_id
                ),
              }
            )
              .toPromise()
              .then(
                async function (data) {
                  UserService.customer_id = $stateParams.customer_id;
                  UserService.control_system_id =
                    data.node.billingControlSystemId;
                  UserService.setSite(data.site);
                  await UserService.setCustomerInfo(
                    new Customer($stateParams.customer_id)
                  );
                  await UserService.setDealerInfo(
                    new DealerService({ customer_id: $stateParams.customer_id })
                  );
                },
                function (error) {
                  console.error(
                    "Error Getting Control System: " + angular.toJson(error)
                  );
                  $state.go("page.404");
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          },
        },
      })

      // ===========================
      // Single Page Routes
      // ===========================

      .state("page", {
        url: "/page",
        templateUrl: "app/common/pages/page.html",
        resolve: resolveFor("modernizr", "icons"),
      })
      .state("page.changePassword", {
        url: "/change-password?token",
        title: "Update Password",
        templateUrl: "app/common/pages/create-update-password-link.html",
        controller: "CreateUpdatePasswordLinkCtrl",
      })
      .state("page.createPassword", {
        url: "/create-password?token",
        title: "Create Password",
        templateUrl: "app/common/pages/create-update-password-link.html",
        controller: "CreateUpdatePasswordLinkCtrl",
      })
      .state("page.video", {
        url: "/video/:control_system_id",
        title: "Video Verification",
        templateUrl: "app/video/video-verification.html",
        controller: "VideoVerificationCtrl",
        controllerAs: "video",
        resolve: angular.extend(resolveFor("video"), {
          control_system_id: [
            "$stateParams",
            function ($stateParams) {
              return $stateParams.control_system_id;
            },
          ],
        }),
      })
      .state("page.vvhold", {
        url: "/vvhold",
        title: "Video Verification User Logged In",
        templateUrl: "app/common/pages/vv-hold.html",
        controller: function ($scope, UserService) {
          $scope.UserService = UserService;
        },
      })
      .state("page.login", {
        url: "/login?autoLogout",
        title: "Login",
        templateUrl: "app/common/pages/login.html",
        controller: "LoginFormCtrl",
        resolve: {
          init: function ($stateParams) {
            return $stateParams.autoLogout ?? $stateParams.autoLogout;
          },
        },
      })

      .state("page.404", {
        url: "/404",
        title: "The page you were looking for doesn't exist (404)",
        templateUrl: "app/common/pages/404.html",
      })
      .state("page.403", {
        url: "/403",
        title: "Access Forbidden (403)",
        templateUrl: "app/common/pages/403.html",
      })
      .state("page.confirm", {
        url: "/confirm",
        title: "Thank You",
        templateUrl: "app/common/pages/campaign-confirm.html",
        controller: "CampaignConfirmCtrl",
      })
      .state("page.serverStatus", {
        url: "/ServerStatus",
        title: "SecureCom Server Status",
        controller: "ServerStatusCtrl",
        templateUrl: "app/common/pages/server-status.html",
      });

    // Generates a resolve object by passing script names
    // previously configured in constant.APP_REQUIRES
    function resolveFor() {
      var _args = arguments;
      return {
        deps: [
          "$ocLazyLoad",
          "$q",
          function ($ocLL, $q) {
            // Creates a promise chain for each argument
            var promise = $q.when(1); // empty promise
            for (var i = 0, len = _args.length; i < len; i++) {
              promise = andThen(_args[i]);
            }
            return promise;

            // creates promise to chain dynamically
            function andThen(_arg) {
              // also support a function that returns a promise
              if (typeof _arg == "function")
                return promise.then(_arg).catch(function (error) {
                  console.error(error);
                });
              else
                return promise
                  .then(function () {
                    // if is a module, pass the name. If not, pass the array
                    var whatToLoad = getRequired(_arg);
                    // simple error check
                    if (!whatToLoad)
                      return $.error(
                        "Route resolve: Bad resource name [" + _arg + "]"
                      );
                    // finally, return a promise
                    return $ocLL.load(whatToLoad);
                  })
                  .catch(function (error) {
                    console.error(error);
                  });
            }

            // check and returns required data
            // analyze module items with the form [name: '', files: []]
            // and also simple array of script files (for not angular js)
            function getRequired(name) {
              if (appRequires.modules)
                for (var m in appRequires.modules)
                  if (
                    appRequires.modules[m].name &&
                    appRequires.modules[m].name === name
                  )
                    return appRequires.modules[m];
              return appRequires.scripts && appRequires.scripts[name];
            }
          },
        ],
      };
    }

    // This allows us to reload the current state (route) from wherever we are
    $provide.decorator("$state", function ($delegate, $stateParams) {
      $delegate.forceReload = function () {
        return $delegate.go($delegate.current, $stateParams, {
          reload: true,
          inherit: false,
          notify: true,
        });
      };
      return $delegate;
    });
  },
])

  .config([
    "$translateProvider",
    function ($translateProvider) {
      $translateProvider.useStaticFilesLoader({
        prefix: "/assets/i18n/",
        suffix: ".json",
      });
      $translateProvider.preferredLanguage("en");
      $translateProvider.useLocalStorage();
      $translateProvider.usePostCompiling(true);
    },
  ])

  .config([
    "cfpLoadingBarProvider",
    function (cfpLoadingBarProvider) {
      cfpLoadingBarProvider.includeBar = true;
      cfpLoadingBarProvider.includeSpinner = false;
      cfpLoadingBarProvider.latencyThreshold = 500;
      cfpLoadingBarProvider.parentSelector = ".wrapper > section";
    },
  ])

  .config([
    "$httpProvider",
    function ($httpProvider) {
      $httpProvider.defaults.useXDomain = true;
      delete $httpProvider.defaults.headers.common["X-Requested-With"];
      // By default, Angular accepts application/json, text/plain and */*. For some reason, this breaks POST and PATCH requests against VK.
      $httpProvider.defaults.headers.post["Accept"] =
        "application/json, text/plain";
      $httpProvider.defaults.headers.patch["Accept"] =
        "application/json, text/plain";
      // At least delete dealer receivers was broken for delete. Add a delete header object.
      $httpProvider.defaults.headers.delete = {
        Accept: "application/json, text/plain",
      };
      // Causes the $digest cycle to wait a few milliseconds for multiple http requests to complete, if necessary
      // This prevents a whole stack of $digests from running at/near the same time
      $httpProvider.useApplyAsync(true);
    },
  ])

  .config([
    "$qProvider",
    function ($qProvider) {
      $qProvider.errorOnUnhandledRejections(false);
    },
  ])

  // This prevents the hash-prefix from becoming /#!/app/... (removes the ! in angularjs 1.6+)
  .config([
    "$locationProvider",
    function ($locationProvider) {
      $locationProvider.hashPrefix("");
    },
  ])

  .controller("NullController", function () {});
