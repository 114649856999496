/**
 * @generated SignedSource<<660980f8258160e4aca75e6ed646bb71>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CardFormatWiegandCodeLengthField_cardFormat$data = {
  readonly id: string;
  readonly number: string;
  readonly wiegandLength: number;
  readonly " $fragmentType": "CardFormatWiegandCodeLengthField_cardFormat";
};
export type CardFormatWiegandCodeLengthField_cardFormat$key = {
  readonly " $data"?: CardFormatWiegandCodeLengthField_cardFormat$data;
  readonly " $fragmentSpreads": FragmentRefs<"CardFormatWiegandCodeLengthField_cardFormat">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CardFormatWiegandCodeLengthField_cardFormat",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "wiegandLength",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    }
  ],
  "type": "CardFormat",
  "abstractKey": null
};

(node as any).hash = "cee910a28a50ea1b8b2c4eace06ffe96";

export default node;
