import React from "react";
import { react2angular } from "react2angular";
import { DaSolidButton } from "../DaStyledElements";
import { LegaleseModal } from "../LegaleseModal";

const TermsAndConditions = () => {
  const [isTermsAndConditionsModalOpen, setIsTermsAndConditionsModalOpen] =
    React.useState(false);

  const handleCloseModal = () => setIsTermsAndConditionsModalOpen(false);
  // this return utilizes a local asset and will be changed when the new html link is available
  return (
    <>
      <DaSolidButton onClick={() => setIsTermsAndConditionsModalOpen(true)}>
        Terms & Conditions
      </DaSolidButton>
      {isTermsAndConditionsModalOpen ? (
        <LegaleseModal
          modalTitle="Terms & Conditions"
          hasCloserIcon
          srcFile={
            "assets/img/legal-docs/LT-2911_GlobalDealer-ServicesAgreement_24362.html"
          }
          handleCloseModal={handleCloseModal}
          modalOpen={isTermsAndConditionsModalOpen}
          handleSubmit={handleCloseModal}
        />
      ) : null}
    </>
  );
};

export const dangerouslyAddToApp = () => {
  App.component(
    "termsAndConditions",
    react2angular(TermsAndConditions, [], ["$state", "$rootScope", "$scope"])
  );
};
